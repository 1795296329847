import { getUnixTime } from 'date-fns';

import type { ObjValues } from '../types/util';

/** Sprint Details */
const details = {
  title: 'sprint-title',
  bulkAssignInput: 'sprint--overview--bulk-assign--input',
} as const;

/** Sprint Drawer */
const startBtn = 'start-sprint-btn';
interface Sprint$Drawer {
  startBtn: typeof startBtn;
}
const drawer: Sprint$Drawer = {
  startBtn,
};

/** Sprint Form Dialog */
// const topSection = 'objective-and-action-form-top-section';
const backBtn = 'objective-and-action-form-action-back-btn';
const sprintFormSaveBtn = 'objective-and-action-form-action-save-btn';
const nextBtn = 'objective-and-action-form-action-next-btn';
interface Sprint$FormDialog {
  backBtn: typeof backBtn;
  saveBtn: typeof sprintFormSaveBtn;
  nextBtn: typeof nextBtn;
}
const sprintFormDialog: Sprint$FormDialog = {
  backBtn,
  saveBtn: sprintFormSaveBtn,
  nextBtn,
};

/** Sprint Start Form */
const startSprintComponent = 'app-start-sprint-form' as const;
const titleInput = 'app-start-sprint-form-title-input' as const;
const startDate = 'app-start-sprint-form-start-date' as const;
const endDate = 'app-start-sprint-form-end-date' as const;
const dateOption = (date: Date): `app-start-sprint-form-date-option-${number}` =>
  `app-start-sprint-form-date-option-${getUnixTime(date)}`;
const customersByOwnerBadge = (
  index: number,
): `app-start-sprint-form-customer-by-owner-badge-${number}` =>
  `app-start-sprint-form-customer-by-owner-badge-${index}`;
const customersByOwnerBadgeOwner = 'app-start-sprint-form-customer-by-owner-badge-owner';
const customersByOwnerBadgeTotal = 'app-start-sprint-form-customer-by-owner-badge-total';
interface Sprint$StartForm {
  component: typeof startSprintComponent;
  titleInput: typeof titleInput;
  startDate: typeof startDate;
  endDate: typeof endDate;
  dateOption: typeof dateOption;
  customersByOwnerBadge: typeof customersByOwnerBadge;
  customersByOwnerBadgeOwner: typeof customersByOwnerBadgeOwner;
  customersByOwnerBadgeTotal: typeof customersByOwnerBadgeTotal;
}
const startSprintForm: Sprint$StartForm = {
  component: startSprintComponent,
  titleInput,
  startDate,
  endDate,
  dateOption,
  customersByOwnerBadge,
  customersByOwnerBadgeOwner,
  customersByOwnerBadgeTotal,
};

/** Sprint Start Form Dialog */
const startSprintDialogComponent = 'app-start-sprint-form-dialog';
const startSprintBtn = 'app-start-sprint-form-dialog-start-btn';
const cancelSprintBtn = 'app-start-sprint-form-dialog-cancel-btn';
interface Sprint$StartFormDialog {
  component: typeof startSprintDialogComponent;
  startSprintBtn: typeof startSprintBtn;
  cancelSprintBtn: typeof cancelSprintBtn;
}
const startSprintFormDialog: Sprint$StartFormDialog = {
  component: startSprintDialogComponent,
  startSprintBtn,
  cancelSprintBtn,
};

/** Sprint Activity Note Dialog */
const activityNoteDialog = {
  openDialogBtn: 'add-note--button--open-dialog',
  text: 'add-note-textarea',
  cancelBtn: 'add-note-cancel-button',
  saveBtn: 'add-note-save-button',
  success: 'add-note-success-snackbar',
  loading: 'add-note--alert--loading',
  caption: 'add-note--caption--text',
} as const;

/** Sprint Invite User Dialog */
const inviteUserLink = 'invite-user-link';
const inviteUserCancelBtn = 'invite-user-cancel-button';
const inviteUserInviteBtn = 'invite-user-invite-button';
const inviteUserSuccess = 'invite-user-success-snackbar';
interface Sprint$InviteUserDialog {
  link: typeof inviteUserLink;
  cancelBtn: typeof inviteUserCancelBtn;
  inviteBtn: typeof inviteUserInviteBtn;
  success: typeof inviteUserSuccess;
}
const inviteUserDialog: Sprint$InviteUserDialog = {
  link: inviteUserLink,
  cancelBtn: inviteUserCancelBtn,
  inviteBtn: inviteUserInviteBtn,
  success: inviteUserSuccess,
};

const customerDetailsPage = {
  startBtn: 'sprint--customer-details-page--start-button',
} as const;

const creation = {
  page: 'sprint--creation--root',
  header: 'sprint--creation--header',
  loadingAlert: 'sprint--creation--loading-alert',
  errorAlert: 'sprint--creation--error-alert',
  createSprintBtn: 'sprint--creation--create-button',
  viewSprintBtn: 'sprint--creation--view-button',
  dateOption: (date: Date) => `sprint--creation--date-option-${getUnixTime(date)}`,
  startDate: 'sprint--creation--start-date',
  endDate: 'sprint--creation--end-date',
  name: 'sprint--creation--name-field',
  recommendation: 'sprint--creation--recommendation-field',
  playbook: 'sprint--creation--playbook-field',
  accounts: 'sprint--creation--accounts-field',
  activities: 'sprint--creation--activities',
  activitiesHeader: 'sprint--creation--activities--header',
  activitiesList: 'sprint--creation--activities--list',
  creator: 'sprint--creation--recommended-sprint-creator',
  createdAt: 'sprint--creation--recommended-sprint-created-at',
} as const;

export const sprint$ = {
  details,
  drawer,
  sprintFormDialog,
  startSprintForm,
  startSprintFormDialog,
  activityNoteDialog,
  inviteUserDialog,
  customerDetailsPage,
  creation,
};
export type Sprint$Values =
  | ObjValues<typeof details>
  | ObjValues<Sprint$Drawer>
  | ObjValues<Sprint$FormDialog>
  | ObjValues<Sprint$StartForm>
  | ObjValues<Sprint$StartFormDialog>
  | ObjValues<typeof activityNoteDialog>
  | ObjValues<Sprint$InviteUserDialog>
  | ObjValues<typeof customerDetailsPage>;
