import { Box, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material';
import type { ChartComponentLike, ChartData } from 'chart.js';
import { ArcElement, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import React, { useCallback, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { truncateFloat } from '../../../utils';

// define custom plugin for handling centered Doughnut title text
const DoughnutCompletionTitle: ChartComponentLike = {
  id: 'DoughnutCompletionTitle',
  beforeDraw: (chart) => {
    const healthPercentage = chart.options.plugins?.healthPercentage?.value;
    if (healthPercentage != null) {
      const width = chart.width;
      const height = chart.height;
      const ctx = chart.ctx;
      const magicOffset = 20;

      ctx.restore();
      ctx.font = `400 22px Roboto`;
      ctx.fillStyle = '#000000';
      ctx.textBaseline = 'middle';

      const text = `${truncateFloat(healthPercentage, 1)}%`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - magicOffset;
      ctx.fillText(text, textX, textY);

      ctx.font = `400 14px Roboto`;
      ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
      ctx.textBaseline = 'middle';

      const text2 = 'Complete';
      const text2X = Math.round((width - ctx.measureText(text2).width) / 2);
      const text2Y = textY + magicOffset;

      ctx.fillText(text2, text2X, text2Y);
      ctx.save();
    }
  },
};

// TODO(REEF-1217)
ChartJS.register(ArcElement, LinearScale, Tooltip, Legend, DoughnutCompletionTitle);

const DATA_SYNC_HEALTH_DONUT_LABELS = ['Complete' as const, 'Missing' as const];
const DATA_SYNC_HEALTH_DONUT_COLORS = ['#4396F3', '#ED6C02'];
const CARD_CONTENT_WIDTH = 160;
const CARD_CONTENT_HEIGHT = 206;

interface UnhealthyDonutProps {
  title: string;
  missing: number;
  total: number;
  loading: boolean;
  error: boolean;
}

export const UnhealthyDonut = ({ error, title, missing, total, loading }: UnhealthyDonutProps) => {
  const chartData = useMemo(
    (): ChartData<'doughnut', number[]> => ({
      labels: DATA_SYNC_HEALTH_DONUT_LABELS,
      datasets: [
        {
          backgroundColor: DATA_SYNC_HEALTH_DONUT_COLORS,
          data: [total - missing, missing],
        },
      ],
    }),
    [missing, total],
  );

  // disable doughnut legend label click behavior
  const legendOnClickHandler = useCallback(() => null, []);

  return (
    <Card>
      <CardContent>
        <Stack>
          <Box sx={{ textAlign: 'center' }}>
            <Typography>{title}</Typography>
          </Box>
          {loading ? (
            <Box
              sx={{
                minWidth: `${CARD_CONTENT_WIDTH}px`,
                minHeight: `${CARD_CONTENT_HEIGHT}px`,
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
          {error ? (
            <Box
              sx={{
                minWidth: `${CARD_CONTENT_WIDTH}px`,
                minHeight: `${CARD_CONTENT_HEIGHT}px`,
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6">⚠️</Typography>
            </Box>
          ) : null}
          {!loading && !error ? (
            <Doughnut
              width={CARD_CONTENT_WIDTH}
              height={CARD_CONTENT_HEIGHT}
              options={{
                font: {
                  family: 'Roboto',
                },
                responsive: false,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                    labels: { pointStyle: 'circle', usePointStyle: true, color: '#000' },
                    onClick: legendOnClickHandler,
                  },
                  healthPercentage: {
                    value: ((total - missing) / (total || 1)) * 100,
                  },
                },
                cutout: '80%',
              }}
              data={chartData}
            />
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
};
