import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import type { DataConnectorSource } from '../../../../../graphql/generated';
import { useDataConnectorLoginUrl } from '../../../../../hooks/dataConnectors';
import { ResultType } from '../../../../../models/result';

type ConnectButtonProps = {
  redirectUrl: string;
  sourceType: DataConnectorSource;
};

export const ConnectButton = ({ redirectUrl, sourceType }: ConnectButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [getDataConnectorLoginUrl, loginUrlResult] = useDataConnectorLoginUrl({
    redirectUrl,
    sourceType,
  });

  const handleConnectClick = async () => {
    setIsLoading(true);
    getDataConnectorLoginUrl();
  };

  useEffect(() => {
    if (loginUrlResult.state === ResultType.Value && loginUrlResult.value) {
      window.location.assign(loginUrlResult.value);
    }
  }, [loginUrlResult]);

  return isLoading ? (
    <CircularProgress data-uid={`data-connector-loader-${sourceType}`} size={50} />
  ) : (
    <Button variant="contained" onClick={handleConnectClick}>
      Connect
    </Button>
  );
};
