import { useMutation } from '@apollo/client';
import type { AlertProps } from '@mui/material';
import { Alert, Box, Button, Container } from '@mui/material';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router';

import { DeleteSprintDocument } from '../../../graphql/generated';
import { asUUID } from '../../../models/uuid';
import { bottom } from '../../../utils';

declare global {
  // eslint-disable-next-line no-var
  var Cypress: unknown | undefined;
}

type DeletionStatus = 'none' | 'deleting' | 'success' | 'failed';

export const DeleteSprint = () => {
  const { sprintId } = useParams();

  const [deleteSprint, result] = useMutation(DeleteSprintDocument);
  const [deletionStatus, setDeletionStatus] = useState<DeletionStatus>('none');

  const canDelete = useMemo(() => !!window.Cypress || location.hostname === 'localhost', []);

  const disableDelete = useMemo(
    () => result.loading || result.called,
    [result.called, result.loading],
  );
  const deleteThisSprint = useCallback(() => {
    if (sprintId != null && canDelete && !disableDelete) {
      setDeletionStatus('deleting');
      deleteSprint({
        variables: { sprintId: asUUID(sprintId) },
        onCompleted: () => setDeletionStatus('success'),
        onError: () => setDeletionStatus('failed'),
      });
    }
  }, [canDelete, deleteSprint, disableDelete, sprintId]);

  const { children: alertMessage, ...alertProps } = useMemo((): PropsWithChildren<AlertProps> => {
    switch (deletionStatus) {
      case 'success':
        return {
          severity: 'success',
          children: `Sprint ${result.data?.payload.sprint.name ?? sprintId} has been deleted!`,
        };
      case 'deleting':
        return {
          severity: 'info',
          children: `Attempting to delete Sprint ${sprintId} ...`,
        };
      case 'failed':
        return {
          severity: 'error',
          children: `Failed to delete Sprint ${sprintId}`,
        };
      case 'none':
        return {
          severity: 'info',
          children: 'Nothing to see here~',
        };
      default:
        bottom(deletionStatus);
    }
  }, [deletionStatus, result.data, sprintId]);

  if (!canDelete) {
    console.error(`Unable to delete Sprint ${sprintId} outside of Cypress`);
    return <Navigate to={`/sprint/${sprintId}`} replace />;
  }

  return (
    <Container sx={{ my: 4, p: 0, mx: 3 }}>
      <Box>
        <Button
          data-uid="sprint--delete-button"
          disabled={disableDelete}
          onClick={deleteThisSprint}
        >
          Delete Me!
        </Button>
        <Alert data-uid="sprint--delete-alert" {...alertProps}>
          {alertMessage}
        </Alert>
      </Box>
    </Container>
  );
};
