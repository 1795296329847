import type { TooltipItem } from 'chart.js';

export const barTooltipTitleHandler = (tooltipItems: TooltipItem<'bar'>[]) => {
  // assume the first label of every dataset is reliable, if present
  return tooltipItems.at(0)?.dataset?.label || '';
};
export const barTooltipLabelHandler = (
  barItem: TooltipItem<'bar'>,
  currencyFormatter: Intl.NumberFormat,
) => {
  // default to empty label for custom interaction mode
  let label = '';

  if (barItem.parsed.y !== null) {
    label += currencyFormatter.format(barItem.parsed.y);
  }
  return label;
};
export const barYTicksHandler = (
  value: string | number,
  formatCurrencyShort: (n: number) => string,
) => formatCurrencyShort(parseFloat(`${value}`));
