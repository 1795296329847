import { DragHandle as DragHandleIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import type { Theme } from '@mui/material';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useCallback } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { PLAYBOOK_MANAGEMENT_TITLE_LIMIT } from '../../../../constants';
import { useObjectiveActions } from '../../../../hooks/client';
import { ResultType } from '../../../../models/result';
import type { Action } from '../../../../models/sprint';
import type { UUID } from '../../../../models/uuid';
import { ActionLinks } from '../../../ActionLinks';
import { FailedResult } from '../../../Alerts';
import { ClampedText } from '../../../ClampedText';

interface PlaybookActionsProps {
  forObjective: UUID;
  /**
   * If non-null, the action that should have a `disabled` publish switch.
   */
  publishActionDisabled: UUID | undefined;
  /**
   * If true, the drag and drop component will not allow movement.
   */
  reorderDisabled: boolean;
  /**
   * Callback to publish/un-publish an action.
   * @param actionId the action to publish/un-publish
   * @param published if `true`, will publish the action, otherwise un-publish
   */
  onPublishAction(actionId: UUID, published: boolean): void;
  /**
   * Callback to open the action menu.
   * @param target where the menu should be placed
   * @param action the action that had it's menu opened
   */
  onOpenActionMenu(target: EventTarget & HTMLButtonElement, action: Action): void;
  /**
   * Called when the actions are reordered.
   * @param actionIdOrder the new order of actions by their ID
   */
  onReorderActions(actionIdOrder: UUID[]): void;
}

export const PlaybookActions = ({
  forObjective,
  publishActionDisabled,
  reorderDisabled,
  onPublishAction,
  onOpenActionMenu,
  onReorderActions,
}: PlaybookActionsProps) => {
  const actionsResult = useObjectiveActions({
    objectiveId: forObjective,
    includeUnpublishedActions: true,
  });

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination == null || actionsResult.state !== ResultType.Value) {
        return;
      }

      const reordered = Array.from(actionsResult.value);
      const [removed] = reordered.splice(result.source.index, 1);
      reordered.splice(result.destination.index, 0, removed);

      onReorderActions(reordered.map((a) => a.id));
    },
    [actionsResult.state, actionsResult.value, onReorderActions],
  );

  if (actionsResult.state === ResultType.Error || actionsResult.state === ResultType.NoValue) {
    return (
      <FailedResult
        resultType={`actions for objective ${forObjective}`}
        error={actionsResult.value}
      />
    );
  }

  if (actionsResult.state === ResultType.Loading) {
    return <LinearProgress />;
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Action</TableCell>
          <TableCell>Author</TableCell>
          <TableCell>Links</TableCell>
          <TableCell align="right">Published</TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-action" type="ACTION">
          {(providedDroppable) => (
            <TableBody {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {actionsResult.value.map((action, index) => (
                <Draggable
                  key={action.id}
                  draggableId={`draggable-action-${action.id}`}
                  index={index}
                  isDragDisabled={reorderDisabled}
                >
                  {(providedDraggable, snapshot) => {
                    // dragging can cause some weirdness because this will make the row render _outside_
                    // of a table context, so the cells won't know how to compute their widths correctly
                    // TODO: clean up the widths of draggable rows in the action reordering
                    const bgcolor = (theme: Theme) =>
                      snapshot.isDragging ? theme.palette.background.paper : undefined;

                    return (
                      <TableRow
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        ref={providedDraggable.innerRef}
                        sx={{ userSelect: 'none' }}
                      >
                        <TableCell sx={{ maxWidth: 0, bgcolor }}>
                          <Box sx={{ my: 'auto', display: 'flex' }}>
                            <IconButton size="small" disabled={reorderDisabled}>
                              <DragHandleIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ bgcolor }}>
                          <ClampedText
                            data-rank={action.rank}
                            text={action.title}
                            limit={PLAYBOOK_MANAGEMENT_TITLE_LIMIT}
                          />
                        </TableCell>
                        <TableCell sx={{ bgcolor }}>{action.author}</TableCell>
                        <TableCell sx={{ bgcolor }}>
                          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                            {action.links.length > 0 ? (
                              <ActionLinks links={action.links} />
                            ) : (
                              <>&ndash;</>
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ bgcolor }} align="right">
                          <Switch
                            disabled={publishActionDisabled === action.id}
                            checked={action.isPublished}
                            onChange={(e) => onPublishAction(action.id, e.target.checked)}
                          />
                        </TableCell>
                        <TableCell sx={{ bgcolor }} align="right">
                          <IconButton onClick={(e) => onOpenActionMenu(e.currentTarget, action)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};
