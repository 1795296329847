import { Typography } from '@mui/material';
import type { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import React from 'react';

import { FieldWrapper } from '../Account/FieldWrapper';

const PERCENT_FORMATTER = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
});

export type PercentCellProps = GridRenderCellParams<GridValidRowModel, number>;
export const PercentCell = ({ field, value, rowNode }: PercentCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  return (
    <FieldWrapper field={field}>
      <Typography variant="body2" title={value?.toString()}>
        {value != null ? `${PERCENT_FORMATTER.format(value)}%` : missingValue}
      </Typography>
    </FieldWrapper>
  );
};
