import type {
  DataGridPremiumProps,
  GridColTypeDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import {
  DateCell,
  MultiCurrencyCell,
  NumberCell,
  StringCell,
} from '../components/Tables/CustomGrid';
import { OpportunityNameCell } from '../components/Tables/CustomGrid/Opportunity';
import type { OpportunityServicePeriodGridRowModel } from '../components/Tables/CustomGrid/Opportunity/types';
import type { OpportunityServicePeriodDetails } from '../models/opportunity';
import { getMUIMultiGroupedColumnField } from '../utils';
import { useCommonGridHelpers } from './useCommonGridHelpers';

interface ContractGridColDef extends GridColTypeDef {
  field: keyof OpportunityServicePeriodGridRowModel;
}

const renderDateCell = (
  params: GridRenderCellParams<OpportunityServicePeriodGridRowModel, Date>,
) => <DateCell {...params} />;
const renderMultiCurrencyCell = (
  params: GridRenderCellParams<OpportunityServicePeriodGridRowModel, number>,
) => <MultiCurrencyCell {...params} />;
const renderNumberCell = (
  params: GridRenderCellParams<OpportunityServicePeriodGridRowModel, number>,
) => <NumberCell {...params} />;
const renderStringCell = (
  params: GridRenderCellParams<OpportunityServicePeriodGridRowModel, string>,
) => <StringCell {...params} />;
const renderNameCell = (
  params: GridRenderCellParams<OpportunityServicePeriodGridRowModel, string>,
  apiRef: Required<DataGridPremiumProps>['apiRef'],
) => {
  if (params.rowNode.type === 'group' && params.rowNode.groupingField === 'opportunityName') {
    const links = new Set<string>(
      apiRef.current
        .getRowGroupChildren({ groupId: params.id })
        .map(params.api.getRow)
        .map((r) => r.opportunityLink),
    );
    if (links.size === 1) {
      return (
        <OpportunityNameCell
          {...params}
          row={{ ...params.row, opportunityLink: Array.from(links)[0] }}
        />
      );
    }
  }

  return <OpportunityNameCell {...params} />;
};

export interface UseOpportunitiesGridProps {
  opportunityServicePeriods: OpportunityServicePeriodDetails[];
}
type OpportunitiesGridProps = Pick<
  DataGridPremiumProps<OpportunityServicePeriodDetails>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'localeText'
  | 'rowHeight'
  | 'rows'
  | 'defaultGroupingExpansionDepth'
  | 'rowGroupingColumnMode'
  | 'groupingColDef'
  | 'onRowClick'
>;
export const useOpportunityServicePeriodsGrid = ({
  opportunityServicePeriods,
}: UseOpportunitiesGridProps): OpportunitiesGridProps => {
  const hideableColMap = useMemo(() => ({}), []);

  const {
    apiRef,
    columnVisibilityModel,
    groupingColDef: commonGroupingColDef,
    ...restProps
  } = useCommonGridHelpers({ hideableColMap, localeEntity: 'Service Period' });

  const columns = useMemo((): ContractGridColDef[] => {
    return [
      {
        field: 'opportunityName',
        headerName: 'Opportunity',
        hideable: false,
        flex: 2,
        type: 'string',
        renderCell: (params) => renderNameCell(params, apiRef),
        groupable: true,
        aggregable: false,
      },
      {
        field: 'productName',
        headerName: 'Product',
        hideable: false,
        flex: 2,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'contractedUnits',
        headerName: 'Quantity',
        flex: 1.5,
        type: 'number',
        renderCell: renderNumberCell,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'contractedDollars',
        headerName: 'Total Price',
        renderCell: renderMultiCurrencyCell,
        flex: 1.5,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'serviceStartDate',
        headerName: 'Start Date',
        flex: 1,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'serviceEndDate',
        headerName: 'End Date',
        flex: 1,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
    ];
  }, [apiRef]);

  const initialState: OpportunitiesGridProps['initialState'] = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      pinnedColumns: {
        // skip pinning due to flex column sizing
        left: [],
      },
      rowGrouping: {
        model: ['opportunityName', 'productName'],
      },
      columns: {
        columnVisibilityModel: { ...columnVisibilityModel, opportunityLink: false },
      },
      sorting: {
        sortModel: [
          {
            field: getMUIMultiGroupedColumnField('opportunityName'),
            sort: 'asc',
          },
          {
            field: getMUIMultiGroupedColumnField('productName'),
            sort: 'asc',
          },
        ],
      },
      aggregation: {
        model: {
          contractedDollars: 'sum',
          projectedOverageDollars: 'sum',
        },
      },
    },
  });

  const groupingColDef: OpportunitiesGridProps['groupingColDef'] = useMemo(
    () => ({
      ...commonGroupingColDef,
      flex: 2,
    }),
    [commonGroupingColDef],
  );

  return {
    ...restProps,
    apiRef,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    rowHeight: 42,
    initialState,
    columns,
    rows: opportunityServicePeriods,
    groupingColDef,
  };
};
