import { Typography } from '@mui/material';
import type { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import React from 'react';

import { cells$ } from '../../../../selectors/cells';
import { DynamicScoreChip } from '../../../ScoreChip/DynamicScoreChip';
import { FieldWrapper } from '../Account/FieldWrapper';

interface RenewalScoreCellProps extends GridRenderCellParams<GridValidRowModel, string> {}
export const RenewalScoreCell = ({ field, value, rowNode }: RenewalScoreCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? (
    <></>
  ) : (
    <Typography variant="body2">&ndash;</Typography>
  );
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <DynamicScoreChip data-uid={cells$.renewalScore.chip} value={value} />
      ) : (
        missingValue
      )}
    </FieldWrapper>
  );
};
