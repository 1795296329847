import { Typography } from '@mui/material';
import type { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import React from 'react';

import { DATA_GRID_TEXT_LIMIT } from '../../../../constants';
import { ClampedText } from '../../../ClampedText';
import { FieldWrapper } from '../Account/FieldWrapper';

type StringCellProps = GridRenderCellParams<GridValidRowModel, string>;
export const StringCell = ({ field, value, rowNode }: StringCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <ClampedText
          TypographyProps={{ variant: 'body2' }}
          limit={DATA_GRID_TEXT_LIMIT}
          text={value}
        />
      ) : (
        <Typography variant="body2">{missingValue}</Typography>
      )}
    </FieldWrapper>
  );
};
