import type { TickOptions, TooltipOptions } from 'chart.js';
import { useCallback, useMemo } from 'react';
import type { ChartProps } from 'react-chartjs-2';

import { TotalRenewalStatusLegendPlugin } from '../components/Charts/Analytics/HeroChart/TotalRenewalStatusLegendPlugin';
import { RENEWAL_ANALYTICS_COLORS } from '../components/Charts/Analytics/utils';
import {
  barTooltipLabelHandler,
  barTooltipTitleHandler,
  barYTicksHandler,
} from '../components/Charts/BarChart/barChartUtils';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type { Renewal, RenewalAnalytics } from './renewal';
import { ExpiringSegments, RenewedSegments } from './renewal';

interface UseTotalRenewalStatusChartConfigProps {
  renewals: Renewal[];
}
export const useTotalRenewalStatusChartConfig = ({
  renewals,
}: UseTotalRenewalStatusChartConfigProps): ChartProps<'bar'> => {
  const { currencyFormatter, formatCurrencyShort } = useUserSettingsContext();
  const label = useCallback<TooltipOptions['callbacks']['label']>(
    (barItem) => barTooltipLabelHandler(barItem, currencyFormatter),
    [currencyFormatter],
  );
  const callback = useCallback<TickOptions['callback']>(
    (value) => barYTicksHandler(value, formatCurrencyShort),
    [formatCurrencyShort],
  );
  const totals = useMemo(
    () =>
      renewals.reduce<Pick<RenewalAnalytics, 'expiring' | 'renewed'>>(
        (acc, c) => ({
          ...acc,
          expiring: {
            ...acc.expiring,
            [ExpiringSegments.Churn]:
              c.analytics.expiring[ExpiringSegments.Churn] + acc.expiring[ExpiringSegments.Churn],
            [ExpiringSegments.Pending]:
              c.analytics.expiring[ExpiringSegments.Pending] +
              acc.expiring[ExpiringSegments.Pending],
            [ExpiringSegments.Renewed]:
              c.analytics.expiring[ExpiringSegments.Renewed] +
              acc.expiring[ExpiringSegments.Renewed],
          },
          renewed: {
            ...acc.renewed,
            [RenewedSegments.Pending]:
              c.analytics.renewed[RenewedSegments.Pending] + acc.renewed[RenewedSegments.Pending],
            [RenewedSegments.Renewed]:
              c.analytics.renewed[RenewedSegments.Renewed] + acc.renewed[RenewedSegments.Renewed],
            [RenewedSegments.Upsell]:
              c.analytics.renewed[RenewedSegments.Upsell] + acc.renewed[RenewedSegments.Upsell],
          },
        }),
        {
          expiring: {
            [ExpiringSegments.Churn]: 0,
            [ExpiringSegments.Pending]: 0,
            [ExpiringSegments.Renewed]: 0,
          },
          renewed: {
            [RenewedSegments.Pending]: 0,
            [RenewedSegments.Renewed]: 0,
            [RenewedSegments.Upsell]: 0,
          },
        },
      ),
    [renewals],
  );
  const data: ChartProps<'bar'>['data'] = useMemo(
    () => ({
      labels: ['Total Expiring', 'Total Renewed'],
      datasets: [
        {
          label: 'Renewed',
          data: [
            totals.expiring[ExpiringSegments.Renewed],
            totals.renewed[RenewedSegments.Renewed],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Renewed,
        },
        {
          label: 'Upsell',
          data: [null, totals.renewed[RenewedSegments.Upsell]],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Upsell,
        },
        {
          label: 'Pending',
          data: [
            totals.expiring[ExpiringSegments.Pending],
            totals.renewed[RenewedSegments.Pending],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Pending,
        },
        {
          label: 'Churn',
          data: [totals.expiring[ExpiringSegments.Churn], null],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Churn,
        },
      ],
    }),
    [totals],
  );

  return {
    type: 'bar',
    data: data,
    options: {
      layout: {
        padding: 16,
      },
      plugins: {
        htmlLegend: {
          containerId: 'custom-total-renewal-status-chart-legend',
          currencyFormatter,
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: barTooltipTitleHandler,
            label,
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          display: false,
          stacked: true,
        },
        y: {
          display: false,
          stacked: true,
          ticks: {
            callback,
          },
        },
      },
      maintainAspectRatio: false,
    },
    plugins: [TotalRenewalStatusLegendPlugin],
  };
};
