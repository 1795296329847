import { useMutation } from '@apollo/client';
import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import type { DateRange, DateRangePickerDayProps } from '@mui/x-date-pickers-pro';
import { DateRangePickerDay, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { addDays, getUnixTime, isToday, isValid, startOfToday } from 'date-fns';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ActivityStatus,
  CreateSprintDocument,
  GetMySprintsDocument,
  GetSprintDashboardDocument,
  StartSprintDocument,
  UpdateActivityStatusBulkDocument,
} from '../../../graphql/generated';
import type { Account } from '../../../models/account';
import type { Action, DraftActivity, Objective } from '../../../models/sprint';
import type { UUID } from '../../../models/uuid';
import { sprint$ } from '../../../selectors';
import { bottom, pluralize, transformDate } from '../../../utils';
import { ActivityStatusSelect } from '../../Selects/ActivityStatusSelect/ActivityStatusSelect';
import type { CreateSprintVariantType } from './CreateSprint';

interface SprintCreationProps {
  playbook: Objective;
  playbookActions: Action[];
  accounts: Account[];
  activities: DraftActivity[];
  recId: UUID | null;
  name: string;
  endOffset: string;
  end: string;
  start: string;
  variant: CreateSprintVariantType;
}
export const SprintCreation = ({
  accounts,
  activities,
  playbook,
  playbookActions,
  recId,
  name: initialName,
  end: initialEnd,
  endOffset: initialEndOffset,
  start: initialStart,
  variant,
}: SprintCreationProps) => {
  const nav = useNavigate();
  const today = useMemo(() => startOfToday(), []);

  const [name, setName] = useState<string>(initialName);
  const onNameChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback((e) => setName(e.target.value), []);
  useEffect(() => setName(initialName), [initialName]);

  const endOffset = parseInt(initialEndOffset ?? '');
  const defaultEndDate = useMemo(() => {
    return transformDate(initialEnd) ?? (!isNaN(endOffset) ? addDays(today, endOffset) : null);
  }, [initialEnd, endOffset, today]);
  const [sprintDateRange, setSprintDateRange] = useState<DateRange<Date>>([
    transformDate(initialStart) ?? today,
    defaultEndDate,
  ]);
  useEffect(
    () => setSprintDateRange([transformDate(initialStart) ?? today, defaultEndDate]),
    [defaultEndDate, initialStart, today],
  );

  const day = useCallback(
    (props: DateRangePickerDayProps<Date>) => (
      <DateRangePickerDay {...props} data-uid={sprint$.creation.dateOption(props.day)} />
    ),
    [],
  );
  const onDateRangeChange = useCallback((value: DateRange<Date>) => setSprintDateRange(value), []);

  const [activityStatusValues, setActivityStatusValues] = useState<{
    [uuid: string]: ActivityStatus;
  }>({});

  // logic to handle callbacks after a sprint is created
  const [createSprint, createSprintResult] = useMutation(CreateSprintDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }, { query: GetSprintDashboardDocument }],
  });
  // logic to handle callbacks after a sprint is started
  const [startSprint, startSprintResult] = useMutation(StartSprintDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }, { query: GetSprintDashboardDocument }],
  });
  const [updateActivityStatusBulk, updateActivityStatusBulkResult] = useMutation(
    UpdateActivityStatusBulkDocument,
  );

  const buttonLoadingInfo = useMemo((): Pick<
    LoadingButtonProps,
    'loading' | 'loadingIndicator'
  > => {
    if (createSprintResult.loading || updateActivityStatusBulkResult.loading) {
      return {
        loading: true,
        loadingIndicator: 'Creating...',
      };
    }
    if (startSprintResult.loading) {
      return {
        loading: true,
        loadingIndicator: 'Starting...',
      };
    }
    return {
      loading: false,
    };
  }, [
    createSprintResult.loading,
    startSprintResult.loading,
    updateActivityStatusBulkResult.loading,
  ]);
  const globalDisabled = useMemo(() => buttonLoadingInfo.loading, [buttonLoadingInfo.loading]);

  const sprintCreationDisabled = useMemo(
    () =>
      accounts == null ||
      accounts.length < 1 ||
      playbook == null ||
      playbookActions == null ||
      playbookActions.length < 1 ||
      name == null ||
      name == '' ||
      !isValid(sprintDateRange[0]) ||
      !isValid(sprintDateRange[1]),
    [accounts, name, playbook, playbookActions, sprintDateRange],
  );

  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);

  const onCreateSprintClick = useCallback(
    async (selectedName: string | null, selectedDateRange: DateRange<Date>) => {
      if (playbook != null && playbookActions != null && accounts != null && selectedName != null) {
        setIsSelectDisabled(true);
        const draftSprint = await createSprint({
          variables: {
            name: `${selectedName} - Draft`,
            objectiveId: playbook.id,
            actionIds: playbookActions.map((pa) => pa.id),
            accountIds: accounts.map((a) => a.id),
            recommendationId: recId ?? null,
          },
        });
        const [startDate, endDate] = selectedDateRange;
        if (
          draftSprint.data != null &&
          selectedName.length > 0 &&
          startDate != null &&
          endDate != null
        ) {
          if (Object.keys(activityStatusValues).length > 0) {
            const activityStatusUpdates = draftSprint.data.payload.sprint.activities.map(
              (activity) => {
                const activityStatus = activityStatusValues[activity.action.id];
                return {
                  activityId: activity.id,
                  status: activityStatus ?? ActivityStatus.NotStarted,
                };
              },
            );
            await updateActivityStatusBulk({ variables: { input: activityStatusUpdates } });
          }
          const startedSprint = await startSprint({
            variables: {
              sprintId: draftSprint.data.payload.sprint.id,
              name: selectedName,
              startDate: getUnixTime(startDate),
              endDate: getUnixTime(endDate),
            },
          });
          if (startedSprint.data != null) {
            nav(`/sprint/${startedSprint.data.payload.sprint.id}`);
          }
        }
        setIsSelectDisabled(false);
      }
    },
    [
      accounts,
      createSprint,
      nav,
      playbook,
      playbookActions,
      recId,
      startSprint,
      activityStatusValues,
      updateActivityStatusBulk,
    ],
  );

  const CustomPaper = ({
    'data-uid': dataUid = '',
    children,
  }: {
    'data-uid'?: string;
    children: ReactNode;
  }) => (
    <Paper elevation={5} sx={{ width: '50%', padding: 5 }} data-uid={dataUid}>
      {children}
    </Paper>
  );

  const onStatusChange = useCallback(
    ({ actionId, status }: { actionId: UUID; status: ActivityStatus }) =>
      setActivityStatusValues({ ...activityStatusValues, [actionId]: status }),
    [activityStatusValues],
  );

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
      data-uid={sprint$.creation.page}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        height="calc(100vh - 2.75rem)"
        margin={2.75}
        sx={{
          maxWidth: '1200px',
        }}
      >
        <Box data-uid={sprint$.creation.header} sx={{ marginBottom: 2.75 }}>
          <Typography variant="h3">
            {variant === 'capture' ? 'Capture your progress' : 'Create a new Sprint'}
          </Typography>
        </Box>
        <Stack direction="row" spacing={5} sx={{ flexGrow: 1 }}>
          <CustomPaper>
            <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: 3 }}>
              Sprint Details
            </Typography>
            <TextField
              fullWidth
              data-uid={sprint$.creation.name}
              label="Name"
              variant="outlined"
              value={name}
              onChange={onNameChange}
              disabled={globalDisabled}
              autoFocus
              sx={{ marginBottom: 3 }}
            />
            <TextField
              fullWidth
              data-uid={sprint$.creation.playbook}
              label="Playbook"
              variant="outlined"
              value={playbook.title}
              disabled
              sx={{ marginBottom: 3 }}
            />
            <TextField
              id="account-name"
              fullWidth
              data-uid={sprint$.creation.accounts}
              label={pluralize({ count: accounts.length, singular: 'Account' })}
              variant="outlined"
              value={accounts.map((a) => a.name).join(', ')}
              disabled
              sx={{ marginBottom: 3 }}
            />
            <DesktopDateRangePicker
              sx={{ marginBottom: 3 }}
              value={sprintDateRange}
              onChange={onDateRangeChange}
              shouldDisableDate={(day, pos) => pos === 'start' && !isToday(day)}
              disablePast
              maxDate={addDays(today, 14)}
              slots={{
                day,
              }}
              slotProps={{
                fieldSeparator: { children: 'to' },
                textField: ({ position }) => {
                  switch (position) {
                    case 'end':
                      return {
                        InputProps: {
                          disabled: globalDisabled,
                        },
                        inputProps: {
                          'data-uid': sprint$.creation.endDate,
                        },
                      };
                    case 'start':
                      return {
                        InputProps: {
                          disabled: true,
                        },
                        inputProps: {
                          'data-uid': sprint$.creation.startDate,
                        },
                      };
                    case undefined:
                      return {};
                    default:
                      bottom(position);
                  }
                },
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <LoadingButton
                data-uid={sprint$.creation.createSprintBtn}
                size="large"
                onClick={() => onCreateSprintClick(name, sprintDateRange)}
                {...buttonLoadingInfo}
                disabled={sprintCreationDisabled}
                color="primary"
                variant="contained"
                sx={{
                  maxHeight: '2.25rem',
                  fontWeight: 'normal',
                  fontSize: '0.75rem',
                  marginRight: 3,
                }}
              >
                Create Sprint
              </LoadingButton>
              <Button
                onClick={() => nav(`/work/my/${recId ? 'recommendations' : 'sprints'}`)}
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
          </CustomPaper>
          <CustomPaper data-uid={sprint$.creation.activities}>
            <Typography
              data-uid={sprint$.creation.activitiesHeader}
              variant="body1"
              sx={{ fontWeight: 500, marginBottom: 3 }}
            >
              Actions ({activities.length})
            </Typography>
            <Box
              data-uid={sprint$.creation.activitiesList}
              sx={{ height: '95%', overflow: 'auto' }}
            >
              {activities.map((act) => (
                <Box key={`${act.account.id}-${act.action.id}`} sx={{ marginBottom: 3 }}>
                  <Typography
                    variant="body1"
                    sx={{ marginBottom: 2 }}
                  >{`${act.action.title} for ${act.account.name}`}</Typography>
                  <FormControl size="small" sx={{ marginBottom: 3 }}>
                    <ActivityStatusSelect
                      activityId={act.action.id}
                      disabled={isSelectDisabled}
                      onStatusChange={(e: SelectChangeEvent) =>
                        onStatusChange({
                          actionId: act.action.id,
                          status: e.target.value as ActivityStatus,
                        })
                      }
                      value={activityStatusValues[act.action.id] || ActivityStatus.NotStarted}
                    />
                  </FormControl>
                  <Divider sx={{ marginBottom: 3 }} />
                </Box>
              ))}
            </Box>
          </CustomPaper>
        </Stack>
      </Stack>
    </Box>
  );
};
