import type { TickOptions, TooltipOptions } from 'chart.js';
import { merge, times } from 'lodash';
import { useCallback, useMemo } from 'react';
import type { ChartProps } from 'react-chartjs-2';

import { RENEWAL_ANALYTICS_COLORS } from '../components/Charts/Analytics/utils';
import { barYTicksHandler } from '../components/Charts/BarChart/barChartUtils';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type { Renewal } from './renewal';
import { RenewedBreakoutSegments } from './renewal';
import { baseWaterfallChartOptions } from './useRenewalOverviewChartConfig';

interface UseRenewalGrowthBreakdownChartConfigProps {
  renewals: Renewal[];
}
export const useRenewalGrowthBreakdownChartConfig = ({
  renewals,
}: UseRenewalGrowthBreakdownChartConfigProps): ChartProps => {
  const { currencyFormatter, formatCurrencyShort } = useUserSettingsContext();
  const label = useCallback<TooltipOptions['callbacks']['label']>(
    (barItem) => {
      // default to empty label for custom interaction mode
      let label = '';

      if (barItem.parsed.y !== null) {
        const { barEnd, barStart } = barItem.parsed._custom;
        label += currencyFormatter.format(barEnd - barStart);
      }
      return label;
    },
    [currencyFormatter],
  );
  const callback = useCallback<TickOptions['callback']>(
    (value) => barYTicksHandler(value, formatCurrencyShort),
    [formatCurrencyShort],
  );
  const totals = useMemo(
    () =>
      renewals.reduce(
        (acc, c) => ({
          ...acc,
          [RenewedBreakoutSegments.OnTime]:
            c.analytics.renewedBreakout[RenewedBreakoutSegments.OnTime] +
            acc[RenewedBreakoutSegments.OnTime],
          [RenewedBreakoutSegments.Late]:
            c.analytics.renewedBreakout[RenewedBreakoutSegments.Late] +
            acc[RenewedBreakoutSegments.Late],
          [RenewedBreakoutSegments.Pending]:
            c.analytics.renewedBreakout[RenewedBreakoutSegments.Pending] +
            acc[RenewedBreakoutSegments.Pending],
          [RenewedBreakoutSegments.ProductCrossSell]:
            c.analytics.renewedBreakout[RenewedBreakoutSegments.ProductCrossSell] +
            acc[RenewedBreakoutSegments.ProductCrossSell],
          [RenewedBreakoutSegments.ProductUpsell]:
            c.analytics.renewedBreakout[RenewedBreakoutSegments.ProductUpsell] +
            acc[RenewedBreakoutSegments.ProductUpsell],
        }),
        {
          [RenewedBreakoutSegments.OnTime]: 0,
          [RenewedBreakoutSegments.Late]: 0,
          [RenewedBreakoutSegments.Pending]: 0,
          [RenewedBreakoutSegments.ProductCrossSell]: 0,
          [RenewedBreakoutSegments.ProductUpsell]: 0,
        },
      ),
    [renewals],
  );
  const data: ChartProps['data'] = useMemo(
    () => ({
      labels: [
        'Renewed (On Time)',
        'Renewed (Late)',
        'Pending',
        'Product CrossSell',
        'Product Upsell',
        'Total Renewed',
      ],
      datasets: [
        {
          label: 'Renewed (On Time)',
          data: [[0, totals[RenewedBreakoutSegments.OnTime]], null, null, null, null, null],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.RenewedOnTime,
        },
        {
          label: 'Renewed (Late)',
          data: [
            null,
            [
              totals[RenewedBreakoutSegments.OnTime],
              totals[RenewedBreakoutSegments.OnTime] + totals[RenewedBreakoutSegments.Late],
            ],
            null,
            null,
            null,
            null,
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.RenewedLate,
        },
        {
          label: 'Pending',
          data: [
            null,
            null,
            [
              totals[RenewedBreakoutSegments.OnTime] + totals[RenewedBreakoutSegments.Late],
              totals[RenewedBreakoutSegments.OnTime] +
                totals[RenewedBreakoutSegments.Late] +
                totals[RenewedBreakoutSegments.Pending],
            ],
            null,
            null,
            null,
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Pending,
        },
        {
          label: 'Product CrossSell',
          data: [
            null,
            null,
            null,
            [
              totals[RenewedBreakoutSegments.OnTime] +
                totals[RenewedBreakoutSegments.Late] +
                totals[RenewedBreakoutSegments.Pending],
              totals[RenewedBreakoutSegments.OnTime] +
                totals[RenewedBreakoutSegments.Late] +
                totals[RenewedBreakoutSegments.Pending] +
                totals[RenewedBreakoutSegments.ProductCrossSell],
            ],
            null,
            null,
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.ProductCrossSell,
        },
        {
          label: 'Product Upsell',
          data: [
            null,
            null,
            null,
            null,
            [
              totals[RenewedBreakoutSegments.OnTime] +
                totals[RenewedBreakoutSegments.Late] +
                totals[RenewedBreakoutSegments.Pending] +
                totals[RenewedBreakoutSegments.ProductCrossSell],
              totals[RenewedBreakoutSegments.OnTime] +
                totals[RenewedBreakoutSegments.Late] +
                totals[RenewedBreakoutSegments.Pending] +
                totals[RenewedBreakoutSegments.ProductCrossSell] +
                totals[RenewedBreakoutSegments.ProductUpsell],
            ],
            null,
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.ProductUpsell,
        },
        {
          label: 'Total Renewed',
          data: [
            null,
            null,
            null,
            null,
            null,
            [
              0,
              totals[RenewedBreakoutSegments.OnTime] +
                totals[RenewedBreakoutSegments.Late] +
                totals[RenewedBreakoutSegments.ProductCrossSell] +
                totals[RenewedBreakoutSegments.ProductUpsell],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.TotalRenewed,
        },
        {
          label: 'Plan',
          data: times(
            8,
            () =>
              totals[RenewedBreakoutSegments.OnTime] +
              totals[RenewedBreakoutSegments.Late] +
              totals[RenewedBreakoutSegments.Pending],
          ),
          borderColor: 'rgba(0, 0, 0, 0.87);',
          pointRadius: 0,
          showLine: true,
          segment: {
            borderDash: [3, 2],
            borderWidth: 0.65,
          },
          borderDashOffset: 5,
          type: 'line',
        },
      ],
    }),
    [totals],
  );

  return {
    ...merge({}, baseWaterfallChartOptions, {
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label,
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback,
            },
          },
        },
      },
    }),
    data,
  };
};
