import { useQuery } from '@apollo/client';
import { Alert, Card } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { GetAccountCustomer360PdfDocument } from '../../../../graphql/generated';
import { useReefFlags } from '../../../../hooks/flags';
import { asUUID } from '../../../../models/uuid';
import { customerDetails$ } from '../../../../selectors/customerDetails';
import { ComingSoonSection } from '../../../ComingSoon';
import { PDFView } from '../../../PDFView';
import { PRODUCTS_CONTENT_INNER_OFFSET } from './constants';

export const Products = () => {
  const { accountId } = useParams();

  const { customer360PdfEnabled: showPDF } = useReefFlags();

  const pdfUrlResult = useQuery(
    GetAccountCustomer360PdfDocument,
    accountId != null ? { variables: { id: asUUID(accountId) } } : { skip: true },
  );

  const filePath = useMemo(
    () =>
      pdfUrlResult.data?.node.__typename === 'Account'
        ? pdfUrlResult.data.node.pdfs.customer360
        : undefined,
    [pdfUrlResult.data],
  );

  if (accountId == null) {
    return <Alert severity="info">Uh oh! We weren&apos;t able to find that account.</Alert>;
  }

  return showPDF && filePath != null ? (
    <Card
      data-uid={customerDetails$.products.section}
      elevation={0}
      variant="outlined"
      sx={{
        height: `calc(100vh - ${PRODUCTS_CONTENT_INNER_OFFSET})`,
        maxWidth: 'calc(100vw - 31.5rem)',
      }}
    >
      <PDFView filePath={filePath} assetName="Product Reports" initialScale={1.5} />
    </Card>
  ) : (
    <ComingSoonSection sectionKey="productReports" />
  );
};
