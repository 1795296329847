import type {
  DataGridPremiumProps,
  GridColTypeDef,
  GridRenderCellParams,
  GridSortCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import {
  GridLogicOperator,
  gridNumberComparator,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-premium';
import { startOfToday, subMonths } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import { DateCell, NumberCell, StringCell } from '../components/Tables/CustomGrid';
import { SupportCaseNameCell } from '../components/Tables/CustomGrid/SupportCase';
import type { SupportCasesGridRowModel } from '../components/Tables/CustomGrid/SupportCase/types';
import type { SupportCaseDetails } from '../models/account';
import { getMUIDateValue } from '../utils';
import { useCommonGridHelpers } from './useCommonGridHelpers';

interface SupportCaseGridColDef extends GridColTypeDef {
  field: keyof SupportCasesGridRowModel;
}

const renderDateCell = (params: GridRenderCellParams<SupportCasesGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderNumberCell = (params: GridRenderCellParams<SupportCasesGridRowModel, number>) => (
  <NumberCell {...params} />
);
const renderNameCell = (params: GridRenderCellParams<SupportCasesGridRowModel, string>) => (
  <SupportCaseNameCell {...params} />
);
const renderStringCell = (params: GridRenderCellParams<SupportCasesGridRowModel, string>) => (
  <StringCell {...params} />
);

export interface UseSupportCasesGridProps {
  supportCases: SupportCaseDetails[];
}
type SupportCasesGridProps = Pick<
  DataGridPremiumProps<SupportCaseDetails>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'localeText'
  | 'rowHeight'
  | 'rows'
  | 'getCellClassName'
>;
export const useSupportCasesGrid = ({
  supportCases,
}: UseSupportCasesGridProps): SupportCasesGridProps => {
  const today = startOfToday();
  const sixMonthsPrior = useMemo(() => getMUIDateValue(subMonths(today, 6)), [today]);
  const hideableColMap = useMemo(
    () => ({
      isOpen: true, // hidden by default
    }),
    [],
  );

  const { columnVisibilityModel, ...commonProps } = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'Support Case',
  });

  const columns = useMemo((): SupportCaseGridColDef[] => {
    return [
      {
        field: 'caseNumber',
        headerName: 'Case',
        hideable: false,
        minWidth: 115,
        type: 'string',
        renderCell: renderNameCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'createdAt',
        headerName: 'Created On',
        minWidth: 145,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'lastModifiedAt',
        headerName: 'Last Updated',
        minWidth: 155,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'closedOn',
        headerName: 'Closed On',
        minWidth: 162,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
        description:
          'Support ticket history shows the last 6 months from today by default. You can update the column filter to expand the time range.',
      },
      {
        field: 'priority',
        headerName: 'Priority',
        minWidth: 115,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
        // TODO: custom sorting on priority
        // Maybe it's enough to do a best-effort heuristic taking into account the
        // most common priorities customers use - e.g. numbers, 'P0,P1', and "High/medium/low" etc
      },
      {
        field: 'durationDays',
        headerName: 'Duration',
        description: 'Total number of days a ticket was active.',
        minWidth: 125,
        type: 'number',
        renderCell: renderNumberCell,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        hideable: false,
        minWidth: 115,
        type: 'string',
        groupable: true,
        aggregable: false,
        sortComparator: (
          v1: Pick<SupportCasesGridRowModel, 'isOpen' | 'status'>,
          v2: Pick<SupportCasesGridRowModel, 'isOpen' | 'status'>,
          params1: GridSortCellParams<SupportCasesGridRowModel>,
          params2: GridSortCellParams<SupportCasesGridRowModel>,
        ) => {
          const openComparatorResult = gridNumberComparator(v1.isOpen, v2.isOpen, params1, params2);
          if (openComparatorResult !== 0) {
            return openComparatorResult;
          }
          return gridStringOrNumberComparator(v1.status, v2.status, params1, params2);
        },
        valueGetter: (params: GridValueGetterParams<SupportCasesGridRowModel>) => ({
          status: params.row.status,
          isOpen: params.row.isOpen,
        }),
        valueFormatter: ({ value }: GridValueFormatterParams<SupportCasesGridRowModel>) =>
          value.status,
      },
      {
        field: 'caseType',
        headerName: 'Type',
        hideable: false,
        minWidth: 130,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'supportTier',
        headerName: 'Support Tier',
        hideable: true,
        minWidth: 148,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
        // TODO: allow sorting?
      },
      {
        field: 'isOpen',
        headerName: 'Is Open?',
        hideable: true,
        flex: 1,
        type: 'boolean',
        groupable: false,
        aggregable: false,
      },
    ];
  }, []);

  const initialState: SupportCasesGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel,
      },
      filter: {
        filterModel: {
          items: [
            {
              field: 'closedOn',
              operator: 'after',
              value: sixMonthsPrior,
              id: 'closedOn-after-6mo-prior',
            },
            {
              field: 'closedOn',
              operator: 'isEmpty',
              id: 'closedOn-is-empty',
            },
          ],
          logicOperator: GridLogicOperator.Or,
          quickFilterValues: [],
          quickFilterLogicOperator: GridLogicOperator.Or,
        },
      },
      pinnedColumns: { left: ['name'] },
      sorting: {
        sortModel: [
          {
            field: 'status',
            sort: 'desc',
          },
          {
            field: 'createdAt',
            sort: 'desc',
          },
        ],
      },
    }),
    [columnVisibilityModel, sixMonthsPrior],
  );

  const getCellClassName = useCallback<Required<SupportCasesGridProps>['getCellClassName']>(
    ({ field, row }) =>
      field === 'status' && !row.isOpen ? 'support-cases-grid--cell--closed' : '',
    [],
  );

  return {
    ...commonProps,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    rowHeight: 42,
    initialState,
    columns,
    rows: supportCases,
    getCellClassName,
  };
};
