import type { ObjValues } from '../types/util';

/** Action Card */
const cardComponent = 'action-card';
const cardTitle = 'action-card-title';
const cardCheckbox = 'action-card-checkbox';
const cardLink = (index: number): `action-card-link-${number}` => `action-card-link-${index}`;
// const description = 'action-card-description',
// const totalCustomers = 'action-card-total-customer',
// const totalArr = 'action-card-total-arr',
// const totalPipeline = 'action-card-total-pipeline',
interface Action$Card {
  component: typeof cardComponent;
  title: typeof cardTitle;
  checkbox: typeof cardCheckbox;
  link: typeof cardLink;
}
const card: Action$Card = {
  component: cardComponent,
  title: cardTitle,
  checkbox: cardCheckbox,
  link: cardLink,
};

/** Action List */
const listComponent = 'actions-list';
const listItem = (index: number): `action-selector-${number}` => `action-selector-${index}`;
interface Action$List {
  component: typeof listComponent;
  item: typeof listItem;
}
const list: Action$List = {
  component: listComponent,
  item: listItem,
};

export const action$ = {
  card,
  list,
};
export type Action$Values = ObjValues<Action$Card> | ObjValues<Action$List>;
