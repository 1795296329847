import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
} from '@mui/material';
import { add, format } from 'date-fns';
import React from 'react';

import { DATE_FORMAT_LONG_LOCALIZED } from '../../../../constants';
import { useUserDateSettings } from '../../../../contexts/userSettingsContext';
import { ReefRecommendationStatus } from '../../../../graphql/generated';
import type { RiskRecommendation } from '../../../../models/recommendation';

interface DismissRecommendationDialogProps {
  open: boolean;
  recommendation: RiskRecommendation | null;
  onClose: () => void;
  onDismiss: () => void;
  onExited: () => void;
  hasError: boolean;
  loading: boolean;
}

export const DismissRecommendationDialog: React.FC<DismissRecommendationDialogProps> = ({
  open,
  recommendation,
  onClose,
  onDismiss,
  onExited,
  hasError,
  loading,
}) => {
  const { locale } = useUserDateSettings();
  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }} maxWidth="sm">
      {recommendation != null && recommendation.status === ReefRecommendationStatus.Rejected ? (
        <Alert sx={{ ml: 4, mr: 10, mt: 4 }} severity="warning">
          {recommendation.statusUpdatedOn != null ? (
            <>
              This recommendation was dismissed on{' '}
              {format(recommendation.statusUpdatedOn, DATE_FORMAT_LONG_LOCALIZED, { locale })}.
            </>
          ) : (
            <>This recommendation has already been dismissed.</>
          )}
        </Alert>
      ) : null}
      <DialogTitle sx={{ pt: 4, pb: 0, px: 4 }}>
        Are you sure you want to dismiss this recommendation?
      </DialogTitle>
      <IconButton
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: 4, py: 1 }}>
        <DialogContentText>
          Reef will resurface{' '}
          {loading || recommendation == null ? (
            // DialogContentText renders as a <p> so we need this to be a <span> (instead of a <div>)
            <Stack component="span" display="inline-flex" direction="row">
              <Skeleton sx={{ mr: 1 }} width={100} /> for <Skeleton sx={{ ml: 1 }} width={100} />
            </Stack>
          ) : (
            <>
              <em>{recommendation.playbook.title}</em> for <em>{recommendation.account.name}</em>
            </>
          )}{' '}
          in{' '}
          <span
            title={`dismissed until ${format(
              add(
                // use the recommendation status timestamp if there is one
                recommendation?.statusUpdatedOn != null &&
                  recommendation.status === ReefRecommendationStatus.Rejected
                  ? recommendation.statusUpdatedOn
                  : Date.now(),
                { days: 90 },
              ),
              DATE_FORMAT_LONG_LOCALIZED,
              { locale },
            )}`}
          >
            90 days
          </span>{' '}
          if it is available.
        </DialogContentText>
        {hasError && (
          <Box sx={{ mt: 1 }}>
            <Alert severity="error" variant="outlined">
              <AlertTitle>Uh oh 😬</AlertTitle>
              Failed to dismiss recommendation.
            </Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', px: 4, pb: 4 }}>
        <LoadingButton
          disabled={
            recommendation == null || recommendation.status === ReefRecommendationStatus.Rejected
          }
          variant="contained"
          color="primary"
          onClick={onDismiss}
          loading={loading}
        >
          Dismiss Recommendation
        </LoadingButton>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
