/** List Details */
import type { ObjValues } from '../types/util';

const component = 'list-of-customers-page';
// const listTabBtn = 'LISTS-tab-button';
const leftMenuNewListBtn = 'nav-lists-new-lists';
const leftMenu = 'list-of-customers-page-left-menu';
// const leftMenuIndustries = 'list-of-customers-page-left-menu-industries';
// const sprintTabBtn = 'SPRINTS-tab-button';
// const headerKebab = 'list-of-customers-header-kebab';
// const headerRemove = 'list-of-customers-header-remove';
// const headerRename = 'list-of-customers-header-rename';
// const headerDuplicate = 'list-of-customers-header-duplicate';
// const rowRemoveCustomerBtn: (index: number) => `list-of-customers-row-remove-customer-btn-${index}`,
const customersTable = 'list-of-customers-page-customers-table';
const customersByActionTable = 'list-of-customers-page-by-action-customer-table';
const customersByActionTableTitle = 'list-of-customers-page-by-action-customer-table-title';
const leftMenuItemMenu = (name?: string): `nav-list-of-customers-menu-${string}` =>
  `nav-list-of-customers-menu-${name ?? ''}`;
const leftMenuItemRemoveBtn = (name?: string): `nav-list-of-customers-menu-remove-${string}` =>
  `nav-list-of-customers-menu-remove-${name ?? ''}`;
const leftMenuItemRenameBtn = (name?: string): `nav-list-of-customers-menu-rename-${string}` =>
  `nav-list-of-customers-menu-rename-${name ?? ''}`;
const moreBtn = 'user-list-secondary-button';
const leftMenuItemBtn = 'user-list-button';
export interface ListOfCustomers$Details {
  leftMenuNewListBtn: typeof leftMenuNewListBtn;
  leftMenu: typeof leftMenu;
  customersTable: typeof customersTable;
  leftMenuItemMenu: typeof leftMenuItemMenu;
  leftMenuItemRemoveBtn: typeof leftMenuItemRemoveBtn;
  leftMenuItemRenameBtn: typeof leftMenuItemRenameBtn;
  component: typeof component;
  moreBtn: typeof moreBtn;
  leftMenuItemBtn: typeof leftMenuItemBtn;
  customersByActionTable: typeof customersByActionTable;
  customersByActionTableTitle: typeof customersByActionTableTitle;
}
const overview = {
  header: 'list-of-customers-header',
  search: 'sprint-search',
  searchInput: 'sprint-search-input',
  addCustomersBtn: 'list-of-customers-header-add-customer-btn',
  clearChangesBtn: 'list-of-customers-header-clear-changes-btn',
} as const;
const details: ListOfCustomers$Details = {
  leftMenuNewListBtn,
  leftMenu,
  customersTable,
  leftMenuItemMenu,
  leftMenuItemRemoveBtn,
  leftMenuItemRenameBtn,
  component,
  moreBtn,
  leftMenuItemBtn,
  customersByActionTable,
  customersByActionTableTitle,
};

/** New List */
const newListComponent = 'app-list-of-customers-form-dialog';
const saveBtn = 'new-list-save';
const newListCancelBtn = 'new-list-cancel';
const search = 'new-list-search';
const searchInput = 'new-list-search-input';
interface ListOfCustomers$NewListDialog {
  component: typeof newListComponent;
  saveBtn: typeof saveBtn;
  cancelBtn: typeof newListCancelBtn;
  search: typeof search;
  searchInput: typeof searchInput;
}
const newListDialog: ListOfCustomers$NewListDialog = {
  component: newListComponent,
  saveBtn,
  cancelBtn: newListCancelBtn,
  search,
  searchInput,
};

/** Delete List */
const deleteListComponent = 'app-ask-dialog';
const confirmBtn = 'dialog-confirm';
const cancelBtn = 'dialog-cancel';
interface ListOfCustomers$DeleteListDialog {
  component: typeof deleteListComponent;
  confirmBtn: typeof confirmBtn;
  cancelBtn: typeof cancelBtn;
}
const deleteListDialog: ListOfCustomers$DeleteListDialog = {
  component: deleteListComponent,
  confirmBtn,
  cancelBtn,
};

/** List Form */
const formComponent = 'app-list-of-customers-form';
const nameInput = 'app-list-of-customers-form-name-input';
interface ListOfCustomers$Form {
  component: typeof formComponent;
  nameInput: typeof nameInput;
}
const form: ListOfCustomers$Form = {
  component: formComponent,
  nameInput,
};

export const listOfCustomers$ = {
  details,
  overview,
  newListDialog,
  deleteListDialog,
  form,
};

export type ListOfCustomers$Values =
  | ObjValues<ListOfCustomers$Details>
  | ObjValues<typeof overview>
  | ObjValues<ListOfCustomers$NewListDialog>
  | ObjValues<ListOfCustomers$DeleteListDialog>
  | ObjValues<ListOfCustomers$Form>;
