import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { WHOLE_PERCENT_NUMBER_FORMATTER } from '../../../../../../utils';

const ArrowIcon = ({ icon: Icon }: { icon: React.ElementType }) => {
  return <Icon id="ArrowUpwardIcon" sx={{ height: '0.5em', width: 'auto' }} />;
};

interface WeeklyDeltaDisplayProps {
  weeklyDeltaPercentage: number;
}

export const WeeklyDeltaDisplay = ({ weeklyDeltaPercentage }: WeeklyDeltaDisplayProps) => {
  const theme = useTheme();
  const deltaDirection =
    weeklyDeltaPercentage > 0 ? 'positive' : weeklyDeltaPercentage < 0 ? 'negative' : 'neutral';
  let icon;
  let color;

  switch (deltaDirection) {
    case 'positive':
      icon = <ArrowIcon icon={ArrowUpward} />;
      color = theme.palette.success.main;
      break;
    case 'negative':
      icon = <ArrowIcon icon={ArrowDownward} />;
      color = theme.palette.error.main;
      break;
    default:
      icon = <>&mdash;</>;
      color = theme.palette.grey[500];
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ ml: 1, color: color }}>
      {icon}
      <Typography variant="body1">
        {WHOLE_PERCENT_NUMBER_FORMATTER.format(Math.abs(weeklyDeltaPercentage))}
      </Typography>
    </Stack>
  );
};
