import { FilterAlt, Visibility, VisibilityOutlined } from '@mui/icons-material';
import type { SelectChangeEvent, Theme } from '@mui/material';
import {
  Backdrop,
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import type { GridState } from '@mui/x-data-grid-premium';
import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid-premium';
import { getQuarter, getYear, startOfToday } from 'date-fns';
import type { Dictionary } from 'lodash';
import { get, isEmpty, keyBy, orderBy, set, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useUserSettingsContext } from '../../../contexts/userSettingsContext';
import { useReefFlags } from '../../../hooks/flags';
import type { Renewal } from '../../../hooks/renewal';
import { transformRenewal, useRenewals } from '../../../hooks/renewal';
import type { GroupingRenewalField } from '../../../hooks/useBreakdownByGroupChartConfig';
import type {
  OutcomesWeightingField,
  RenewalScoreField,
} from '../../../hooks/useOutcomesByScoreChartConfig';
import type { UseRenewalsGridProps } from '../../../hooks/useRenewalsGrid';
import { useRenewalsGrid } from '../../../hooks/useRenewalsGrid';
import { ResultType } from '../../../models/result';
import { analytics$ } from '../../../selectors/analytics';
import { isNonNull, pluralize } from '../../../utils';
import { ChartTypeSelect } from '../../Charts/Analytics/ChartTypeSelect';
import {
  DetailChart,
  GroupingFieldSelect,
  ScoreFieldSelect,
  WeightingFieldSelect,
} from '../../Charts/Analytics/DetailChart';
import { HeroChart } from '../../Charts/Analytics/HeroChart';
import type { DetailCharts, HeroCharts } from '../../Charts/Analytics/types';
import { ComingSoonIcon } from '../../ComingSoon';
import { CustomGrid } from '../../Tables/CustomGrid';

// TODO: graph legends/totals
// TODO: chart axes bounds tied to FY? or full dataset?
// TODO: reintroduce product names in renewal model? -- have to be added as Renewal details page, not in rollup

/**
 * Async function to load mocked renewals + load extra bundles.
 * @param cb dispatch function to update renewals
 */
async function loadMockedRenewals(cb: (r: Renewal[]) => void): Promise<void> {
  const { someSeededAccountRenewals } = await import('../../../graphql/mocks');
  const mockedRenewals = someSeededAccountRenewals({}, 300).map((r, idx) =>
    transformRenewal(r, idx, {}, 'USD'),
  );

  cb(mockedRenewals);
}

interface UseAllRenewalsOptions {
  showDemoData: boolean;
}

/**
 * Hook to get all renewals.
 * @param root0 options
 * @param root0.showDemoData flag to show mocked renewals
 * @returns list of renewals (or empty)
 */
function useAllRenewals({ showDemoData }: UseAllRenewalsOptions): [Renewal[], boolean] {
  const result = useRenewals({ skip: showDemoData });
  const loading = useMemo(() => result.state === ResultType.Loading, [result.state]);

  const [allRenewals, setAllRenewals] = useState<Renewal[]>([]);
  useEffect(() => {
    if (result.state === ResultType.Value) {
      setAllRenewals(result.value);
    }
    if (showDemoData) {
      loadMockedRenewals(setAllRenewals);
    }
  }, [result, showDemoData]);

  return [allRenewals, loading];
}

export const Renewals = () => {
  const { renewalAnalyticsEnabled } = useReefFlags();
  const [params] = useSearchParams();
  const showMocked = useMemo(() => params.get('mocked') != null, [params]);
  const {
    admin: { showDemoData: showDemoDataSetting },
  } = useUserSettingsContext();
  const showDemoData = useMemo(
    () => showDemoDataSetting || showMocked,
    [showDemoDataSetting, showMocked],
  );
  const [renewalsFilterModel, setRenewalsFilterModel] =
    useState<UseRenewalsGridProps['filterModel']>();
  const [gridState, setGridState] = useState<GridState>();
  const [showCharts, setShowCharts] = useState(true);
  const [renewalOwnerIds, setRenewalOwnerIds] = useState<string[]>([]);
  const defaultFiscalYear = useMemo(() => `${getYear(startOfToday()) + 1}`, []);
  const defaultFiscalQuarter = useMemo(() => `Q${getQuarter(startOfToday())}`, []);

  const showNonInteractiveMode = useMemo(() => !renewalAnalyticsEnabled, [renewalAnalyticsEnabled]);
  const [heroChart, setHeroChart] = useState<HeroCharts>('total-renewals-bars');
  const [detailChart, setDetailChart] = useState<DetailCharts>('renewal-overview-waterfall');
  const [groupingField, setGroupingField] = useState<GroupingRenewalField>('onTimeStatus');
  const [scoreField, setScoreField] = useState<RenewalScoreField>('customHealthCategoryCurrent');
  const [weightingField, setWeightingField] = useState<OutcomesWeightingField>('count');

  const [allRenewals, loading] = useAllRenewals({ showDemoData });
  const allRenewalsById = useMemo(
    () =>
      allRenewals.reduce<Dictionary<Renewal>>(
        (acc, renewal) => ({ ...acc, [renewal.id]: renewal }),
        {},
      ),
    [allRenewals],
  );

  const fiscalInfo = useMemo(
    () =>
      allRenewals.reduce(
        (acc, c) => {
          const fy = `${c.baseContractExpirationFiscalYear}`;
          const fq = `${c.baseContractExpirationFiscalQuarter}`;
          acc.years.add(fy);
          acc.quarters.add(fq);
          const prevCount = get(acc.counts, `${fy}.${fq}`, 0) as number;
          set(acc.counts, `${fy}.${fq}`, 1 + prevCount);
          return acc;
        },
        {
          years: new Set<string>(),
          quarters: new Set<string>(),
          counts: {
            [defaultFiscalYear]: {
              [defaultFiscalQuarter]: 0,
            },
          },
        },
      ),
    [allRenewals, defaultFiscalQuarter, defaultFiscalYear],
  );

  const onRenewalOwnersChange = (event: SelectChangeEvent<typeof renewalOwnerIds>) => {
    const {
      target: { value },
    } = event;
    setRenewalOwnerIds(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const fiscalYears = useMemo(() => Array.from(fiscalInfo.years).sort(), [fiscalInfo.years]);
  const initialFiscalYear = useMemo(
    () =>
      fiscalYears.length > 0
        ? (fiscalYears.find((fy) => fy === `${getYear(startOfToday())}`) ?? fiscalYears[0])
        : null,
    [fiscalYears],
  );
  const [fiscalYear, setFiscalYear] = useState(defaultFiscalYear);

  // TODO: consider All quarters?
  const fiscalQuarters = useMemo(
    () => Array.from(fiscalInfo.quarters).sort(),
    [fiscalInfo.quarters],
  );
  const initialFiscalQuarter = useMemo(() => {
    if (fiscalQuarters.length > 0 && initialFiscalYear != null) {
      const availableQuarters = Object.keys(fiscalInfo.counts[initialFiscalYear]).sort();
      return availableQuarters.includes(defaultFiscalQuarter)
        ? defaultFiscalQuarter
        : availableQuarters.at(0);
    }
    return null;
  }, [defaultFiscalQuarter, fiscalInfo.counts, fiscalQuarters.length, initialFiscalYear]);
  const [fiscalQuarter, setFiscalQuarter] = useState(defaultFiscalQuarter);
  const onFQChange = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>, newFQ: string | null) =>
      newFQ != null ? setFiscalQuarter(newFQ) : undefined,
    [],
  );
  const onFYChange = useCallback(
    (e: SelectChangeEvent) => {
      setFiscalYear(e.target.value);
      const nextFQ = fiscalQuarters.find((fq) => fiscalInfo.counts[e.target.value][fq] != null);
      if (nextFQ) {
        setFiscalQuarter(nextFQ);
      }
    },
    [fiscalInfo.counts, fiscalQuarters],
  );

  const onHeroChange = useCallback(
    (newType: HeroCharts) => {
      setHeroChart(newType);
      if (!showCharts) {
        setShowCharts(true);
      }
    },
    [showCharts],
  );

  const onDetailChange = useCallback(
    (newType: DetailCharts) => {
      setDetailChart(newType);
      if (!showCharts) {
        setShowCharts(true);
      }
    },
    [showCharts],
  );

  const onFilterModelChange = useCallback<Required<UseRenewalsGridProps>['onFilterModelChange']>(
    (model) => setRenewalsFilterModel(model),
    [],
  );
  const onStateChange = useCallback<Required<UseRenewalsGridProps>['onStateChange']>(
    (state) => setGridState(state),
    [],
  );

  const filteredRenewals = useMemo(() => {
    const renewalOwnerIdsSet = new Set(renewalOwnerIds);
    return allRenewals.filter((c) => {
      const isInSelectedFiscalDimensions =
        `${c.baseContractExpirationFiscalYear}` === fiscalYear &&
        `${c.baseContractExpirationFiscalQuarter}` === fiscalQuarter;
      return renewalOwnerIdsSet.size > 0
        ? isInSelectedFiscalDimensions && renewalOwnerIdsSet.has(c.renewalOwnerId ?? '')
        : isInSelectedFiscalDimensions;
    });
  }, [allRenewals, fiscalQuarter, fiscalYear, renewalOwnerIds]);
  const renewalsGridProps = useRenewalsGrid({
    renewals: filteredRenewals,
    filterModel: renewalsFilterModel,
    onFilterModelChange,
    onStateChange,
  });
  const gridFilteredRenewals = useMemo(() => {
    const gridFilteredRows =
      !loading && renewalsGridProps.apiRef && gridState
        ? gridFilteredSortedRowIdsSelector(gridState, renewalsGridProps.apiRef.current.instanceId)
        : null;
    if (gridFilteredRows != null) {
      return gridFilteredRows.map((gfr) => allRenewalsById[gfr] ?? null).filter(isNonNull);
    }
    return filteredRenewals;
  }, [allRenewalsById, filteredRenewals, gridState, loading, renewalsGridProps.apiRef]);
  const hasGridFilteredCharts = useMemo(() => {
    return !isEmpty(renewalsFilterModel?.items);
  }, [renewalsFilterModel?.items]);
  const filterTooltipContent = useMemo(
    () =>
      `This chart is showing ${gridFilteredRenewals.length} of ${filteredRenewals.length} Renewals, using the ${renewalsFilterModel?.items.length} ${pluralize({ count: renewalsFilterModel?.items.length ?? NaN, singular: 'filter' })} you have applied below.`,
    [filteredRenewals.length, gridFilteredRenewals.length, renewalsFilterModel?.items.length],
  );

  const renewalOwners = useMemo(
    () =>
      orderBy(
        uniqBy(
          allRenewals.map((r) => r.renewalOwner),
          'id',
        ),
        'name',
      ),
    [allRenewals],
  );
  const renewalOwnersById = useMemo(() => keyBy(renewalOwners, 'id'), [renewalOwners]);

  const gridWrapperBoxProps = useMemo(
    () => ({
      outer: {
        sx: {
          height: showCharts ? 'calc(100vh - 30rem)' : 'calc(100vh - 12.25rem)',
          transition: (theme: Theme) => theme.transitions.create('height'),
        },
      },
    }),
    [showCharts],
  );

  const visibilityIcon = useMemo(
    () => (showCharts ? <Visibility /> : <VisibilityOutlined />),
    [showCharts],
  );

  const renewalOwnersLabel = useMemo(
    () =>
      renewalOwnerIds.length
        ? `${renewalOwnerIds.length} Renewal ${pluralize({
            count: renewalOwnerIds.length,
            singular: 'Lead',
          })}`
        : 'Renewal Leads',
    [renewalOwnerIds.length],
  );

  useEffect(() => {
    if (initialFiscalYear != null && initialFiscalYear !== defaultFiscalYear) {
      setFiscalYear(initialFiscalYear);
    }
  }, [defaultFiscalYear, initialFiscalYear]);

  useEffect(() => {
    if (initialFiscalQuarter != null && initialFiscalQuarter !== defaultFiscalQuarter) {
      setFiscalQuarter(initialFiscalQuarter);
    }
  }, [defaultFiscalQuarter, initialFiscalQuarter]);

  return (
    <Box
      data-uid={analytics$.page.root}
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Container sx={{ mx: 0 }} maxWidth={false}>
        <Stack spacing={0}>
          <Paper sx={{ py: 2, backgroundColor: 'transparent' }} elevation={0}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography data-uid={analytics$.page.header} variant="h4">
                {showMocked ? '[Demo] ' : null}Renewals: {fiscalYear} {fiscalQuarter}
              </Typography>
              <Box flexGrow={2}></Box>
              <Box>
                <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
                  <InputLabel id="renewal-owner-select-label">{renewalOwnersLabel}</InputLabel>
                  <Select
                    data-uid={analytics$.renewalOwnersSelect.select}
                    labelId="renewal-owner-select-label"
                    id="renewal-owner-select"
                    multiple
                    renderValue={(selected) =>
                      selected
                        .map((sel) => get(renewalOwnersById, sel)?.name ?? 'No Renewal Lead')
                        .join(', ')
                    }
                    value={loading ? [] : renewalOwnerIds}
                    onChange={onRenewalOwnersChange}
                    input={<OutlinedInput label={renewalOwnersLabel} />}
                    disabled={loading || showNonInteractiveMode}
                  >
                    {renewalOwners.map((ro) => (
                      <MenuItem
                        data-uid={analytics$.renewalOwnersSelect.item(`${ro.id}`)}
                        key={ro.id}
                        value={ro.id ?? ''}
                      >
                        <Checkbox checked={renewalOwnerIds.indexOf(ro.id ?? '') > -1} />
                        <ListItemText primary={ro.name ?? 'No Renewal Lead'} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="fiscal-year-select-label">Fiscal Year</InputLabel>
                  <Select
                    data-uid={analytics$.fiscalYearSelect.select}
                    labelId="fiscal-year-select-label"
                    id="fiscal-year-select"
                    value={loading ? '' : fiscalYear}
                    onChange={onFYChange}
                    input={<OutlinedInput label="Fiscal Year" />}
                    disabled={loading || showNonInteractiveMode}
                  >
                    {fiscalYears.map((fy, i) => (
                      <MenuItem
                        data-uid={analytics$.fiscalYearSelect.item(fy)}
                        key={`${fy}-${i}`}
                        value={fy}
                      >
                        {fy}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{ m: 1, mr: 0, minWidth: 129 }} size="small">
                  <ToggleButtonGroup
                    data-uid={analytics$.fiscalQuarterToggle.group}
                    value={loading ? '' : fiscalQuarter}
                    exclusive
                    onChange={onFQChange}
                    disabled={loading || showNonInteractiveMode}
                    size="small"
                  >
                    {fiscalQuarters.map((fq, i) => (
                      <ToggleButton
                        data-uid={analytics$.fiscalQuarterToggle.button(fq)}
                        key={`${fq}-${i}`}
                        value={fq}
                        disabled={fiscalInfo.counts[fiscalYear][fq] == null}
                      >
                        {fq}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </FormControl>
              </Box>
            </Stack>
          </Paper>
          <Stack direction="row" spacing={3}>
            <Paper
              sx={{
                width: '25vw',
                minWidth: '20.25rem',
                height: showCharts ? '21.375rem' : '3.5rem',
                transition: (theme) => theme.transitions.create('height'),
              }}
              elevation={0}
              variant="outlined"
            >
              <>
                {loading ? (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <ChartTypeSelect
                        variant="hero"
                        value={heroChart}
                        onChange={onHeroChange}
                        selectProps={{ disabled: showNonInteractiveMode }}
                      />
                      <Box>
                        {hasGridFilteredCharts ? (
                          <Tooltip title={filterTooltipContent}>
                            <Badge badgeContent={gridFilteredRenewals.length} color="info" showZero>
                              <FilterAlt color="disabled" />
                            </Badge>
                          </Tooltip>
                        ) : null}
                        <IconButton
                          size="small"
                          sx={{ margin: '0.6875rem' }}
                          onClick={() => setShowCharts(!showCharts)}
                        >
                          {visibilityIcon}
                        </IconButton>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        height: showCharts ? '17.875rem' : '0px',
                        transition: (theme) => theme.transitions.create('height'),
                      }}
                    >
                      {showCharts ? (
                        <HeroChart chart={heroChart} data={gridFilteredRenewals} />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                )}
              </>
            </Paper>
            <Paper
              sx={{
                width: '75vw',
                height: showCharts ? '21.375rem' : '3.5rem',
                transition: (theme) => theme.transitions.create('height'),
              }}
              elevation={0}
              variant="outlined"
            >
              <>
                {loading ? (
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack direction="row">
                        <ChartTypeSelect
                          variant="detail"
                          value={detailChart}
                          onChange={onDetailChange}
                          selectProps={{ disabled: showNonInteractiveMode }}
                        />
                        {/* TODO: add secondary control wrapper */}
                        {detailChart === 'breakdown-by-group-bars' ? (
                          <GroupingFieldSelect
                            value={groupingField}
                            onChange={setGroupingField}
                            selectProps={{ disabled: showNonInteractiveMode }}
                          />
                        ) : null}
                        {detailChart === 'outcomes-by-score-sankey' ? (
                          <>
                            <ScoreFieldSelect
                              value={scoreField}
                              onChange={setScoreField}
                              selectProps={{ disabled: showNonInteractiveMode }}
                            />
                            <WeightingFieldSelect
                              value={weightingField}
                              onChange={setWeightingField}
                              selectProps={{ disabled: showNonInteractiveMode }}
                            />
                          </>
                        ) : null}
                      </Stack>
                      <Box>
                        {hasGridFilteredCharts ? (
                          <Tooltip title={filterTooltipContent}>
                            <Badge badgeContent={gridFilteredRenewals.length} color="info" showZero>
                              <FilterAlt color="disabled" />
                            </Badge>
                          </Tooltip>
                        ) : null}
                        <IconButton
                          size="small"
                          sx={{ margin: '0.6875rem' }}
                          onClick={() => setShowCharts(!showCharts)}
                        >
                          {visibilityIcon}
                        </IconButton>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        height: showCharts ? '17.875rem' : '0px',
                        transition: (theme) => theme.transitions.create('height'),
                      }}
                    >
                      {showCharts ? (
                        <DetailChart
                          chart={detailChart}
                          data={gridFilteredRenewals}
                          groupingField={groupingField}
                          scoreField={scoreField}
                          weightingField={weightingField}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                )}
              </>
            </Paper>
          </Stack>
        </Stack>
      </Container>
      <Box
        data-uid={analytics$.page.grid}
        sx={{
          mt: 3,
          mx: 3,
          backgroundColor: 'white',
        }}
      >
        <CustomGrid
          {...renewalsGridProps}
          loading={loading}
          GridWrapperBoxProps={gridWrapperBoxProps}
        >
          {showNonInteractiveMode ? (
            <Backdrop
              open
              sx={{
                zIndex: 4,
                position: 'relative',
                height: '100%',
                marginBottom: '-31.75%',
                backgroundColor: 'rgba(186, 186, 186, 0.2)',
              }}
            >
              <ComingSoonIcon />
            </Backdrop>
          ) : null}
        </CustomGrid>
      </Box>
    </Box>
  );
};
