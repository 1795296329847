import type { SelectChangeEvent, SelectProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useCallback } from 'react';

import type { GroupingRenewalField } from '../../../../hooks/useBreakdownByGroupChartConfig';
import { GROUPING_RENEWAL_FIELDS } from '../../../../hooks/useBreakdownByGroupChartConfig';
import { analytics$ } from '../../../../selectors/analytics';

type GroupingFieldSelectProps = {
  value: GroupingRenewalField;
  onChange: (newType: GroupingRenewalField) => void;
  selectProps?: Partial<SelectProps<GroupingRenewalField>>;
};
const GROUPING_FIELD_LABELS: Record<GroupingRenewalField, string> = {
  onTimeStatus: 'On Time Status',
  customHealthCategory3mo: 'Customer Healthscore (3mo)',
  customHealthCategory6mo: 'Customer Healthscore (6mo)',
  customHealthCategory9mo: 'Customer Healthscore (9mo)',
  customHealthCategoryCurrent: 'Customer Healthscore (Current)',
  reefRenewal3mo: 'Reef Renewal Score (3mo)',
  reefRenewal6mo: 'Reef Renewal Score (6mo)',
  reefRenewal9mo: 'Reef Renewal Score (9mo)',
  reefRenewalCurrent: 'Reef Renewal Score (Current)',
  renewalOwnerName: 'Renewal Lead',
  accountIndustry: 'Industry',
};
export const GroupingFieldSelect = ({ value, onChange, selectProps }: GroupingFieldSelectProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<GroupingRenewalField>) =>
      event instanceof Event ? onChange(event.target.value) : undefined,
    [onChange],
  );
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="breakdown-grouping-field-select-label">Group</InputLabel>
      <Select<GroupingRenewalField>
        data-uid={analytics$.groupingFieldSelect.select}
        labelId="breakdown-grouping-field-select-label"
        id="grouping-field-select-small"
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label="Group" />}
        {...selectProps}
      >
        {GROUPING_RENEWAL_FIELDS.map((rf) => (
          <MenuItem data-uid={analytics$.groupingFieldSelect.item(rf)} key={rf} value={rf}>
            {GROUPING_FIELD_LABELS[rf]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
