import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

import type { ProductMetricViews } from '../types';

interface ProductMetricPageToggleProps {
  metricView: ProductMetricViews;
  onChange: (e: React.MouseEvent<HTMLElement>, newMetricView: ProductMetricViews) => void;
}

export const ProductMetricPageToggle = ({ metricView, onChange }: ProductMetricPageToggleProps) => {
  return (
    <ToggleButtonGroup
      color="primary"
      value={metricView}
      exclusive
      onChange={onChange}
      size="small"
    >
      <ToggleButton value="overview_metrics">Overview Metrics</ToggleButton>
      <ToggleButton value="product_level_metrics">Product-Level Metrics</ToggleButton>
    </ToggleButtonGroup>
  );
};
