import { Typography } from '@mui/material';
import type { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { isNaN } from 'lodash';
import React from 'react';

import { CURRENCY_FORMATTER } from '../../../../constants';
import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import { FieldWrapper } from '../Account/FieldWrapper';

export type CurrencyCellProps = GridRenderCellParams<GridValidRowModel, number>;
export const CurrencyCell = ({ field, value, rowNode }: CurrencyCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  return (
    <FieldWrapper field={field}>
      <Typography variant="body2" title={value?.toString()}>
        {value != null ? CURRENCY_FORMATTER.format(value) : missingValue}
      </Typography>
    </FieldWrapper>
  );
};

// NOTE: adding new cell content renderer to scope currency changes to the specific converted ones
export const MultiCurrencyCell = ({ field, value, rowNode }: CurrencyCellProps) => {
  const { currencyFormatter } = useUserSettingsContext();
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  return (
    <FieldWrapper field={field}>
      <Typography variant="body2" title={value?.toString()}>
        {value != null && !isNaN(value) ? currencyFormatter.format(value) : missingValue}
      </Typography>
    </FieldWrapper>
  );
};
