import type { Chart, LegendItem, Plugin } from 'chart.js';
import { uniq } from 'lodash';

import { RenewedSegments } from '../../../../hooks/renewal';
import { isNonNull, PERCENT_NUMBER_FORMATTER } from '../../../../utils';

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id);
  if (legendContainer == null) {
    throw new Error(`uh oh, missing legend container: ${id}`);
  }
  let listContainer: HTMLUListElement | null = legendContainer.querySelector('ul#legend-list');
  if (listContainer == null) {
    listContainer = document.createElement('ul');
    listContainer.id = 'legend-list';
    listContainer.className = 'custom-total-renewal-status-chart-legend--list';
    legendContainer.appendChild(listContainer);
  }
  return listContainer;
};

const getOrCreateLegendHeader = (id: string) => {
  const legendContainer = document.getElementById(id);
  if (legendContainer == null) {
    throw new Error(`uh oh, missing legend container: ${id}`);
  }
  let legendHeader: HTMLDivElement | null = legendContainer.querySelector('div#legend-header');
  if (legendHeader == null) {
    legendHeader = document.createElement('div');
    legendHeader.id = 'legend-header';
    legendHeader.className = 'custom-total-renewal-status-chart-legend--header';
    legendContainer.appendChild(legendHeader);
  }
  return legendHeader;
};

const createDivider = () => {
  const divider = document.createElement('hr');
  divider.className = 'custom-total-renewal-status-chart-legend--divider';
  return divider;
};

const createLegendItem = (text: string): LegendItem => ({
  text,
  hidden: false,
});

type ParsedDatum = { y: number };
const getLegendData = (chart: Chart<'bar'>, item: LegendItem) => {
  const [expMeta, renMeta] = chart.getDatasetMeta(item.datasetIndex ?? NaN)
    ._parsed as ParsedDatum[];
  // NOTE: ignore Pending amount for Total Renewed
  return [expMeta.y, item.text !== RenewedSegments.Pending ? renMeta.y : 0];
};
const getChartData = (chart: Chart<'bar'>) => {
  const noop = () => [];
  const items = (chart.options.plugins?.legend?.labels?.generateLabels || noop)(chart);
  const legendData = items.map((item) => getLegendData(chart, item));
  const [totalExpiring, totalRenewed] = legendData.reduce<[number, number]>(
    ([totalExp, totalRen], [exp, ren]) => [totalExp + (exp ?? 0), totalRen + (ren ?? 0)],
    [0, 0],
  );
  const renewalNRR = totalRenewed / totalExpiring;
  return {
    items,
    totalExpiring,
    totalRenewed,
    renewalNRR,
  };
};

const addLegendHeader = (header: HTMLDivElement, item: LegendItem, value: string) => {
  const div = document.createElement('div');
  div.className = 'custom-total-renewal-status-chart-legend--row';

  const itemTitleContainer = document.createElement('div');
  itemTitleContainer.className = 'custom-total-renewal-status-chart-legend--label--header';
  itemTitleContainer.style.textDecoration = item.hidden ? 'line-through' : '';
  itemTitleContainer.appendChild(document.createTextNode(item.text));

  const itemTotalContainer = document.createElement('div');
  itemTotalContainer.className = 'custom-total-renewal-status-chart-legend--total--header';
  itemTotalContainer.style.textDecoration = item.hidden ? 'line-through' : '';
  itemTotalContainer.appendChild(document.createTextNode(value));

  const textContainer = document.createElement('div');
  textContainer.className = 'custom-total-renewal-status-chart-legend--text';
  textContainer.appendChild(itemTitleContainer);
  textContainer.appendChild(itemTotalContainer);

  div.appendChild(textContainer);
  header.appendChild(div);
};
const addCurrencyLegendHeader = (
  header: HTMLDivElement,
  item: LegendItem,
  total: number,
  currencyFormatter: Intl.NumberFormat,
) => addLegendHeader(header, item, currencyFormatter.format(total));
const addPercentLegendHeader = (header: HTMLDivElement, item: LegendItem, percent: number) =>
  addLegendHeader(header, item, PERCENT_NUMBER_FORMATTER.format(percent));

const addLegendItem = (
  ul: HTMLUListElement,
  item: LegendItem,
  total: number,
  isLastItem: boolean,
  currencyFormatter: Intl.NumberFormat,
) => {
  const li = document.createElement('li');
  li.className = 'custom-total-renewal-status-chart-legend--row';

  const bulletSpan = document.createElement('span');
  bulletSpan.className = 'custom-total-renewal-status-chart-legend--bullet';
  bulletSpan.style.background = item.fillStyle?.toString() ?? '';
  bulletSpan.style.borderColor = item.strokeStyle?.toString() ?? '';
  bulletSpan.style.borderWidth = `${item.lineWidth}px`;

  const itemTitleContainer = document.createElement('div');
  itemTitleContainer.className = 'custom-total-renewal-status-chart-legend--label';
  itemTitleContainer.style.textDecoration = item.hidden ? 'line-through' : '';
  itemTitleContainer.appendChild(document.createTextNode(item.text));

  const itemTotalContainer = document.createElement('div');
  itemTotalContainer.className = 'custom-total-renewal-status-chart-legend--total';
  itemTotalContainer.style.textDecoration = item.hidden ? 'line-through' : '';
  itemTotalContainer.appendChild(document.createTextNode(currencyFormatter.format(total)));

  const textContainer = document.createElement('div');
  textContainer.className = 'custom-total-renewal-status-chart-legend--text';
  textContainer.appendChild(itemTitleContainer);
  textContainer.appendChild(itemTotalContainer);

  li.appendChild(bulletSpan);
  li.appendChild(textContainer);
  ul.appendChild(li);

  if (!isLastItem) {
    const dividerLi = document.createElement('li');
    dividerLi.style.display = 'block';
    dividerLi.appendChild(createDivider());
    ul.appendChild(dividerLi);
  }
};

export const TotalRenewalStatusLegendPlugin: Plugin<
  'bar',
  { containerId: string; currencyFormatter: Intl.NumberFormat }
> = {
  id: 'htmlLegend',
  afterUpdate(chart: Chart<'bar'>, _, options) {
    const header = getOrCreateLegendHeader(options.containerId);
    const ul = getOrCreateLegendList(options.containerId);

    while (header.firstChild) {
      header.firstChild.remove();
    }
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const { items, totalExpiring, totalRenewed, renewalNRR } = getChartData(chart);

    addCurrencyLegendHeader(
      header,
      createLegendItem('Total Expiring'),
      totalExpiring,
      options.currencyFormatter,
    );
    header.appendChild(createDivider());
    addCurrencyLegendHeader(
      header,
      createLegendItem('Total Renewed'),
      totalRenewed,
      options.currencyFormatter,
    );
    header.appendChild(createDivider());
    addPercentLegendHeader(header, createLegendItem('Renewal NRR'), renewalNRR);

    items.forEach((item, idx) =>
      addLegendItem(
        ul,
        item,
        uniq(getLegendData(chart, item).filter(isNonNull))[0],
        idx === items.length - 1,
        options.currencyFormatter,
      ),
    );
  },
};
