import { Help } from '@mui/icons-material';
import { Box, Button, Drawer, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import type { ResponsiveStyleValue } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router';

import type { AccountHeatmapConfig } from '../../hooks/useAccountChartConfig';
import type { Account } from '../../models/account';
import { sprint$, tooltip$ } from '../../selectors';
import type { HeatmapVariation } from '../../types/heatmap';
import { Heatmap } from '../Charts/Heatmap';
import { HeatmapOverview } from './HeatmapOverview';

export interface BuildSprintDrawerProps {
  /**
   * Responsive width of the drawer
   */
  width?: ResponsiveStyleValue<number>;
  /**
   * List of customers used to build a sprint.
   */
  accounts: Account[];
  /**
   * The grid dimensions of the heatmap.
   * @default 6
   */
  heatmapSize?: number;
  /**
   * If `true`, the add objectives and actions button is disabled.
   */
  createSprintDisabled: boolean;
  heatmapConfig: AccountHeatmapConfig;
  /**
   * Called when a single heatmap tile is toggled.
   * @param coords the [x, y] coords that were clicked
   */
  onSelectHeatmapTile(coords: [number, number]): void;
  /**
   * Which button does the user have selected in the heatmap variation control.
   */
  heatmapVariation: HeatmapVariation;
  /**
   * Called when a user wants to change the variant of data in the heatmap.
   * @param variant the variant of data that we display in the heatmap
   */
  onSelectHeatmapVariation(variant: HeatmapVariation): void;
  /**
   * Called when all of the tiles are toggled.
   * @param isEveryTileSelected `true` if every tile is selected when called
   */
  onToggleAllHeatmapTiles(isEveryTileSelected: boolean): void;
  /**
   * Called when the `draftSprintId` is started.
   */
  onStartSprint(): void;
}

export const BuildSprintDrawer = ({
  width,
  accounts,
  heatmapConfig,
  createSprintDisabled,
  heatmapVariation,
  onSelectHeatmapVariation,
  onSelectHeatmapTile,
  onToggleAllHeatmapTiles,
  onStartSprint,
}: BuildSprintDrawerProps) => {
  const nav = useNavigate();

  return (
    <Drawer
      open
      variant="permanent"
      anchor="right"
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width,
        },
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{ backgroundColor: (theme) => theme.palette.common.white }}
      >
        <Box>
          <Box
            sx={{
              mx: 4,
              mt: 1,
              mb: 2,
              // put the "build sprint" actions at the bottom of the drawer
              // regardless of vertical window dimensions
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ my: 'auto' }} variant="overline">
                Create Sprint
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ ml: 1 }}>
                <Tooltip
                  data-uid={tooltip$.component}
                  // because the build sprint actions sit close to the bottom, the spacing is a little cramped
                  // with the default "bottom" option here
                  placement="top"
                  title={
                    <Typography>
                      Building a <strong>sprint</strong> is act of selecting an over-arching{' '}
                      <em>objective</em> and set of <em>actions</em> you&apos;d like to do against a
                      selection of customers over a specific period of time. A typical sprint is 1-2
                      weeks long, but the amount of customers and actions you take are completely up
                      to you!
                    </Typography>
                  }
                >
                  <IconButton size="small">
                    <Help fontSize="inherit" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ my: 1 }}>
              <Box>
                <Button
                  data-uid={sprint$.drawer.startBtn}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disableElevation
                  disabled={createSprintDisabled}
                  onClick={onStartSprint}
                >
                  Start sprint
                </Button>
              </Box>
            </Box>
            <Box sx={{ my: 1 }}>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disableElevation
                  onClick={() => nav('/work/my/recommendations')}
                >
                  View Recommendations
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ mx: 4 }}>
            <HeatmapOverview
              heatmapConfig={heatmapConfig}
              heatmapVariation={heatmapVariation}
              accounts={accounts}
              onSelectHeatmapVariation={onSelectHeatmapVariation}
            />
          </Box>
          <Box sx={{ mx: 1 }}>
            <Heatmap
              {...heatmapConfig}
              onSelectTile={onSelectHeatmapTile}
              onToggleAllTiles={onToggleAllHeatmapTiles}
            />
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
};
