import { useMutation } from '@apollo/client';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useSearchContext } from '../../../../contexts/searchContext';
import { SearchProvider } from '../../../../contexts/SearchProvider';
import { UpdateObjectiveRankDocument } from '../../../../graphql/generated';
import { useObjectives } from '../../../../hooks/client';
import { ResultType } from '../../../../models/result';
import { manage$ } from '../../../../selectors/manage';
import {
  CreateActionDialog,
  type CreateActionDialogOptions,
  CreateObjectiveDialog,
  DeleteActionDialog,
  type DeleteActionDialogOptions,
  DeleteObjectiveDialog,
  type DeleteObjectiveDialogOptions,
  EditActionDialog,
  type EditActionDialogOptions,
  EditObjectiveDialog,
  type EditObjectiveDialogOptions,
} from '../../../Dialogs';
import { SearchPlaybooks } from '../../../SearchPlaybooks';
import { DraggablePlaybook } from './DraggablePlaybook';

export const Playbooks = () => (
  <SearchProvider>
    <PlaybooksList />
  </SearchProvider>
);

const PlaybooksList = () => {
  const { searchTerm, searchPlaybooks } = useSearchContext();

  // action dialog state controls
  const [createActionOptions, setCreateActionOptions] = useState<CreateActionDialogOptions>({
    open: false,
    objectiveId: undefined,
    rank: undefined,
  });
  const [editActionOptions, setEditActionOptions] = useState<EditActionDialogOptions>({
    open: false,
    action: undefined,
  });
  const [deleteActionOptions, setDeleteActionOptions] = useState<DeleteActionDialogOptions>({
    open: false,
    action: undefined,
  });

  // objective dialog state controls
  const [createObjectiveDialogOpen, setCreateObjectiveDialogOpen] = useState(false);
  const [editObjectiveOptions, setEditObjectiveOptions] = useState<EditObjectiveDialogOptions>({
    open: false,
    objective: undefined,
  });
  const [deleteObjectiveOptions, setDeleteObjectiveOptions] =
    useState<DeleteObjectiveDialogOptions>({ open: false, objective: undefined });

  // action menu
  const [actionMenuEl, setActionMenuEl] = useState<HTMLElement>();
  const actionMenuOpen = Boolean(actionMenuEl);

  const objectivesResult = useObjectives();
  const searchedObjectives = useMemo(() => {
    if (objectivesResult.state === ResultType.Value) {
      return searchPlaybooks(objectivesResult.value);
    }
    return [];
  }, [objectivesResult.state, objectivesResult.value, searchPlaybooks]);

  const [reRankObjective, reRankObjectiveResult] = useMutation(UpdateObjectiveRankDocument);

  const [reRankObjectivesLoading, setReRankObjectivesLoading] = useState(false);

  const onDragPlaybookEnd = useCallback(
    async (result: DropResult): Promise<void> => {
      if (result.destination == null || objectivesResult.state !== ResultType.Value) {
        return;
      }

      setReRankObjectivesLoading(true);

      const reordered = Array.from(objectivesResult.value);
      const [removed] = reordered.splice(result.source.index, 1);
      reordered.splice(result.destination.index, 0, removed);

      await Promise.all(
        reordered.map((o, rank) => reRankObjective({ variables: { objectiveId: o.id, rank } })),
      );

      setReRankObjectivesLoading(false);

      return;
    },
    [objectivesResult, reRankObjective],
  );

  useEffect(() => {
    if (reRankObjectiveResult.called && !reRankObjectivesLoading) {
      reRankObjectiveResult.reset();
    }
  }, [reRankObjectiveResult, reRankObjectivesLoading]);

  return (
    <>
      <Container sx={{ my: 4, p: 0, mx: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchPlaybooks
            data-uid={manage$.playbooks.playbookSearch}
            inputProps={{ 'data-uid': manage$.playbooks.playbookSearchInput }}
          />
          <Box sx={{ my: 'auto' }}>
            <Tooltip
              open={
                objectivesResult.state === ResultType.Value && objectivesResult.value.length < 1
              }
              title={<Typography variant="caption">Create your first Playbook</Typography>}
            >
              <Button
                variant="contained"
                color="primary"
                data-uid={manage$.playbooks.addPlaybookBtn}
                startIcon={<AddIcon />}
                onClick={() => setCreateObjectiveDialogOpen(true)}
              >
                Add Playbook
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ mt: 4, mb: 6 }} data-uid={manage$.playbooks.playbooksContent}>
          {objectivesResult.state === ResultType.Loading && <LinearProgress />}
          {objectivesResult.state === ResultType.Error && (
            <Card sx={{ mt: 4 }}>
              <Alert severity="error">Failed to retrieve client Playbooks</Alert>
            </Card>
          )}
          {reRankObjectivesLoading && <LinearProgress />}
          <DragDropContext onDragEnd={onDragPlaybookEnd}>
            {objectivesResult.state === ResultType.Value && (
              <Droppable droppableId="droppable-playbook">
                {(provided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {searchedObjectives.length === 0 ? (
                      <Alert data-uid={manage$.playbooks.noResults} severity="info">
                        {searchTerm.length > 0
                          ? `No playbooks found for: ${searchTerm}`
                          : 'No Playbooks available; add one to start!'}
                      </Alert>
                    ) : null}
                    {searchedObjectives.map((objective, index) => (
                      <DraggablePlaybook
                        key={objective.id}
                        data-uid={manage$.playbooks.playbookTitle(objective.title)}
                        objective={objective}
                        draggableIndex={index}
                        reorderDisabled={searchTerm.length > 0}
                        onCreateAction={() =>
                          setCreateActionOptions({
                            open: true,
                            objectiveId: objective.id,
                            // initialize action ranks as one higher than the max action rank
                            // this should mean that we always put new actions at the end
                            // users can reorder actions after by using the reorder controls in <PlaybookActions />
                            rank: objective.maxActionRank + 1,
                          })
                        }
                        onEditPlaybook={() => setEditObjectiveOptions({ open: true, objective })}
                        onDeletePlaybook={() =>
                          setDeleteObjectiveOptions({ open: true, objective })
                        }
                        onOpenActionMenu={(target, action) => {
                          // keep the previous
                          setDeleteActionOptions({
                            ...deleteActionOptions,
                            action,
                          });
                          setEditActionOptions({
                            ...editActionOptions,
                            action,
                          });
                          setActionMenuEl(target);
                        }}
                      />
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </Box>
      </Container>
      <Menu
        anchorEl={actionMenuEl}
        open={actionMenuOpen}
        onClose={() => setActionMenuEl(undefined)}
      >
        <MenuItem
          onClick={() => {
            const { action } = editActionOptions;
            if (action != null) {
              setEditActionOptions({ open: true, action });
              setActionMenuEl(undefined);
            }
          }}
        >
          Edit action
        </MenuItem>
        <MenuItem
          onClick={() => {
            const { action } = deleteActionOptions;
            if (action != null) {
              setDeleteActionOptions({ open: true, action });
              setActionMenuEl(undefined);
            }
          }}
        >
          Delete action
        </MenuItem>
      </Menu>

      <CreateObjectiveDialog
        open={createObjectiveDialogOpen}
        rank={
          (objectivesResult.state === ResultType.Value
            ? Math.min(...objectivesResult.value.map((o) => o.rank), 0)
            : 0) - 1
        }
        onClose={() => setCreateObjectiveDialogOpen(false)}
      />
      <EditObjectiveDialog
        onClose={() => setEditObjectiveOptions({ ...editObjectiveOptions, open: false })}
        onExited={() => {
          setEditObjectiveOptions({ open: false, objective: undefined });
        }}
        {...editObjectiveOptions}
      />
      <DeleteObjectiveDialog
        onClose={() => setDeleteObjectiveOptions({ ...deleteObjectiveOptions, open: false })}
        onExited={() => setDeleteObjectiveOptions({ open: false, objective: undefined })}
        {...deleteObjectiveOptions}
      />

      <CreateActionDialog
        onClose={() =>
          setCreateActionOptions({ open: false, objectiveId: undefined, rank: undefined })
        }
        {...createActionOptions}
      />
      <EditActionDialog
        onClose={() => setEditActionOptions({ ...editActionOptions, open: false })}
        onExited={() => setEditActionOptions({ open: false, action: undefined })}
        {...editActionOptions}
      />
      <DeleteActionDialog
        onClose={() => setDeleteActionOptions({ ...deleteActionOptions, open: false })}
        onExited={() => setDeleteActionOptions({ open: false, action: undefined })}
        {...deleteActionOptions}
      />
    </>
  );
};
