import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import type {
  GridColDef,
  GridGroupingValueGetterParams,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { add, format, isAfter, isBefore, isSameMonth, startOfToday, sub } from 'date-fns';
import { sumBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CURRENCY_FORMATTER } from '../../../constants';
import { useUserSettingsContext } from '../../../contexts/userSettingsContext';
import { useCommonGridHelpers } from '../../../hooks/useCommonGridHelpers';
import type { PartialUser } from '../../../hooks/user';
import type { GrowthRecommendation } from '../../../models/recommendation';
import { GROWTH_TYPE_LABELS } from '../../../models/recommendation';
import { ResultType } from '../../../models/result';
import type { UUID } from '../../../models/uuid';
import { recommendations$ } from '../../../selectors';
import { getMUIMultiGroupedColumnField, NUMBER_FORMATTER } from '../../../utils';
import {
  CurrencyCell,
  CustomerCell,
  CustomGrid,
  DateCell,
  MetricCell,
  PercentCell,
  StringCell,
} from '../../Tables/CustomGrid';
import { LinkedPipelineCell } from '../../Tables/CustomGrid/Account/LinkedPipelineCell';

interface GrowthRecommendationsSummary {
  numberOfUsers: number;
  numberOfRecommendations: number;
  totalRecommendationsRun: number;
  totalPriorPipeline: number;
  totalNetNewPipeline: number;
}

interface GrowthRecommendationRow {
  id: UUID;
  createdOn: Date | null;
  playbookName: string;
  customerId: UUID;
  customerName: string;
  sprintStarted: boolean;
  owner: PartialUser | null;
  growthScore: number;
  originalPredictedOverage: number | null;
  productNames: string;
  originalPipeline: number | null;
  netNewPipeline: number | null;
  type: string;
}

const MINIMUM_COLUMN_WIDTH = 150;

const renderCustomerCell = (params: GridRenderCellParams<GrowthRecommendationRow, string>) => (
  <CustomerCell {...params} row={{ id: params.row.customerId }} showProductDetailsLink />
);

const renderDateCell = (params: GridRenderCellParams<GrowthRecommendationRow, Date>) => (
  <DateCell {...params} />
);

const renderStringCell = (params: GridRenderCellParams<GrowthRecommendationRow, string>) => (
  <StringCell {...params} />
);

const renderCurrencyCell = (params: GridRenderCellParams<GrowthRecommendationRow, number>) => (
  <CurrencyCell {...params} />
);

const renderPercentCell = (params: GridRenderCellParams<GrowthRecommendationRow, number>) => (
  <PercentCell {...params} />
);

const renderMetricCell = (params: GridRenderCellParams<GrowthRecommendationRow, string>) => (
  <MetricCell {...params} />
);

const renderPipelineCell = (params: GridRenderCellParams<GrowthRecommendationRow, number>) => (
  <LinkedPipelineCell {...params} customerId={params.row.customerId} />
);

/**
 * Async function to load mocked growth recommendations + load extra bundles.
 * @param cb dispatch function to update growth recommendations
 */
async function loadMockedGrowthRecommendations(
  cb: (recommendations: GrowthRecommendation[]) => void,
): Promise<void> {
  const { someGrowthRecommendations } = await import('../../../graphql/mocks');
  const mockedGrowthRecommendations = someGrowthRecommendations({}, 25);

  cb(mockedGrowthRecommendations);
}

interface UseAllGrowthRecommendationsOptions {
  showDemoData: boolean;
}

/**
 * Hook to get all growth recommendations.
 * @param root0 options
 * @param root0.showDemoData flag to show mocked growth recommendations
 * @returns list of growth recommendations (or empty)
 */
function useAllGrowthRecommendations({
  showDemoData,
}: UseAllGrowthRecommendationsOptions): [GrowthRecommendation[], boolean] {
  const result = { state: ResultType.NoValue, value: [] };
  const loading = useMemo(() => result.state === ResultType.Loading, [result.state]);

  const [allGrowthRecommendations, setAllGrowthRecommendations] = useState<GrowthRecommendation[]>(
    [],
  );
  useEffect(() => {
    // NOTE: only load mocked recommendations once to avoid glitchy states
    if (allGrowthRecommendations.length < 1) {
      if (result.state === ResultType.Value) {
        setAllGrowthRecommendations(result.value);
      }
      if (showDemoData) {
        loadMockedGrowthRecommendations(setAllGrowthRecommendations);
      }
    }
  }, [allGrowthRecommendations.length, result.state, result.value, showDemoData]);

  return [allGrowthRecommendations, loading];
}

export const GrowthRecommendations = () => {
  const {
    admin: { showDemoData },
  } = useUserSettingsContext();
  const [growthRecommendations, loading] = useAllGrowthRecommendations({ showDemoData });
  const { columnVisibilityModel, ...gridData } = useCommonGridHelpers({
    hideableColMap: {},
    localeEntity: 'Recommendation',
  });

  // TODO: backfill with browser state/local storage
  const [reportDate, setReportDate] = useState(startOfToday());

  const recommendations = useMemo(() => {
    return growthRecommendations.filter(
      (r) => r.createdOn == null || isSameMonth(r.createdOn, reportDate),
    );
  }, [growthRecommendations, reportDate]);

  const earliestRecommendationDate = useMemo(
    () =>
      growthRecommendations.reduce((earliest: Date, r) => {
        if (r.createdOn != null) {
          const createdOn = r.createdOn;
          if (isBefore(createdOn, earliest)) {
            return createdOn;
          }
        }
        return earliest;
      }, startOfToday()) ?? new Date(2024, 0),
    [growthRecommendations],
  );

  const summary = useMemo((): GrowthRecommendationsSummary => {
    const users = new Set(
      recommendations.reduce((owners: UUID[], r) => {
        if (r.owner != null && r.owner.id != null) {
          return [...owners, r.owner.id];
        }
        return owners;
      }, []),
    );
    return {
      numberOfUsers: users.size,
      numberOfRecommendations: recommendations.length,
      totalRecommendationsRun: recommendations.filter((r) => r.sprint != null).length,
      totalPriorPipeline: sumBy(recommendations, 'metrics.originalPipeline'),
      totalNetNewPipeline: sumBy(recommendations, 'metrics.netNewPipeline'),
    };
  }, [recommendations]);

  const columns = useMemo(
    (): GridColDef<GrowthRecommendationRow>[] =>
      [
        {
          field: 'owner',
          type: 'string',
          headerName: 'User',
          valueGetter: (
            params: GridValueGetterParams<GrowthRecommendationRow, PartialUser | null>,
          ) => params.value?.name,
          groupingValueGetter: (
            params: GridGroupingValueGetterParams<GrowthRecommendationRow, PartialUser | null>,
          ) => params.value?.name ?? 'No Owner',
          renderCell: renderStringCell,
          minWidth: 175,
          flex: 175 / MINIMUM_COLUMN_WIDTH,
          aggregable: false,
          groupable: true,
        },
        {
          field: 'playbookName',
          type: 'string',
          headerName: 'Playbook Name',
          minWidth: 250,
          flex: 250 / MINIMUM_COLUMN_WIDTH,
          renderCell: renderStringCell,
          aggregable: false,
          groupable: true,
        },
        {
          field: 'type',
          type: 'string',
          headerName: 'Growth Type',
          minWidth: 150,
          flex: 150 / MINIMUM_COLUMN_WIDTH,
          renderCell: renderStringCell,
          aggregable: false,
          groupable: true,
        },
        {
          field: 'createdOn',
          headerName: 'Recommended On',
          minWidth: 190,
          flex: 190 / MINIMUM_COLUMN_WIDTH,
          type: 'dateTime',
          renderCell: renderDateCell,
          groupable: false,
          aggregable: false,
        },
        {
          field: 'customerName',
          type: 'string',
          headerName: 'Customer Name',
          minWidth: 175,
          flex: 175 / MINIMUM_COLUMN_WIDTH,
          renderCell: renderCustomerCell,
          aggregable: false,
          groupable: false,
        },
        {
          field: 'sprintStarted',
          type: 'boolean',
          headerName: 'Sprint Started?',
          minWidth: 170,
          flex: 170 / MINIMUM_COLUMN_WIDTH,
          aggregable: false,
          groupable: false,
        },
        {
          field: 'growthScore',
          headerName: 'Growth Score',
          minWidth: 160,
          flex: 160 / MINIMUM_COLUMN_WIDTH,
          type: 'number',
          renderCell: renderMetricCell,
          aggregable: true,
          groupable: false,
        },
        {
          field: 'originalPredictedOverage',
          headerName: 'Predicted Overage',
          minWidth: 190,
          flex: 190 / MINIMUM_COLUMN_WIDTH,
          type: 'number',
          renderCell: renderPercentCell,
          aggregable: true,
          groupable: false,
        },
        {
          field: 'productNames',
          type: 'string',
          headerName: 'Product(s)',
          minWidth: 150,
          flex: 150 / MINIMUM_COLUMN_WIDTH,
          renderCell: renderStringCell,
          aggregable: false,
          groupable: false,
        },
        {
          field: 'originalPipeline',
          type: 'number',
          headerName: 'Prior Pipeline',
          minWidth: 160,
          flex: 160 / MINIMUM_COLUMN_WIDTH,
          renderCell: renderCurrencyCell,
          aggregable: true,
          groupable: false,
        },
      ].concat(
        showDemoData
          ? {
              field: 'netNewPipeline',
              type: 'number',
              headerName: 'Net New Pipeline',
              minWidth: 180,
              flex: 180 / MINIMUM_COLUMN_WIDTH,
              renderCell: renderPipelineCell,
              aggregable: true,
              groupable: false,
            }
          : [],
      ),
    [showDemoData],
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef: gridData.apiRef,
    initialState: {
      pinnedColumns: {
        left: [getMUIMultiGroupedColumnField('owner')],
      },
      rowGrouping: { model: ['owner'] },
      columns: { columnVisibilityModel },
      sorting: {
        sortModel: [
          { field: getMUIMultiGroupedColumnField('owner'), sort: 'asc' },
          { field: 'createdOn', sort: 'desc' },
        ],
      },
    },
  });

  const rows = useMemo(
    () =>
      recommendations.map(
        (r): GrowthRecommendationRow => ({
          id: r.id,
          createdOn: r.createdOn,
          playbookName: r.playbook.title,
          customerId: r.metrics.accountId,
          customerName: r.metrics.accountName,
          sprintStarted: r.sprint != null,
          owner: r.owner,
          growthScore: r.metrics.score * 100,
          netNewPipeline: r.metrics.netNewPipeline,
          originalPipeline: r.metrics.originalPipeline,
          originalPredictedOverage:
            r.metrics.originalPredictedOverage != null
              ? r.metrics.originalPredictedOverage * 100
              : null,
          productNames: r.metrics.products.map((p) => p.name).join(', '),
          type: GROWTH_TYPE_LABELS[r.type],
        }),
      ),
    [recommendations],
  );

  return (
    <Box
      data-uid={recommendations$.growth.page.growth}
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Container sx={{ mx: 0, flexGrow: 1 }} maxWidth={false}>
        <Stack spacing={2} flexGrow={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2" sx={{ pb: 3, pt: 4 }}>
              Monthly Growth Report
            </Typography>
            <Box sx={{ my: 'auto' }}>
              <Button
                component={RouterLink}
                data-uid={recommendations$.growth.page.viewRecsBtn}
                to="/work/my/recommendations"
                variant="contained"
                color="primary"
                disableElevation
              >
                View My Recommendations
              </Button>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Card elevation={1} sx={{ flexShrink: 0 }}>
              <CardHeader
                sx={{ px: 1, py: 1.4 }}
                title={
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      data-uid={recommendations$.growth.summary.previousMonthBtn}
                      size="small"
                      onClick={() => setReportDate(sub(reportDate, { months: 1 }))}
                      // don't allow the user to traverse before the earliest known recommendations
                      disabled={isSameMonth(reportDate, earliestRecommendationDate)}
                    >
                      <NavigateBefore />
                    </IconButton>
                    <Box
                      sx={{
                        flexGrow: 1,
                        // fitting for longer text e.g. "december xxxx"
                        minWidth: (theme) => theme.spacing(22),
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {format(reportDate, 'MMMM yyyy')}
                    </Box>
                    <IconButton
                      data-uid={recommendations$.growth.summary.nextMonthBtn}
                      size="small"
                      disabled={isAfter(add(reportDate, { months: 1 }), startOfToday())}
                      onClick={() => setReportDate(add(reportDate, { months: 1 }))}
                    >
                      <NavigateNext />
                    </IconButton>
                  </Stack>
                }
              />
              <Divider />
              <CardContent sx={{ p: 4 }}>
                <Stack spacing={2}>
                  <Box>
                    <Typography
                      variant="h4"
                      data-uid={recommendations$.growth.summary.numberOfUsers}
                    >
                      {NUMBER_FORMATTER.format(summary.numberOfUsers)}
                    </Typography>
                    <Typography variant="caption">Total Users</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      data-uid={recommendations$.growth.summary.numberOfRecommendations}
                    >
                      {NUMBER_FORMATTER.format(summary.numberOfRecommendations)}
                    </Typography>
                    <Typography variant="caption"># Recommendations</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      data-uid={recommendations$.growth.summary.recommendationsRun}
                    >
                      {NUMBER_FORMATTER.format(summary.totalRecommendationsRun)}
                    </Typography>
                    <Typography variant="caption">Total Run</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      data-uid={recommendations$.growth.summary.totalPriorPipeline}
                    >
                      {CURRENCY_FORMATTER.format(summary.totalPriorPipeline)}
                    </Typography>
                    <Typography variant="caption">Total Prior Pipeline</Typography>
                  </Box>
                  {showDemoData ? (
                    <Box>
                      <Typography
                        variant="h4"
                        data-uid={recommendations$.growth.summary.totalNetNewPipeline}
                      >
                        {CURRENCY_FORMATTER.format(summary.totalNetNewPipeline)}
                      </Typography>
                      <Typography variant="caption">Total Net New Pipeline</Typography>
                    </Box>
                  ) : null}
                </Stack>
              </CardContent>
            </Card>
            <Card elevation={1} sx={{ mt: 3, mx: 3, flexGrow: 1 }}>
              <CustomGrid
                {...gridData}
                loading={loading}
                sx={{ borderColor: 'transparent' }}
                initialState={initialState}
                defaultGroupingExpansionDepth={1}
                groupingColDef={{ ...gridData.groupingColDef, flex: 1, minWidth: 175 }}
                columns={columns}
                rows={rows}
              />
            </Card>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
