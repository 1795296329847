import CircleIcon from '@mui/icons-material/Circle';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import type { PaletteColor } from '@mui/material/styles';
import React, { useMemo } from 'react';

import type { MetricDatum } from '../../../../../../graphql/generated';
import {
  ProductMetricProgressStatus,
  ProductMetricTemporalStatus,
  ProductMetricUnit,
} from '../../../../../../graphql/generated';
import type { MetricType } from '../../../../../../models/productMetrics';
import { ProductMetricMap } from '../../../../../../models/productMetrics';
import { NUMBER_FORMATTER, PERCENT_NUMBER_FORMATTER } from '../../../../../../utils';
import { WeeklyDeltaDisplay } from './WeeklyDeltaDisplay';

export interface MetricSummaryProps {
  metric: MetricType;
  data: MetricDatum[];
  isSelected?: boolean;
  onClick: (metric: MetricType) => void;
}

export const MetricSummary = ({ metric, data, isSelected, onClick }: MetricSummaryProps) => {
  const theme = useTheme();

  const currentWeekMetricIndex: number = useMemo(
    () =>
      data.findIndex(
        (metric) =>
          metric.progressStatus === ProductMetricProgressStatus.Complete &&
          metric.temporalStatus === ProductMetricTemporalStatus.Current,
      ),
    [data],
  );
  const currentWeekMetricDatum = useMemo(
    (): MetricDatum | undefined => data[currentWeekMetricIndex],
    [currentWeekMetricIndex, data],
  );
  const priorWeekMetricDatum = useMemo((): MetricDatum | undefined => {
    const lastWeekMetricIndex = Math.max(currentWeekMetricIndex - 1, 0);
    return data[lastWeekMetricIndex];
  }, [currentWeekMetricIndex, data]);

  const weeklyDeltaPercentage: number = useMemo(() => {
    if (currentWeekMetricDatum?.metricValue && priorWeekMetricDatum?.metricValue) {
      return (
        (currentWeekMetricDatum.metricValue - priorWeekMetricDatum.metricValue) /
        Math.abs(priorWeekMetricDatum.metricValue)
      );
    }

    return 0;
  }, [currentWeekMetricDatum?.metricValue, priorWeekMetricDatum?.metricValue]);

  const currentWeekMetricDisplayValue: string = useMemo(() => {
    if (currentWeekMetricDatum?.metricValue) {
      switch (currentWeekMetricDatum.metricUnit) {
        case ProductMetricUnit.Percentage:
          return PERCENT_NUMBER_FORMATTER.format(currentWeekMetricDatum.metricValue);
        default:
          return NUMBER_FORMATTER.format(currentWeekMetricDatum.metricValue);
      }
    }
    return '—';
  }, [currentWeekMetricDatum?.metricUnit, currentWeekMetricDatum?.metricValue]);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        p: 2,
        mt: 2,
        width: 200,
        border: (theme) =>
          `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.grey}`,
      }}
      onClick={() => onClick(metric)}
    >
      <Stack direction="row" spacing={1}>
        <CircleIcon
          sx={{
            color: (theme.palette[ProductMetricMap[metric].color] as PaletteColor).main,
            width: '0.4em',
          }}
        />
        <Typography variant="body1">{ProductMetricMap[metric].title}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="h6" sx={{ fontWeight: 400 }}>
          {currentWeekMetricDisplayValue}
        </Typography>
        <WeeklyDeltaDisplay weeklyDeltaPercentage={weeklyDeltaPercentage} />
      </Stack>
    </Paper>
  );
};
