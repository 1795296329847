import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Add, Remove, Search, ZoomIn, ZoomOut } from '@mui/icons-material';
import type { SxProps } from '@mui/material';
import { Box, Divider, Pagination, Paper, Stack } from '@mui/material';
import { darken } from '@mui/material/styles';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useCallback, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';

import { PRODUCTS_CONTENT_OUTER_OFFSET } from '../Routes/CustomerDetails/Products/constants';
import { LoadingPDF } from './LoadingPDF';
import { PDFError } from './PDFError';
import { PDFNoData } from './PDFNoData';

// TODO: resizing via https://github.com/wojtekmaj/react-pdf/issues/718#issuecomment-770247948

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};
interface PDFViewProps {
  filePath: string;
  assetName?: string;
  initialScale?: number;
  docContainerSx?: SxProps;
  pageContainerSx?: SxProps;
}
const SCALE_STEP_SIZE = 0.05;
const PDF_VIEWER_HEIGHT_CALC = `calc(100vh - ${PRODUCTS_CONTENT_OUTER_OFFSET})`;
export const PDFView = ({
  filePath,
  assetName,
  initialScale = 1.5,
  docContainerSx = {},
  pageContainerSx = {},
}: PDFViewProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(initialScale);
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
    setPageNumber(1);
  }, []);

  const changePage = useCallback((_: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  }, []);

  const incrementScale = useCallback(() => setScale((scale) => scale + SCALE_STEP_SIZE), []);
  const decrementScale = useCallback(() => setScale((scale) => scale - SCALE_STEP_SIZE), []);
  const resetScale = useCallback(
    () => (scale !== initialScale ? setScale(initialScale) : null),
    [initialScale, scale],
  );
  const ResetScaleIcon = useMemo(() => {
    if (scale === initialScale) {
      return Search;
    }
    return scale > initialScale ? ZoomOut : ZoomIn;
  }, [initialScale, scale]);

  return (
    <Box
      sx={{
        height: PDF_VIEWER_HEIGHT_CALC,
        '.react-pdf__Outline': {
          pl: 1,
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: (theme) => theme.typography.fontSize,
          '& a': {
            color: (theme) => theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          '& ul': {
            paddingInlineStart: 2.5,
          },
        },
        ...docContainerSx,
      }}
    >
      <Document
        file={filePath}
        loading={<LoadingPDF assetName={assetName} />}
        error={<PDFError assetName={assetName} />}
        noData={<PDFNoData assetName={assetName} />}
        onLoadSuccess={onDocumentLoadSuccess}
        onSourceError={console.error}
        options={options}
      >
        <Box sx={{ overflow: 'auto', height: PDF_VIEWER_HEIGHT_CALC, ...pageContainerSx }}>
          <Page pageNumber={pageNumber || 1} scale={scale} />
        </Box>
        <Paper
          sx={{
            position: 'relative',
            bottom: '3rem',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'max-content',
            zIndex: 10,
            p: 1,
            borderRadius: '1rem',
            backgroundColor: (theme) => darken(theme.palette.common.white, 0.05),
            opacity: 0.4,
            transition: (theme) => theme.transitions.create(['opacity']),
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Stack direction="row" spacing={1}>
            <Pagination
              size="small"
              count={numPages}
              page={pageNumber}
              siblingCount={0}
              onChange={changePage}
            />
            <Divider variant="middle" />
            <Stack spacing={2} direction="row" alignItems="center" sx={{ minWidth: '8rem' }}>
              <span title="zoom out">
                <Remove sx={{ cursor: 'pointer' }} onClick={decrementScale} />
              </span>
              <span title={scale !== initialScale ? 'reset zoom' : undefined}>
                <ResetScaleIcon
                  sx={{
                    cursor: scale === initialScale ? 'not-allowed' : 'pointer',
                    color: (theme) =>
                      scale === initialScale ? theme.palette.text.disabled : 'initial',
                  }}
                  onClick={resetScale}
                />
              </span>
              <span title="zoom in">
                <Add sx={{ cursor: 'pointer' }} onClick={incrementScale} />
              </span>
            </Stack>
          </Stack>
        </Paper>
      </Document>
    </Box>
  );
};
