import type { TickOptions, TooltipOptions } from 'chart.js';
import { groupBy, keys, mapValues, orderBy, values } from 'lodash';
import { useCallback, useMemo } from 'react';
import type { ChartProps } from 'react-chartjs-2';

import { RENEWAL_ANALYTICS_COLORS } from '../components/Charts/Analytics/utils';
import {
  barTooltipLabelHandler,
  barTooltipTitleHandler,
  barYTicksHandler,
} from '../components/Charts/BarChart/barChartUtils';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type { Renewal } from './renewal';
import { ExpiringSegments, RenewedSegments } from './renewal';

export type GroupingRenewalField = keyof Pick<
  Renewal,
  | 'onTimeStatus'
  | 'renewalOwnerName'
  | 'accountIndustry'
  | 'customHealthCategoryCurrent'
  | 'customHealthCategory3mo'
  | 'customHealthCategory6mo'
  | 'customHealthCategory9mo'
  | 'reefRenewalCurrent'
  | 'reefRenewal3mo'
  | 'reefRenewal6mo'
  | 'reefRenewal9mo'
>;
export const GROUPING_RENEWAL_FIELDS: Readonly<Array<GroupingRenewalField>> = [
  'onTimeStatus',
  'renewalOwnerName',
  'accountIndustry',
  'customHealthCategoryCurrent',
  'customHealthCategory3mo',
  'customHealthCategory6mo',
  'customHealthCategory9mo',
  'reefRenewalCurrent',
  'reefRenewal3mo',
  'reefRenewal6mo',
  'reefRenewal9mo',
] as const;
interface UseBreakdownByGroupChartConfigProps {
  renewals: Renewal[];
  groupingField: GroupingRenewalField;
}

export const useBreakdownByGroupChartConfig = ({
  renewals,
  groupingField,
}: UseBreakdownByGroupChartConfigProps): ChartProps<'bar'> => {
  const { currencyFormatter, formatCurrencyShort } = useUserSettingsContext();
  const label = useCallback<TooltipOptions['callbacks']['label']>(
    (barItem) => barTooltipLabelHandler(barItem, currencyFormatter),
    [currencyFormatter],
  );
  const callback = useCallback<TickOptions['callback']>(
    (value) => barYTicksHandler(value, formatCurrencyShort),
    [formatCurrencyShort],
  );
  const groupedRenewals = useMemo(
    () => groupBy(orderBy(renewals, `${groupingField}`), `${groupingField}`),
    [renewals, groupingField],
  );
  const groupedTotals = useMemo(
    () =>
      mapValues(groupedRenewals, (renewals) =>
        renewals.reduce(
          (acc, c) => ({
            ...acc,
            expiring: {
              ...acc.expiring,
              [ExpiringSegments.Churn]:
                c.analytics.expiring[ExpiringSegments.Churn] + acc.expiring[ExpiringSegments.Churn],
              [ExpiringSegments.Pending]:
                c.analytics.expiring[ExpiringSegments.Pending] +
                acc.expiring[ExpiringSegments.Pending],
              [ExpiringSegments.Renewed]:
                c.analytics.expiring[ExpiringSegments.Renewed] +
                acc.expiring[ExpiringSegments.Renewed],
            },
            renewed: {
              ...acc.renewed,
              [RenewedSegments.Renewed]:
                c.analytics.renewed[RenewedSegments.Renewed] + acc.renewed[RenewedSegments.Renewed],
              [RenewedSegments.Upsell]:
                c.analytics.renewed[RenewedSegments.Upsell] + acc.renewed[RenewedSegments.Upsell],
            },
          }),
          {
            expiring: {
              [ExpiringSegments.Churn]: 0,
              [ExpiringSegments.Pending]: 0,
              [ExpiringSegments.Renewed]: 0,
            },
            renewed: {
              [RenewedSegments.Renewed]: 0,
              [RenewedSegments.Upsell]: 0,
            },
          },
        ),
      ),
    [groupedRenewals],
  );

  const data: ChartProps<'bar'>['data'] = useMemo(
    () => ({
      labels: keys(groupedTotals),
      datasets: [
        {
          label: 'Renewed',
          data: values(groupedTotals).map((summary) => summary.expiring[ExpiringSegments.Renewed]),
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Renewed,
          stack: 'Expiring',
        },
        {
          label: 'Renewed',
          data: values(groupedTotals).map((summary) => summary.renewed[RenewedSegments.Renewed]),
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Renewed,
          stack: 'Renewing',
        },
        {
          label: 'Upsell',
          data: values(groupedTotals).map((summary) => summary.renewed[RenewedSegments.Upsell]),
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Upsell,
          stack: 'Renewing',
        },
        {
          label: 'Pending',
          data: values(groupedTotals).map((summary) => summary.expiring[ExpiringSegments.Pending]),
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Pending,
          stack: 'Expiring',
        },
        {
          label: 'Churn',
          data: values(groupedTotals).map((summary) => summary.expiring[ExpiringSegments.Churn]),
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Churn,
          stack: 'Expiring',
        },
      ],
    }),
    [groupedTotals],
  );

  // disable legend label click behavior
  const legendOnClickHandler = useCallback(() => null, []);

  return {
    type: 'bar',
    data: data,
    options: {
      layout: {
        padding: 16,
      },
      plugins: {
        legend: {
          display: false,
          position: 'right',
          labels: { pointStyle: 'circle', usePointStyle: true },
          onClick: legendOnClickHandler,
        },
        tooltip: {
          callbacks: {
            title: barTooltipTitleHandler,
            label,
          },
        },
      },
      responsive: true,
      scales: {
        x: {
          display: (data.labels?.length ?? 0) < 15,
          stacked: true,
        },
        y: {
          display: false,
          stacked: true,
          ticks: {
            callback,
          },
        },
      },
      maintainAspectRatio: false,
    },
  };
};
