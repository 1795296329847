import type { SxProps } from '@mui/material';
import { Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import React, { useCallback, useMemo } from 'react';
import { matchPath, Navigate, Outlet, useLocation, useNavigate } from 'react-router';

import { userWork$ } from '../../../selectors';
import { UW_TAB_VALUES } from './types';

export const MyWorkIndex = () => <Navigate to="/work/my/sprints" replace />;

export const UserWork = () => {
  const nav = useNavigate();
  const { pathname } = useLocation();

  const isMySprintsActive = matchPath('/work/my/sprints', pathname);
  const isMyActionsActive = matchPath('/work/my/actions', pathname);
  const isMyRecommendationsActive =
    matchPath('/work/my/recommendations', pathname) ||
    matchPath('/work/my/recommendations/:recId/dismiss', pathname);

  const tabsValue = useMemo(() => {
    if (isMySprintsActive) {
      return UW_TAB_VALUES.Sprints;
    }
    if (isMyActionsActive) {
      return UW_TAB_VALUES.Actions;
    }
    if (isMyRecommendationsActive) {
      return UW_TAB_VALUES.Recommendations;
    }
    return UW_TAB_VALUES.Sprints;
  }, [isMyActionsActive, isMyRecommendationsActive, isMySprintsActive]);

  const onClickNav = useCallback((path: string) => nav(path), [nav]);
  const onTabsChange = useCallback(
    (_: React.SyntheticEvent, value: typeof tabsValue) => onClickNav(`/work/my/${value}`),
    [onClickNav],
  );

  const tabSx = useMemo<SxProps<Theme>>(
    () => ({
      minWidth: '16.875rem',
      '&:hover': {
        color: (theme) => theme.palette.primary.main,
        backgroundColor: (theme) => theme.palette.primary.s12p,
      },
      transition: (theme) => theme.transitions.create(['color', 'backgroundColor']),
    }),
    [],
  );

  return (
    <Box
      data-uid={userWork$.page.root}
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Stack spacing={3} direction="row" height="calc(100vh - 2.75rem)" margin={2.75}>
        <Stack spacing={3} width="100%">
          <Typography data-uid={userWork$.page.header} variant="h3">
            My Work
          </Typography>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Tabs value={tabsValue} onChange={onTabsChange}>
                <Tab
                  data-uid={userWork$.sprints.tab}
                  value={UW_TAB_VALUES.Sprints}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      My Sprints
                    </Typography>
                  }
                  sx={tabSx}
                />
                <Tab
                  data-uid={userWork$.actions.tab}
                  value={UW_TAB_VALUES.Actions}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      My Actions
                    </Typography>
                  }
                  sx={tabSx}
                />
                <Tab
                  data-uid={userWork$.recommendations.tab}
                  value={UW_TAB_VALUES.Recommendations}
                  label={
                    <Typography variant="overline" sx={{ lineHeight: 1 }}>
                      My Recommendations
                    </Typography>
                  }
                  sx={tabSx}
                />
              </Tabs>
            </Stack>
            <Divider />
          </Box>
          <Outlet />
        </Stack>
      </Stack>
    </Box>
  );
};
