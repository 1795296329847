import type { ApolloError, LazyQueryExecFunction } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import { useMemo } from 'react';

import type { SessionInfo } from '../contexts/reefAuthContext';
import { AuthStatus, useReefAuthContext } from '../contexts/reefAuthContext';
import type { Exact, FindUserQuery, GetSelfQuery, UserFieldsFragment } from '../graphql/generated';
import { FindUserDocument, GetSelfDocument, UserStatus } from '../graphql/generated';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import type { UUID } from '../models/uuid';
import { asUUID } from '../models/uuid';
import type { RequiredNotNull } from '../types/util';

interface UserPDFs {
  recommendations: string;
}
export interface PartialUser {
  /**
   * The ID of the user.
   */
  id: UUID | null;
  /**
   * The user's name.
   */
  name: string | null;
  /**
   * The user's email.
   */
  email: string | null;
}
export interface User extends RequiredNotNull<PartialUser> {
  /**
   * The user's status.
   */
  status?: UserStatus | null;
  /**
   * Display text description of a user's status.
   */
  statusDescription: ReturnType<typeof getStatusDescription>;
  /**
   * Boolean, set when user is a valid action assignee.
   */
  isValidAssignee: boolean;
  /**
   * Object containing various user-scoped PDFs.
   */
  pdfs: UserPDFs;
  /**
   * Default currency code to display for the user
   */
  defaultCurrencyCode: string | null;
  /**
   * The customer region a user prefers to see.
   */
  customerRegionSetting: string | null;
}

export const getStatusDescription = <U extends { status: UserStatus }>(user: U) => {
  switch (user.status) {
    case UserStatus.Invited:
      return 'Invite Sent';
    case UserStatus.NotActive:
      return 'Invite to Reef';
    case UserStatus.Deactivated:
      return 'Deactivated';
    case UserStatus.Active:
    default:
      return undefined;
  }
};
/**
 * Transforms GraphQL User object into a ClientUser shape.
 * @param user GraphQL User Objet
 * @returns ClientUser shape
 */
export const transform = <U extends UserFieldsFragment>(user: U): User => ({
  id: user.id,
  name: user.name,
  email: user.email,
  status: user.status,
  isValidAssignee: user.status !== UserStatus.Deactivated && user.status !== UserStatus.Invited,
  statusDescription: getStatusDescription(user),
  pdfs: user.pdfs,
  defaultCurrencyCode: user.defaultCurrencyCode,
  customerRegionSetting: user.customerRegionSetting,
});

/**
 * Default, missing user value; used to ensure ClientUser useability from auth session methods.
 */
export const MISSING_USER: User = transform({
  id: asUUID('MISSING_USER_ID'),
  email: '',
  name: '',
  status: UserStatus.NotActive,
  pdfs: {
    recommendations: '',
  },
  defaultCurrencyCode: 'USD',
  customerRegionSetting: null,
});

export const useSelf = (): Result<GetSelfQuery['self'], ApolloError> => {
  const [authCtx] = useReefAuthContext();

  // don't try to get current user data if the user isn't signed in
  // because the `getClient` query will fail anyway due to no
  // auth token being set
  const result = useResultFromQuery(
    useQuery(GetSelfDocument, { skip: authCtx.status !== AuthStatus.SignedIn }),
  );

  return useMemo(() => {
    // if the authCtx is loading, that should be bumped here - otherwise, this will
    // return a no-value result because `{skip: true}` forces no value to be emitted
    // from the query
    if (authCtx.status === AuthStatus.Loading) {
      return { state: ResultType.Loading, value: undefined };
    }

    if (result.state === ResultType.Value) {
      return {
        ...result,
        value: result.value.self,
      };
    }

    return result;
  }, [authCtx.status, result]);
};

/**
 * Gets the current logged in user.
 * @returns result for a current user query
 */
export const useCurrentUser = (): Result<User, ApolloError> => {
  const selfResult = useSelf();

  return useMemo(() => {
    if (selfResult.state === ResultType.Value) {
      const { id, name, email, status, pdfs, defaultCurrencyCode, customerRegionSetting } =
        selfResult.value;
      return {
        ...selfResult,
        // don't want to unintentionally include other fields here as well
        value: {
          ...transform({
            id,
            name,
            email,
            status,
            pdfs,
            defaultCurrencyCode,
            customerRegionSetting,
          }),
        },
      };
    }

    return selfResult;
  }, [selfResult]);
};

interface UseCurrentUserDetails extends Pick<SessionInfo, 'authProvider' | 'clientId'> {
  clientId: UUID; // override as UUID
  user: User;
}
/**
 * Extracts the current logged-in user & client information.
 * @returns current user details
 */
export const useCurrentUserDetails = (): UseCurrentUserDetails => {
  const [authCtx] = useReefAuthContext();

  const authProvider = useMemo(
    () => (authCtx.status === AuthStatus.SignedIn ? authCtx.session.authProvider : ''),
    [authCtx],
  );

  const clientId = useMemo(
    () => asUUID(authCtx.status === AuthStatus.SignedIn ? authCtx.session.clientId : ''),
    [authCtx],
  );

  const currentUserResult = useCurrentUser();
  const user = useMemo(
    () => (currentUserResult.state === ResultType.Value ? currentUserResult.value : MISSING_USER),
    [currentUserResult.state, currentUserResult.value],
  );

  return {
    authProvider,
    clientId,
    user,
  };
};

type UseFindUsersValue = [
  LazyQueryExecFunction<
    FindUserQuery,
    Exact<{
      searchTerm: string;
    }>
  >,
  Result<User[], ApolloError>,
];

/**
 * Constructs a Find User lazy query, and returns the Result-wrapped query result.
 * @returns Result object containing found ClientUsers or an Apollo error.
 */
export const useFindUsers = (): UseFindUsersValue => {
  const [findUsers, queryResult] = useLazyQuery(FindUserDocument);
  const usersResult = useResultFromQuery(queryResult);

  return useMemo(() => {
    if (usersResult.state === ResultType.Value) {
      return [
        findUsers,
        {
          ...usersResult,
          value: orderBy(usersResult.value.users.map(transform), 'name'),
        },
      ];
    }

    return [findUsers, usersResult];
  }, [findUsers, usersResult]);
};
