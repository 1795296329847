import { Link, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CURRENCY_FORMATTER } from '../../../../constants';
import type { UUID } from '../../../../models/uuid';
import { FieldWrapper } from './FieldWrapper';

interface LinkedPipelineCellProps extends GridRenderCellParams<{ id: UUID }, number> {
  customerId: UUID;
}
export const LinkedPipelineCell = ({
  field,
  value,
  rowNode,
  customerId,
}: LinkedPipelineCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? (
    <></>
  ) : (
    <Typography variant="body2">&ndash;</Typography>
  );
  // clicking on the link should not change grid row selection value
  const onLinkClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <Link
          component={RouterLink}
          to={`/customer-details/${customerId}/pipeline`}
          data-account-id={customerId}
          onClick={onLinkClick}
        >
          <Typography variant="body2" title={value?.toString()}>
            {CURRENCY_FORMATTER.format(value)}
          </Typography>
        </Link>
      ) : (
        missingValue
      )}
    </FieldWrapper>
  );
};
