import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import React from 'react';
import type { ChartProps } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  BarController,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
);
// TODO: https://app.shortcut.com/reef-2/story/6026
export const WaterfallChart = (chartProps: ChartProps) => <Chart {...chartProps} />;
