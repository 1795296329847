import type {
  DataGridPremiumProps,
  GridColTypeDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import {
  DateCell,
  MultiCurrencyCell,
  NumberCell,
  StringCell,
} from '../components/Tables/CustomGrid';
import type { ContractServicePeriodsGridRowModel } from '../components/Tables/CustomGrid/Contract/types';
import type { ContractServicePeriodDetails } from '../models/contract';
import { getMUIMultiGroupedColumnField } from '../utils';
import { useCommonGridHelpers } from './useCommonGridHelpers';

interface ContractGridColDef extends GridColTypeDef {
  field: keyof ContractServicePeriodsGridRowModel;
}

const renderDateCell = (params: GridRenderCellParams<ContractServicePeriodsGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderMultiCurrencyCell = (
  params: GridRenderCellParams<ContractServicePeriodsGridRowModel, number>,
) => <MultiCurrencyCell {...params} />;
const renderNumberCell = (
  params: GridRenderCellParams<ContractServicePeriodsGridRowModel, number>,
) => <NumberCell {...params} />;
const renderStringCell = (
  params: GridRenderCellParams<ContractServicePeriodsGridRowModel, string>,
) => <StringCell {...params} />;

export interface UseContractServicePeriodsGridProps {
  contractServicePeriods: ContractServicePeriodDetails[];
}
type ContractServicePeriodsGridProps = Pick<
  DataGridPremiumProps<ContractServicePeriodDetails>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'localeText'
  | 'density'
  | 'rowHeight'
  | 'rows'
  | 'groupingColDef'
>;
export const useContractServicePeriodsGrid = ({
  contractServicePeriods,
}: UseContractServicePeriodsGridProps): ContractServicePeriodsGridProps => {
  const hideableColMap = useMemo(
    () => ({
      isActive: true, // hidden by default
    }),
    [],
  );

  const {
    apiRef,
    columnVisibilityModel,
    groupingColDef: commonGroupingColDef,
    ...restProps
  } = useCommonGridHelpers({ hideableColMap, localeEntity: 'Service Period' });

  const columns = useMemo((): ContractGridColDef[] => {
    return [
      {
        field: 'name',
        headerName: 'Contract',
        hideable: false,
        minWidth: 200,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'serviceStartDate',
        headerName: 'Service Start Date',
        minWidth: 180,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: true,
        aggregable: true,
        availableAggregationFunctions: ['min', 'max'],
      },
      {
        field: 'serviceEndDate',
        headerName: 'Service End Date',
        minWidth: 180,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: true,
        aggregable: true,
        availableAggregationFunctions: ['min', 'max'],
      },
      {
        field: 'productName',
        headerName: 'Product',
        hideable: false,
        minWidth: 150,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'contractedUnits',
        headerName: 'Contracted Units',
        minWidth: 175,
        type: 'number',
        renderCell: renderNumberCell,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'contractedDollars',
        headerName: 'Contract Price',
        renderCell: renderMultiCurrencyCell,
        minWidth: 160,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'currentlyUsedUnits',
        headerName: 'Currently Used Units',
        minWidth: 200,
        type: 'number',
        renderCell: renderNumberCell,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'projectedUnits',
        headerName: 'Projected Units',
        minWidth: 170,
        type: 'number',
        renderCell: renderNumberCell,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'projectedUsageDollars',
        headerName: 'Proj. Usage $',
        renderCell: renderMultiCurrencyCell,
        minWidth: 160,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'projectedOverageDollars',
        headerName: 'Proj. Overage $',
        renderCell: renderMultiCurrencyCell,
        minWidth: 170,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'isActive',
        headerName: 'Is Active?',
        hideable: true,
        flex: 1,
        type: 'boolean',
        groupable: false,
        aggregable: false,
      },
    ];
  }, []);

  // NOTE: this feature runs into apiRef 💥 in unit tests, 😵‍💫
  const initialState: ContractServicePeriodsGridProps['initialState'] = useKeepGroupedColumnsHidden(
    {
      apiRef,
      initialState: {
        pinnedColumns: {
          left: [
            getMUIMultiGroupedColumnField('productName'),
            getMUIMultiGroupedColumnField('name'),
          ],
        },
        rowGrouping: {
          model: ['productName', 'name'],
        },
        columns: {
          columnVisibilityModel,
        },
        sorting: {
          sortModel: [],
        },
        aggregation: {
          model: {
            serviceStartDate: 'min',
            serviceEndDate: 'max',
            contractedDollars: 'sum',
            projectedOverageDollars: 'sum',
            projectedUsageDollars: 'sum',
          },
        },
      },
    },
  );

  const groupingColDef: ContractServicePeriodsGridProps['groupingColDef'] = useMemo(
    () => ({
      ...commonGroupingColDef,
      minWidth: 200,
    }),
    [commonGroupingColDef],
  );

  return {
    ...restProps,
    apiRef,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    rowHeight: 42,
    initialState,
    columns,
    rows: contractServicePeriods,
    groupingColDef,
  };
};
