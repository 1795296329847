import type {
  DataGridPremiumProps,
  GridColDef,
  GridInitialState,
  GridLocaleText,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import { noop, tap } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { CustomerCell, UpdateCell } from '../components/Tables/CustomGrid';
import type { ListOfCustomersProps } from '../components/Tables/ListOfCustomersTable';
import type { CustomersGridRowModel } from '../components/Tables/ListOfCustomersTable/types';
import type { Account } from '../models/account';
import type { UUID } from '../models/uuid';
import { useCommonGridHelpers } from './useCommonGridHelpers';
import { useCustomersGrid } from './useCustomersGrid';

type CustomerGridColDef = GridColDef<CustomersGridRowModel>;

const renderUpdateCell = (params: GridRenderCellParams<CustomersGridRowModel, UUID>) => (
  <UpdateCell {...params} />
);

export type UseDataQualityGridProps = Required<Pick<ListOfCustomersProps, 'accounts'>> & {
  fields: (keyof Account)[];
  error: boolean;
};
type DataQualityGridProps = Pick<
  DataGridPremiumProps<Account>,
  | 'autoHeight'
  | 'apiRef'
  | 'checkboxSelection'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'rowSelectionModel'
  | 'onRowSelectionModelChange'
  | 'hideFooter'
  | 'localeText'
  | 'sx'
>;
export const useDataQualityGrid = ({
  error,
  fields,
}: UseDataQualityGridProps): DataQualityGridProps => {
  const {
    apiRef: customerApiRef,
    columns: customerColumns,
    columnVisibilityModel: customersColumnVisibilityModel,
    sx,
  } = useCustomersGrid({
    accounts: [],
    customerMenuHidden: true,
    crmLinkHidden: false,
    disableSelection: true,
    rowSelectionModel: [],
    setRowSelectionModel: noop,
    showProductDetailsLink: true,
    onRemoveCustomer: noop,
  });

  const renderCustomerCell = useCallback(
    (params: GridRenderCellParams<CustomersGridRowModel, string>) => (
      <CustomerCell {...{ ...params, showProductDetailsLink: true }} />
    ),
    [],
  );

  const hideableColMap = useMemo(() => ({}), []);

  const {
    customNumberComparator: _unused, // restore if DataQualityGrid needs custom number sorting 🥲
    columnVisibilityModel: dataQualityColumnVisibilityModel,
    localeText: defaultLocaleText,
    ...commonProps
  } = useCommonGridHelpers({
    initialApiRef: customerApiRef,
    hideableColMap,
    localeEntity: 'Customer',
  });

  const columns: CustomerGridColDef[] = useMemo(() => {
    return [
      {
        field: 'crmLink',
        type: 'actions',
        headerName: 'Update Data',
        renderCell: renderUpdateCell,
        minWidth: 125,
        disableReorder: true,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'name',
        headerName: 'Customer',
        minWidth: 150,
        type: 'string',
        renderCell: renderCustomerCell,
        groupable: false,
        aggregable: false,
      },
      ...customerColumns.filter((cc) => !['__check__', 'name', 'id', 'crmLink'].includes(cc.field)),
    ].map((col) => ({
      ...col,
      cellClassName: (params) =>
        params.value == null && fields.includes(params.field as keyof Account)
          ? 'data-sync-health--negative'
          : '',
    }));
  }, [customerColumns, fields, renderCustomerCell]);

  const columnVisibilityModel = useMemo(
    () =>
      tap(
        { ...customersColumnVisibilityModel, ...dataQualityColumnVisibilityModel },
        (cvm) => delete cvm.id, // ignore filtered-out Customers action column
      ),
    [dataQualityColumnVisibilityModel, customersColumnVisibilityModel],
  );

  const initialState: GridInitialState = useMemo(
    () => ({
      pinnedColumns: { left: ['crmLink'] },
      sorting: {
        sortModel: [{ field: 'name', sort: 'asc' }],
      },
    }),
    [],
  );

  const localeText: Partial<GridLocaleText> = useMemo(
    () => ({
      ...defaultLocaleText,
      noRowsLabel: error
        ? '⚠️ Sorry, we cannot load your data right now.'
        : '🦀 Congrats, all your data is syncing well! 🦀',
      footerTotalRows: 'Total Customers with missing data:',
    }),
    [defaultLocaleText, error],
  );

  return {
    ...commonProps,
    columnVisibilityModel,
    autoHeight: false,
    checkboxSelection: false,
    initialState,
    columns,
    hideFooter: false,
    localeText,
    sx,
  };
};
