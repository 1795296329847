import { Alert, Card } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useCustomerProducts } from '../../../../hooks/account';
import { ResultType } from '../../../../models/result';
import type { UUID } from '../../../../models/uuid';
import { customerDetails$ } from '../../../../selectors/customerDetails';
import { FailedResult } from '../../../Alerts';
import { ProductDetailsTable } from '../../../Tables/ProductDetailsTable';
import { PRODUCTS_CONTENT_INNER_OFFSET } from './constants';

export const LegacyProducts = () => {
  const { accountId } = useParams();

  const productsResult = useCustomerProducts(accountId as UUID | undefined);

  const products = useMemo(
    () =>
      productsResult.state === ResultType.Value ? productsResult.value.productConsumption : [],
    [productsResult.state, productsResult.value],
  );

  if (accountId == null) {
    return <Alert severity="info">Uh oh! We weren&apos;t able to find that account.</Alert>;
  }

  if (productsResult.state === ResultType.Error || productsResult.state === ResultType.NoValue) {
    return <FailedResult resultType="account product details" error={productsResult.value} />;
  }

  return (
    <Card data-uid={customerDetails$.legacyProducts.section} elevation={0}>
      <ProductDetailsTable
        products={products}
        gridProps={{
          loading: productsResult.state === ResultType.Loading,
          GridWrapperBoxProps: {
            outer: {
              sx: {
                height: `calc(100vh - ${PRODUCTS_CONTENT_INNER_OFFSET})`,
                maxWidth: 'calc(100vw - 31.5rem)',
              },
            },
          },
        }}
      />
    </Card>
  );
};
