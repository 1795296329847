import type { SelectChangeEvent, SelectProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useCallback } from 'react';

import type { RenewalScoreField } from '../../../../hooks/useOutcomesByScoreChartConfig';
import { RENEWAL_SCORE_FIELDS } from '../../../../hooks/useOutcomesByScoreChartConfig';
import { analytics$ } from '../../../../selectors/analytics';

type ScoreFieldSelectProps = {
  value: RenewalScoreField;
  onChange: (newType: RenewalScoreField) => void;
  selectProps?: Partial<SelectProps<RenewalScoreField>>;
};
const SCORE_FIELD_LABELS: Record<RenewalScoreField, string> = {
  customHealthCategory3mo: 'Customer Healthscore (3mo)',
  customHealthCategory6mo: 'Customer Healthscore (6mo)',
  customHealthCategory9mo: 'Customer Healthscore (9mo)',
  customHealthCategoryCurrent: 'Customer Healthscore (Current)',
  reefRenewal3mo: 'Reef Renewal Score (3mo)',
  reefRenewal6mo: 'Reef Renewal Score (6mo)',
  reefRenewal9mo: 'Reef Renewal Score (9mo)',
  reefRenewalCurrent: 'Reef Renewal Score (Current)',
};
export const ScoreFieldSelect = ({ value, onChange, selectProps }: ScoreFieldSelectProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<RenewalScoreField>) =>
      event instanceof Event ? onChange(event.target.value) : undefined,
    [onChange],
  );
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="outcomes-score-field-select-label">Score</InputLabel>
      <Select
        data-uid={analytics$.scoreFieldSelect.select}
        labelId="outcomes-score-field-select-label"
        id="score-field-select-small"
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label="Score" />}
        {...selectProps}
      >
        {RENEWAL_SCORE_FIELDS.map((rf) => (
          <MenuItem data-uid={analytics$.scoreFieldSelect.item(rf)} key={rf} value={rf}>
            {SCORE_FIELD_LABELS[rf]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
