import React from 'react';

import type { Renewal } from '../../../hooks/renewal';
import type {
  OutcomesWeightingField,
  RenewalScoreField,
} from '../../../hooks/useOutcomesByScoreChartConfig';
import { useOutcomesByScoreChartConfig } from '../../../hooks/useOutcomesByScoreChartConfig';
import { SankeyChart } from './SankeyChart';

interface OutcomesByScoreChartProps {
  renewals: Renewal[];
  scoreField: RenewalScoreField;
  weightingField: OutcomesWeightingField;
}

export const OutcomesByScoreChart = ({
  renewals,
  scoreField,
  weightingField,
}: OutcomesByScoreChartProps) => {
  const sankeyChartProps = useOutcomesByScoreChartConfig({
    renewals,
    scoreField,
    weightingField,
  });
  return <SankeyChart {...sankeyChartProps} />;
};
