import { SyncAlt } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React from 'react';

import { tooltip$ } from '../../../../selectors';
import type { CustomersGridRowModel } from '../../ListOfCustomersTable/types';
import { FieldWrapper } from './FieldWrapper';

interface EditCrmButtonProps {
  href?: string;
}
const EditCrmButton = ({ href }: EditCrmButtonProps) => (
  <Button
    component="a"
    href={href}
    target="_blank"
    sx={{ borderRadius: 6, px: 2 }}
    endIcon={<SyncAlt />}
    disabled={href == null}
  >
    Edit CRM
  </Button>
);

type UpdateCellProps = GridRenderCellParams<CustomersGridRowModel, string>;
export const UpdateCell = ({ field, value, row: account }: UpdateCellProps) => (
  <FieldWrapper field={field}>
    {value != null ? (
      <EditCrmButton href={value} />
    ) : (
      <>
        <Tooltip
          data-uid={tooltip$.component}
          title={
            <>
              We were not able to construct a link to the account <strong>{account.name}</strong> in
              your CRM. Please contact your CRM administrator to modify details for this account
            </>
          }
          placement="bottom-end"
        >
          <span>
            <EditCrmButton />
          </span>
        </Tooltip>
      </>
    )}
  </FieldWrapper>
);
