import type {
  DataGridPremiumProps,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import { CurrencyCell, DateCell, NumberCell } from '../components/Tables/CustomGrid';
import type { ProductsGridRowModel } from '../components/Tables/CustomGrid/Product/types';
import type { ProductDetailsTableProps } from '../components/Tables/ProductDetailsTable';
import type { ProductConsumptionDetails } from '../models/account';
import { PERCENT_NUMBER_FORMATTER } from '../utils';
import { useCommonGridHelpers } from './useCommonGridHelpers';

type ProductGridColDef = GridColDef<ProductsGridRowModel>;
const MINIMUM_COLUMN_WIDTH = 100;

const renderCurrencyCell = (params: GridRenderCellParams<ProductsGridRowModel, number>) => (
  <CurrencyCell {...params} />
);
const renderDateCell = (params: GridRenderCellParams<ProductsGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderNumberCell = (params: GridRenderCellParams<ProductsGridRowModel, number>) => (
  <NumberCell {...params} />
);
const renderOverageCell = (params: GridRenderCellParams<ProductsGridRowModel, number>) => (
  <NumberCell
    {...params}
    elTitle={`${params.value?.toString()}\rOverage by ${PERCENT_NUMBER_FORMATTER.format((params.row.projectedUnitsTotal ?? 0) / (params.row.contractedUnits ?? 1))} of contracted units`}
  />
);
const renderUsageCell = (params: GridRenderCellParams<ProductsGridRowModel, number>) => (
  <NumberCell
    {...params}
    elTitle={`${params.value?.toString()}\rUsing ${PERCENT_NUMBER_FORMATTER.format((params.row.unitsUsedTotal ?? 0) / (params.row.contractedUnits ?? 1))} of contracted units`}
  />
);

type UseProductsGridProps = Pick<ProductDetailsTableProps, 'products'>;
type ProductsGridProps = Pick<
  DataGridPremiumProps<ProductConsumptionDetails>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'localeText'
  | 'rowHeight'
>;
export const useProductsGrid = (_props: UseProductsGridProps): ProductsGridProps => {
  const hideableColMap = useMemo(() => ({}), []);

  const { customNumberComparator, ...commonProps } = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'Product',
  });

  const columns: ProductGridColDef[] = useMemo(() => {
    return [
      {
        field: 'productName',
        headerName: 'Product Name',
        hideable: false,
        minWidth: 160,
        flex: 160 / MINIMUM_COLUMN_WIDTH,
        type: 'string',
        groupable: true,
        aggregable: false,
      },
      {
        field: 'hasProduct',
        headerName: 'Has Product',
        minWidth: 155,
        flex: 155 / MINIMUM_COLUMN_WIDTH,
        type: 'boolean',
        groupable: true,
        aggregable: false,
      },
      {
        field: 'serviceStartDate',
        headerName: 'Service Start Date',
        minWidth: 185,
        flex: 185 / MINIMUM_COLUMN_WIDTH,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: true,
        availableAggregationFunctions: ['max', 'min'],
      },
      {
        field: 'serviceEndDate',
        headerName: 'Service End Date',
        minWidth: 180,
        flex: 180 / MINIMUM_COLUMN_WIDTH,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: true,
        availableAggregationFunctions: ['max', 'min'],
      },
      {
        field: 'overageDate',
        headerName: 'Overage Date',
        minWidth: 155,
        flex: 155 / MINIMUM_COLUMN_WIDTH,
        type: 'dateTime',
        description: 'When Reef expects overage to start incurring for this product.',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: true,
        availableAggregationFunctions: ['max', 'min'],
      },
      {
        field: 'overageDollarsTotal',
        headerName: 'Projected Overage $',
        minWidth: 200,
        flex: 200 / MINIMUM_COLUMN_WIDTH,
        type: 'number',
        description: 'How many dollars in overage Reef expects by the end of the service period.',
        renderCell: renderCurrencyCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'projectedUnitsTotal',
        headerName: 'Projected Unit Overage',
        minWidth: 220,
        flex: 220 / MINIMUM_COLUMN_WIDTH,
        type: 'number',
        description:
          'How many units Reef expects the customer to use by the time the contract is up.',
        renderCell: renderOverageCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'contractedUnits',
        headerName: 'Contracted Units',
        minWidth: 185,
        flex: 185 / MINIMUM_COLUMN_WIDTH,
        type: 'number',
        description: 'How many units did the customer purchase.',
        renderCell: renderNumberCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'unitsUsedTotal',
        headerName: 'Total Used Units',
        minWidth: 175,
        flex: 175 / MINIMUM_COLUMN_WIDTH,
        type: 'number',
        description: 'How many units the customer has used.',
        renderCell: renderUsageCell,
        sortComparator: customNumberComparator,
        groupable: false,
        aggregable: true,
      },
      {
        field: 'usageUpdatedOn',
        headerName: 'Usage Updated On',
        minWidth: 190,
        flex: 190 / MINIMUM_COLUMN_WIDTH,
        type: 'dateTime',
        description: 'When the data was most recently updated in Salesforce.',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
    ];
  }, [customNumberComparator]);

  // TODO: aggregation + grouping by default
  const initialState: ProductsGridProps['initialState'] = useMemo(
    () => ({
      pinnedColumns: { left: ['productName'] },
      sorting: {
        sortModel: [{ field: 'productName', sort: 'asc' }],
      },
    }),
    [],
  );

  return {
    ...commonProps,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    initialState,
    columns,
    rowHeight: 42,
  };
};
