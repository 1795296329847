import { Box, Stack } from '@mui/material';
import React from 'react';

import type { Renewal } from '../../../../hooks/renewal';
import { useCleanRenewalData } from '../../../../hooks/renewal';
import { bottom } from '../../../../utils';
import { TotalRenewalStatusChart } from '../../BarChart/TotalRenewalStatusChart';
import type { HeroCharts } from '../types';

interface HeroChartProps {
  chart: HeroCharts;
  data: Renewal[];
}
export const HeroChart = ({ chart, data }: HeroChartProps) => {
  const cleanedData = useCleanRenewalData(data);
  switch (chart) {
    case 'total-renewals-bars':
      return (
        <Stack direction="row">
          <Box sx={{ width: '30%', height: '17.9rem' }}>
            <TotalRenewalStatusChart renewals={cleanedData} />
          </Box>
          <Stack
            id="custom-total-renewal-status-chart-legend"
            sx={{
              width: '70%',
              height: '17rem',
              pr: 2,
              // NOTE: prefer CSS styling of custom legend elements
              // to retain Chart.js integrations / hooks
              '.custom-total-renewal-status-chart-legend': {
                color: (theme) => theme.palette.text.primary,
                '&--divider': {
                  color: 'rgba(0, 0, 0, 0.12)',
                  borderWidth: 0,
                  borderStyle: 'solid',
                  borderBottomWidth: 'thin',
                  margin: '0.375rem 0px',
                },
                '&--header': {
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 0,
                  padding: 0,
                },
                '&--row': {
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                },
                '&--text': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                },
                '&--label': {
                  fontSize: '0.875rem',
                  fontFamily: 'Inter',
                  fontWeight: 300,
                  lineHeight: '1.25125rem',
                  margin: 0,
                  padding: 0,
                  '&--header': {
                    fontSize: '1rem',
                    fontFamily: 'Inter',
                    fontWeight: 300,
                    lineHeight: '1.5rem',
                    margin: 0,
                    padding: 0,
                  },
                },
                '&--total': {
                  fontSize: '0.875rem',
                  fontFamily: 'Roboto',
                  lineHeight: '1.25125rem',
                  margin: 0,
                  padding: 0,
                  '&--header': {
                    fontSize: '1rem',
                    fontFamily: 'Roboto',
                    lineHeight: '1.5rem',
                    margin: 0,
                    padding: 0,
                  },
                },
                '&--bullet': {
                  display: 'inline-block',
                  flexShrink: 0,
                  height: '0.6875rem',
                  marginRight: '0.37rem',
                  width: '0.6875rem',
                  borderRadius: '50%',
                },
                '&--list': {
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 0,
                  padding: 0,
                },
              },
            }}
            direction="column"
            justifyContent="space-between"
          ></Stack>
        </Stack>
      );
    default:
      bottom(chart);
  }
};
