import { useCallback, useMemo, useState } from 'react';

import type { SprintActivityNoteDialogProps } from '../components/Dialogs/SprintActivityNoteDialog';
import type { Activity } from '../models/sprint';

interface UseSprintActivityNoteDialog extends SprintActivityNoteDialogProps {
  editNote: ({ activity }: { activity: Activity }) => void;
}
export const useSprintActivityNoteDialog = (): UseSprintActivityNoteDialog => {
  const [editingActivity, setEditingActivity] = useState<Activity | null>(null);
  const editNote = useCallback(({ activity }: { activity: Activity }) => {
    setEditingActivity(activity);
  }, []);
  const closeNote = useCallback(() => setEditingActivity(null), []);

  return useMemo(
    () => ({
      open: editingActivity != null,
      activityId: editingActivity?.id,
      initialText: editingActivity?.note ?? undefined,
      editNote,
      onClose: closeNote,
    }),
    [closeNote, editNote, editingActivity],
  );
};
