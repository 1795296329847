import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Link, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { EMAIL_REGEX } from '../../../constants';
import type { AuthAction } from '../../../contexts/reefAuthContext';
import { AuthActionType, AuthStatus, useReefAuthContext } from '../../../contexts/reefAuthContext';
import googleLogo from '../../../images/googleLogo.svg';
import { AuthError } from '../../Auth/AuthError';

export const SignUp = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [loading, setLoading] = useState(false);

  const [authCtx, { authenticate }] = useReefAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx.status === AuthStatus.Confirming) {
      if (!authCtx.confirmed) {
        navigate('/sign-up/challenge');
      } else {
        // we shouldn't hit this state - but lets alert if we do
        console.error('got a confirmed user state that landed on login');
      }
    }
  }, [authCtx, navigate]);

  const loginOrSignUp = useCallback(
    async (options: AuthAction) => {
      setLoading(true);
      await authenticate(options);
      setLoading(false);
    },
    [authenticate],
  );

  return (
    <Stack spacing={2} maxWidth={(theme) => theme.spacing(48)}>
      <Button
        disabled={authCtx.status !== AuthStatus.SignedOut}
        variant="contained"
        color="inherit"
        onClick={() => loginOrSignUp({ type: AuthActionType.Federated })}
      >
        <Box sx={{ my: 1, display: 'flex' }}>
          <img src={googleLogo} />
          <Box sx={{ pl: 2 }}>Continue with Google</Box>
        </Box>
      </Button>
      <Divider textAlign="center" role="presentation">
        <Typography>OR</Typography>
      </Divider>
      <Stack spacing={1}>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          name="username"
          type="email"
          required
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Password"
          name="password"
          required
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Confirm password"
          name="confirmPassword"
          required
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <LoadingButton
          sx={{ my: 'auto' }}
          variant="contained"
          color="primary"
          size="large"
          disabled={
            username.length === 0 ||
            !EMAIL_REGEX.test(username) ||
            password.length === 0 ||
            confirmPassword.length === 0 ||
            password !== confirmPassword
          }
          onClick={() => loginOrSignUp({ type: AuthActionType.SignUp, username, password })}
          loading={loading}
        >
          Sign up
        </LoadingButton>
        <Typography variant="caption">
          Already have an account?{' '}
          <Link component={RouterLink} sx={{ cursor: 'pointer' }} to="/login">
            Sign in
          </Link>
        </Typography>
      </Stack>
      <AuthError username={username} />
    </Stack>
  );
};
