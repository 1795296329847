import { Link, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import type { UUID } from '../../../../models/uuid';
import { FieldWrapper } from './FieldWrapper';

interface CustomerCellProps extends GridRenderCellParams<{ id: UUID }, string> {
  showProductDetailsLink?: boolean;
}
export const CustomerCell = ({
  field,
  value,
  row,
  showProductDetailsLink,
  rowNode,
}: CustomerCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? (
    <></>
  ) : (
    <Typography variant="body2">&ndash;</Typography>
  );
  // clicking on the link should not grid row change selection value
  const onLinkClick = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        showProductDetailsLink ? (
          <Link
            component={RouterLink}
            to={`/customer-details/${row.id}`}
            data-account-id={row.id}
            onClick={onLinkClick}
          >
            {value}
          </Link>
        ) : (
          value
        )
      ) : (
        missingValue
      )}
    </FieldWrapper>
  );
};
