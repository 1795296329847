import { Link, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import type { UUID } from '../../../../models/uuid';
import { FieldWrapper } from '../Account/FieldWrapper';

// name cell needs id at a minimum to navigate
type NameCellProps = GridRenderCellParams<{ id: UUID }, string>;
export const NameCell = ({ field, value, row }: NameCellProps) => {
  return (
    <FieldWrapper field={field}>
      {value != null ? (
        <Link component={RouterLink} to={`/sprint/${row.id}`}>
          {value}
        </Link>
      ) : (
        <Typography variant="body2">&ndash;</Typography>
      )}
    </FieldWrapper>
  );
};
