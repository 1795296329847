import React from 'react';

import type { Renewal } from '../../../../hooks/renewal';
import { useCleanRenewalData } from '../../../../hooks/renewal';
import type { GroupingRenewalField } from '../../../../hooks/useBreakdownByGroupChartConfig';
import type {
  OutcomesWeightingField,
  RenewalScoreField,
} from '../../../../hooks/useOutcomesByScoreChartConfig';
import { bottom } from '../../../../utils';
import { BreakdownByGroupChart } from '../../BarChart/BreakdownByGroupChart';
import { GrossRetentionBreakdownChart } from '../../BarChart/GrossRetentionBreakdownChart';
import { RenewalGrowthBreakdownChart } from '../../BarChart/RenewalGrowthBreakdownChart';
import { RenewalOverviewChart } from '../../BarChart/RenewalOverviewChart';
import { OutcomesByScoreChart } from '../../SankeyChart/OutcomesByScoreChart';
import type { DetailCharts } from '../types';

interface DetailChartProps {
  chart: DetailCharts;
  data: Renewal[];
  groupingField: GroupingRenewalField;
  scoreField: RenewalScoreField;
  weightingField: OutcomesWeightingField;
}
export const DetailChart = ({
  chart,
  data,
  groupingField,
  scoreField,
  weightingField,
}: DetailChartProps) => {
  const cleanedData = useCleanRenewalData(data);
  switch (chart) {
    case 'renewal-overview-waterfall':
      return <RenewalOverviewChart renewals={cleanedData} />;
    case 'gross-retention-breakdown-waterfall':
      return <GrossRetentionBreakdownChart renewals={cleanedData} />;
    case 'renewal-growth-breakdown-waterfall':
      return <RenewalGrowthBreakdownChart renewals={cleanedData} />;
    case 'breakdown-by-group-bars':
      return <BreakdownByGroupChart renewals={cleanedData} groupingField={groupingField} />;
    case 'outcomes-by-score-sankey':
      return (
        <OutcomesByScoreChart
          renewals={cleanedData}
          scoreField={scoreField}
          weightingField={weightingField}
        />
      );
    default:
      bottom(chart);
  }
};
