import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { get, isEmpty } from 'lodash';
import { useMemo } from 'react';

import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type {
  GetClientRenewalsQuery,
  RenewalOnTimeStatus,
  RenewalStatus as APIRenewalStatus,
} from '../graphql/generated';
import { GetClientRenewalsDocument } from '../graphql/generated';
import { getModelMoneyFields } from '../models/currency';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import type { UUID } from '../models/uuid';
import { asUUID } from '../models/uuid';
import { coalesceDeepNaNFields, duplicates, transformDate } from '../utils';
import type { PartialUser } from './user';

export enum RenewalStatus {
  NoRenewalFound = 'No Renewal Found',
  Active = 'Active',
  Won = 'Won',
  Lost = 'Lost',
  Unknown = 'Unknown',
}
const RenewalStatusFromAPI: Record<APIRenewalStatus, RenewalStatus> = {
  ACTIVE: RenewalStatus.Active,
  LOST: RenewalStatus.Lost,
  NO_RENEWAL_FOUND: RenewalStatus.NoRenewalFound,
  UNKNOWN: RenewalStatus.Unknown,
  WON: RenewalStatus.Won,
};

export enum RenewalTimeStatus {
  OnTime = 'On Time',
  Pending = 'Pending',
  Late = 'Late',
  Unknown = 'Unknown',
}
const RenewalTimeStatusFromAPI: Record<RenewalOnTimeStatus, RenewalTimeStatus> = {
  LATE: RenewalTimeStatus.Late,
  ON_TIME: RenewalTimeStatus.OnTime,
  PENDING: RenewalTimeStatus.Pending,
  UNKNOWN: RenewalTimeStatus.Unknown,
};

enum ExpiringTimeSegments {
  Pending = 'Pending',
}

enum GlobalTimeSegments {
  Renewed = 'Renewed',
}
enum BreakoutTimeSegments {
  OnTime = 'On Time',
  Late = 'Late',
}

enum ChurnSegments {
  Churn = 'Churn',
}
enum BreakoutChurnSegments {
  ProductDownsell = 'Product Downsell',
  ProductChurn = 'Product Churn',
  ContractChurn = 'Contract Churn',
}

enum UpsellSegments {
  Upsell = 'Upsell',
}
enum BreakoutUpsellSegments {
  ProductCrossSell = 'Product Cross Sell',
  ProductUpsell = 'Product Upsell',
  // TODO: add PriceUplift
}

export const ExpiringSegments = Object.assign(
  {},
  GlobalTimeSegments,
  ExpiringTimeSegments,
  ChurnSegments,
);
export type ExpiringSegments = GlobalTimeSegments | ExpiringTimeSegments | ChurnSegments;
export const ExpiringBreakoutSegments = Object.assign(
  {},
  BreakoutTimeSegments,
  ExpiringTimeSegments,
  BreakoutChurnSegments,
);
export type ExpiringBreakoutSegments =
  | BreakoutTimeSegments
  | ExpiringTimeSegments
  | BreakoutChurnSegments;

export const RenewedSegments = Object.assign(
  {},
  ExpiringTimeSegments,
  GlobalTimeSegments,
  UpsellSegments,
);
export type RenewedSegments = ExpiringTimeSegments | GlobalTimeSegments | UpsellSegments;
export const RenewedBreakoutSegments = Object.assign(
  {},
  BreakoutTimeSegments,
  ExpiringTimeSegments,
  BreakoutUpsellSegments,
);
export type RenewedBreakoutSegments =
  | BreakoutTimeSegments
  | ExpiringTimeSegments
  | BreakoutUpsellSegments;

export interface ExpiringAnalytics extends Record<ExpiringSegments, number> {}
export interface ExpiringBreakoutAnalytics extends Record<ExpiringBreakoutSegments, number> {}

export interface RenewedAnalytics extends Record<RenewedSegments, number> {}
export interface RenewedBreakoutAnalytics extends Record<RenewedBreakoutSegments, number> {}

export interface RenewalAnalytics {
  expiring: ExpiringAnalytics;
  expiringBreakout: ExpiringBreakoutAnalytics;
  renewed: RenewedAnalytics;
  renewedBreakout: RenewedBreakoutAnalytics;
}

// TODO: confirm nullable fields
export interface Renewal {
  /**
   * TODO: verify if `reef_renewal_id` will be unique across
   * all renewals or if we need to modify the UI model `id` value
   */
  id: UUID;
  accountId: UUID;
  accountIndustry: string | null;
  accountName: string;
  baseContractId: UUID;
  baseContractName: string | null;
  baseContractLink: string | null;
  baseContractExpirationDate: Date; // 2009-11-15
  baseContractExpirationQuarter: string; // 2009 Q4
  baseContractExpirationFiscalQuarter: string; // Q4
  baseContractExpirationFiscalYear: string; // 2009
  renewalOpportunityId: UUID | null;
  renewalOpportunityLink: string | null;
  renewalOpportunityName: string | null;
  renewalOpportunityStage: string;
  closeDate: Date | null;
  status: RenewalStatus;
  onTimeStatus: RenewalTimeStatus;
  arrExpiringTotal: number;
  arrNewContract: number;
  arrRenewed: number;
  productDownsell: number;
  productChurn: number;
  contractChurn: number;
  productCrossSell: number;
  productUpsell: number;
  netChurn: number;
  netUpsell: number;

  renewalOwnerName: string | null;
  renewalOwnerEmail: string | null;
  renewalOwnerId: UUID | null;
  primaryOwnerName: string | null;
  primaryOwnerEmail: string | null;
  primaryOwnerId: UUID | null;
  secondaryOwnerName: string | null;
  secondaryOwnerEmail: string | null;
  secondaryOwnerId: UUID | null;

  renewalOwner: PartialUser;
  primaryOwner: PartialUser;
  secondaryOwner: PartialUser;

  customHealthCategoryCurrent: string | null;
  customHealthCategory3mo: string | null;
  customHealthCategory6mo: string | null;
  customHealthCategory9mo: string | null;

  reefRenewalCurrent: string | null;
  reefRenewal3mo: string | null;
  reefRenewal6mo: string | null;
  reefRenewal9mo: string | null;

  analytics: RenewalAnalytics;
}

export const transformRenewal = <T extends GetClientRenewalsQuery['client']>(
  item: T extends { renewals: Array<infer U> } ? U : never,
  index: number,
  processedRenewals: Record<UUID, Renewal>,
  currencyCode: string,
): Renewal => {
  const renewalMoneyFields = getModelMoneyFields(
    {
      arrExpiringTotalConversions: item.arrExpiringTotalConversions ?? null,
      arrNewContractConversions: item.arrNewContractConversions ?? null,
      arrRenewedConversions: item.arrRenewedConversions ?? null,
      contractChurnConversions: item.contractChurnConversions ?? null,
      netChurnConversions: item.netChurnConversions ?? null,
      netUpsellConversions: item.netUpsellConversions ?? null,
      productChurnConversions: item.productChurnConversions ?? null,
      productCrossSellConversions: item.productCrossSellConversions ?? null,
      productDownsellConversions: item.productDownsellConversions ?? null,
      productUpsellConversions: item.productUpsellConversions ?? null,
    },
    [
      'arrExpiringTotalConversions',
      'arrNewContractConversions',
      'arrRenewedConversions',
      'contractChurnConversions',
      'netChurnConversions',
      'netUpsellConversions',
      'productChurnConversions',
      'productCrossSellConversions',
      'productDownsellConversions',
      'productUpsellConversions',
    ],
  );
  let renewalId = item.id;
  if (processedRenewals[item.id] != null) {
    console.warn('transformRenewal', 'found dupe Renewal ID', item.id);
    renewalId = asUUID(`${item.id}-${index}`);
  }
  const renewal: Omit<Renewal, 'analytics'> = {
    accountId: item.accountId,
    accountIndustry: item.accountIndustry,
    accountName: item.accountName,
    arrExpiringTotal: get(renewalMoneyFields.arrExpiringTotalConversions, currencyCode, NaN),
    arrNewContract: get(renewalMoneyFields.arrNewContractConversions, currencyCode, NaN),
    arrRenewed: get(renewalMoneyFields.arrRenewedConversions, currencyCode, NaN),
    baseContractExpirationDate: transformDate(item.contract.endDate),
    baseContractExpirationFiscalQuarter: item.contract.endFiscalQuarter.split(' ')[1],
    baseContractExpirationFiscalYear: item.contract.endFiscalQuarter.split(' ')[0],
    baseContractExpirationQuarter: item.contract.endFiscalQuarter,
    baseContractId: item.contract.id,
    baseContractLink: item.renewalContract.link,
    baseContractName: item.contract.name,
    closeDate: transformDate(item.closeDate),
    contractChurn: get(renewalMoneyFields.contractChurnConversions, currencyCode, NaN),
    customHealthCategory3mo: item.customHealthCategory3mo,
    customHealthCategory6mo: item.customHealthCategory6mo,
    customHealthCategory9mo: item.customHealthCategory9mo,
    customHealthCategoryCurrent: item.customHealthCategoryCurrent,
    id: renewalId,
    netChurn: get(renewalMoneyFields.netChurnConversions, currencyCode, NaN),
    netUpsell: get(renewalMoneyFields.netUpsellConversions, currencyCode, NaN),
    onTimeStatus: RenewalTimeStatusFromAPI[item.onTimeStatus],
    primaryOwner: {
      email: item.primaryOwner?.email ?? null,
      id: item.primaryOwner?.id ?? null,
      name: item.primaryOwner?.name ?? null,
    },
    primaryOwnerEmail: item.primaryOwner?.email ?? null,
    primaryOwnerId: item.primaryOwner?.id ?? null,
    primaryOwnerName: item.primaryOwner?.name ?? null,
    productChurn: get(renewalMoneyFields.productChurnConversions, currencyCode, NaN),
    productCrossSell: get(renewalMoneyFields.productCrossSellConversions, currencyCode, NaN),
    productDownsell: get(renewalMoneyFields.productDownsellConversions, currencyCode, NaN),
    productUpsell: get(renewalMoneyFields.productUpsellConversions, currencyCode, NaN),
    reefRenewal3mo: item.reefRenewal3mo,
    reefRenewal6mo: item.reefRenewal6mo,
    reefRenewal9mo: item.reefRenewal9mo,
    reefRenewalCurrent: item.reefRenewalCurrent,
    renewalOpportunityId: item.renewalOpportunity.id,
    renewalOpportunityLink: item.opportunity.crmLink,
    renewalOpportunityName: item.opportunity.name,
    renewalOpportunityStage: item.opportunity.stage,
    renewalOwner: {
      email: item.renewalOwner?.email ?? null,
      id: item.renewalOwner?.id ?? null,
      name: item.renewalOwner?.name ?? null,
    },
    renewalOwnerEmail: item.renewalOwner?.email ?? null,
    renewalOwnerId: item.renewalOwner?.id ?? null,
    renewalOwnerName: item.renewalOwner?.name ?? null,
    secondaryOwner: {
      email: item.secondaryOwner?.email ?? null,
      id: item.secondaryOwner?.id ?? null,
      name: item.secondaryOwner?.name ?? null,
    },
    secondaryOwnerEmail: item.secondaryOwner?.email ?? null,
    secondaryOwnerId: item.secondaryOwner?.id ?? null,
    secondaryOwnerName: item.secondaryOwner?.name ?? null,
    status: RenewalStatusFromAPI[item.status],
  };
  const pendingAmount =
    renewal.status === RenewalStatus.Active || renewal.status === RenewalStatus.NoRenewalFound
      ? renewal.arrExpiringTotal
      : 0;
  const transformedRenewal = {
    ...renewal,
    analytics: {
      expiring: {
        [ExpiringSegments.Churn]: renewal.netChurn,
        [ExpiringSegments.Renewed]: renewal.arrRenewed,
        [ExpiringSegments.Pending]: pendingAmount,
      },
      expiringBreakout: {
        [ExpiringBreakoutSegments.OnTime]:
          renewal.onTimeStatus === RenewalTimeStatus.OnTime ? renewal.arrRenewed : 0,
        [ExpiringBreakoutSegments.Late]:
          renewal.onTimeStatus === RenewalTimeStatus.Late ? renewal.arrRenewed : 0,
        [ExpiringBreakoutSegments.Pending]: pendingAmount,
        [ExpiringBreakoutSegments.ProductDownsell]: renewal.productDownsell,
        [ExpiringBreakoutSegments.ProductChurn]: renewal.productChurn,
        [ExpiringBreakoutSegments.ContractChurn]: renewal.contractChurn,
      },
      renewed: {
        [RenewedSegments.Pending]: pendingAmount,
        [RenewedSegments.Upsell]: renewal.netUpsell,
        [RenewedSegments.Renewed]: renewal.arrRenewed,
      },
      renewedBreakout: {
        [RenewedBreakoutSegments.OnTime]:
          renewal.onTimeStatus === RenewalTimeStatus.OnTime ? renewal.arrRenewed : 0,
        [RenewedBreakoutSegments.Late]:
          renewal.onTimeStatus === RenewalTimeStatus.Late ? renewal.arrRenewed : 0,
        [RenewedBreakoutSegments.Pending]: pendingAmount,
        [RenewedBreakoutSegments.ProductCrossSell]: renewal.productCrossSell,
        [RenewedBreakoutSegments.ProductUpsell]: renewal.productUpsell,
      },
    },
  };
  processedRenewals[renewal.id] = transformedRenewal;
  return transformedRenewal;
};

export const useRenewals = ({ skip } = { skip: false }): Result<Renewal[], ApolloError> => {
  const { currencyCode } = useUserSettingsContext();
  const renewalsResult = useResultFromQuery(useQuery(GetClientRenewalsDocument, { skip }));
  return useMemo((): Result<Renewal[], ApolloError> => {
    if (renewalsResult.state === ResultType.Value) {
      const { renewals } = renewalsResult.value.client;
      const dupeRenewalIds = duplicates(renewals.map((r) => r.id));
      if (!isEmpty(dupeRenewalIds)) {
        console.error(`found ${dupeRenewalIds.length} duplicate Renewal IDs`, dupeRenewalIds);
      }
      const renewalsById = {};
      const value = renewals.map((r, idx) => transformRenewal(r, idx, renewalsById, currencyCode));
      return { state: renewalsResult.state, value };
    }
    return renewalsResult;
  }, [currencyCode, renewalsResult]);
};

/**
 * Hook to clean renewal data.
 * @param renewals list of renewals with possible bad data
 * @returns cleaned renewal data
 */
export function useCleanRenewalData(renewals: Renewal[]): Renewal[] {
  return useMemo(
    (): Renewal[] => renewals.map<Renewal>((r): Renewal => coalesceDeepNaNFields(r)),
    [renewals],
  );
}
