import React from 'react';

import type { Renewal } from '../../../hooks/renewal';
import { useRenewalGrowthBreakdownChartConfig } from '../../../hooks/useRenewalGrowthBreakdownChartConfig';
import { WaterfallChart } from './WaterfallChart';

interface RenewalGrowthBreakdownChartProps {
  renewals: Renewal[];
}
export const RenewalGrowthBreakdownChart = ({ renewals }: RenewalGrowthBreakdownChartProps) => {
  const barChartProps = useRenewalGrowthBreakdownChartConfig({ renewals });
  return <WaterfallChart {...barChartProps} />;
};
