import type { SxProps, Theme } from '@mui/material';
import type {
  DataGridPremiumProps,
  GridColDef,
  GridGroupingValueGetterParams,
  GridInitialState,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { GridLogicOperator, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import React, { useCallback, useMemo, useState } from 'react';

import {
  AccountNameCell,
  DateCell,
  SprintNameCell,
  StatusCell,
  StringCell,
} from '../components/Tables/CustomGrid';
import type { UserActivitiesGridRowModel } from '../components/Tables/CustomGrid/Activity/types';
import { ActivityStatus, SprintStatus } from '../graphql/generated';
import type { UserActivity } from '../models/sprint';
import type { UUID } from '../models/uuid';
import { useCommonGridHelpers } from './useCommonGridHelpers';

type UserActivitiesGridColDef = GridColDef<UserActivitiesGridRowModel>;

const getSprintName = ({
  row,
}:
  | GridValueGetterParams<UserActivitiesGridRowModel, string>
  | GridGroupingValueGetterParams<UserActivitiesGridRowModel, string>): string | undefined =>
  row.sprint?.name;
const getSprintCreator = ({
  row,
}:
  | GridValueGetterParams<UserActivitiesGridRowModel, string>
  | GridGroupingValueGetterParams<UserActivitiesGridRowModel, string>): string | undefined =>
  row.sprint?.createdBy.name;
const getSprintEnd = ({
  row,
}: GridValueGetterParams<UserActivitiesGridRowModel, Date>): Date | undefined =>
  row.sprint?.end ?? undefined;
const getSprintStart = ({
  row,
}: GridValueGetterParams<UserActivitiesGridRowModel, Date>): Date | undefined =>
  row.sprint?.start ?? undefined;

const getActionName = ({
  row,
}:
  | GridValueGetterParams<UserActivitiesGridRowModel, string>
  | GridGroupingValueGetterParams<UserActivitiesGridRowModel, string>): string | undefined =>
  row.action?.title;

const getAccountName = ({
  row,
}:
  | GridValueGetterParams<UserActivitiesGridRowModel, string>
  | GridGroupingValueGetterParams<UserActivitiesGridRowModel, string>): string | undefined =>
  row.account?.name;

const renderStringCell = (params: GridRenderCellParams<UserActivitiesGridRowModel, string>) => (
  <StringCell {...params} />
);

const renderDateCell = (params: GridRenderCellParams<UserActivitiesGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderAccountNameCell = (
  params: GridRenderCellParams<UserActivitiesGridRowModel, string>,
) => <AccountNameCell {...params} />;
const renderSprintNameCell = (params: GridRenderCellParams<UserActivitiesGridRowModel, string>) => (
  <SprintNameCell {...params} />
);

const shortTransition = (prop: string) => (theme: Theme) =>
  theme.transitions.create([prop], {
    duration: theme.transitions.duration.short,
  });
const gridSx: SxProps<Theme> = {
  '& .MuiDataGrid-cell': {
    // use transparent borders / colors by default & set respective transitions
    '& .MuiOutlinedInput-notchedOutline, & .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      transition: shortTransition('border-color'),
    },
    '& .MuiSelect-icon, & .MuiAutocomplete-popupIndicator': {
      color: 'transparent',
      transition: shortTransition('color'),
    },
  },
  '& .Mui-hovered > .MuiDataGrid-cell': {
    // drop transparent borders / colors on hover
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'unset',
    },
    '& .MuiSelect-icon, & .MuiAutocomplete-popupIndicator': {
      color: 'unset',
    },
  },
};

export interface UpdateActivityStatusOptions {
  activityId: UUID;
  status: ActivityStatus;
}

export interface UseUserActivitiesGridProps {
  activities: UserActivity[];
  updateActivityStatus(options: UpdateActivityStatusOptions): Promise<void>;
}

type UserActivitiesGridProps = Pick<
  DataGridPremiumProps<UserActivity>,
  | 'rows'
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'initialState'
  | 'getRowClassName'
  | 'hideFooter'
  | 'onCellKeyDown'
  | 'autosizeOptions'
  | 'autosizeOnMount'
  | 'sx'
>;
export const useUserActivitiesGrid = ({
  activities,
  updateActivityStatus,
}: UseUserActivitiesGridProps): UserActivitiesGridProps => {
  const hideableColMap = useMemo(() => ({}), []);

  const {
    customNumberComparator: _unused, // restore if ActivitiesGrid needs custom number sorting 🥲
    columnVisibilityModel,
    ...commonProps
  } = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'Action',
    localeEntities: 'Actions',
    // TODO: empty actions message (all things completed? or no work started?)
  });
  const [loadingActivity, setLoadingActivity] = useState<UUID>();

  const onStatusChange = useCallback(
    async ({ activityId, status }: UpdateActivityStatusOptions) => {
      setLoadingActivity(activityId);
      await updateActivityStatus({ activityId, status });
      setLoadingActivity(undefined);
    },
    [updateActivityStatus],
  );

  const renderStatusCell = useCallback(
    (params: GridRenderCellParams<UserActivitiesGridRowModel, ActivityStatus>) => (
      <StatusCell
        onChange={onStatusChange}
        disabled={
          loadingActivity === params.row.id || params.row.sprint.status === SprintStatus.Complete
        }
        {...params}
      />
    ),
    [loadingActivity, onStatusChange],
  );

  const columns = useMemo((): UserActivitiesGridColDef[] => {
    const columns = [
      {
        field: 'actionName',
        headerName: 'Action(s)',
        type: 'string',
        valueGetter: getActionName,
        groupingValueGetter: getActionName,
        renderCell: renderStringCell,
        minWidth: 300,
        maxWidth: 600,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'sprintName',
        headerName: 'Sprint(s)',
        type: 'string',
        valueGetter: getSprintName,
        groupingValueGetter: getSprintName,
        renderCell: renderSprintNameCell,
        minWidth: 200,
        groupable: false, // TODO: support grouping
        aggregable: false,
      },
      {
        field: 'sprintCreator',
        headerName: 'Sprint Creator',
        type: 'string',
        valueGetter: getSprintCreator,
        groupingValueGetter: getSprintCreator,
        renderCell: renderStringCell,
        minWidth: 180,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'accountName',
        headerName: 'Customer Name',
        type: 'string',
        valueGetter: getAccountName,
        groupingValueGetter: getAccountName,
        renderCell: renderAccountNameCell,
        minWidth: 150,
        groupable: false, // TODO: support grouping
        aggregable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        renderCell: renderStatusCell,
        minWidth: 150,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'sprintStart',
        headerName: 'Start Date',
        type: 'string',
        valueGetter: getSprintStart,
        renderCell: renderDateCell,
        minWidth: 140,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'sprintEnd',
        headerName: 'End Date',
        type: 'string',
        valueGetter: getSprintEnd,
        renderCell: renderDateCell,
        minWidth: 140,
        groupable: false,
        aggregable: false,
      },
    ];

    return columns;
  }, [renderStatusCell]);

  const initialState: GridInitialState = useKeepGroupedColumnsHidden({
    apiRef: commonProps.apiRef,
    initialState: {
      columns: {
        columnVisibilityModel,
      },
      filter: {
        filterModel: {
          items: [
            {
              field: 'status',
              operator: 'isAnyOf',
              value: [ActivityStatus.NotStarted, ActivityStatus.InProcess],
            },
          ],
          logicOperator: GridLogicOperator.Or,
          quickFilterValues: [],
          quickFilterLogicOperator: GridLogicOperator.Or,
        },
      },
      rowGrouping: {
        model: [],
      },
      sorting: {
        sortModel: [{ field: 'sprintEnd', sort: 'asc' }],
      },
    },
  });

  return {
    ...commonProps,
    rows: activities,
    checkboxSelection: false,
    columns,
    disableRowSelectionOnClick: true,
    hideFooter: false,
    initialState,
    autosizeOptions: { columns: ['actionName', 'sprintName'], includeOutliers: true, expand: true },
    autosizeOnMount: true,
    sx: gridSx,
  };
};
