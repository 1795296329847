import { useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AddActivityNoteDocument } from '../../../graphql/generated';
import type { UUID } from '../../../models/uuid';
import { sprint$ } from '../../../selectors';
import { pluralize } from '../../../utils';

export interface SprintActivityNoteDialogProps {
  /**
   * The activity ID this note belongs to.
   */
  activityId?: UUID;
  /**
   * The text already in the note.
   * @default ''
   */
  initialText?: string;
  open: boolean;
  onClose: () => void;
}

export type ButtonActionType = 'clear' | 'save';

export const SprintActivityNoteDialog = ({
  activityId,
  initialText = '',
  open,
  onClose,
}: SprintActivityNoteDialogProps) => {
  const [note, setNote] = useState(initialText);
  const [buttonAction, setButtonAction] = useState<ButtonActionType>('save');
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const actionButtonData = useMemo(() => {
    return {
      clear: {
        buttonLabel: {
          regular: 'Clear',
          loading: 'Clearing',
        },
        note: () => 'cleared',
      },
      save: {
        buttonLabel: {
          regular: 'Save',
          loading: 'Saving',
        },
        note: () => (initialText.length > 0 ? 'updated' : 'created'),
      },
    };
  }, [initialText]);

  const onNoteUpdated = useCallback(() => {
    setSnackbarMessage(`Successfully ${actionButtonData[buttonAction].note()} your note.`);
    onClose();
  }, [buttonAction, onClose, actionButtonData]);
  const [addNote, addNoteResult] = useMutation(AddActivityNoteDocument, {
    onCompleted: onNoteUpdated,
  });

  const canSaveNote = useMemo(
    () =>
      !addNoteResult.loading &&
      (note !== initialText || (note.trim() === initialText.trim() && note.trim().length > 0)) &&
      (note.length == 0 || note.trim().length > 0),
    [note, initialText, addNoteResult.loading],
  );
  const onSnackClose = useCallback(() => setSnackbarMessage(undefined), []);
  const notePlaceholder = useMemo(() => {
    if (note.trim().length > 0) {
      return initialText.trim().length > 0
        ? 'Edit the note on this activity'
        : 'Add a note to this activity';
    } else {
      return initialText.trim().length > 0
        ? 'Clear the note on this activity'
        : 'Add a note to this activity';
    }
  }, [note, initialText]);

  // reset note content for many activities reusing one dialog
  useEffect(() => {
    // [sc-2539]: clear text content for whitespace-only notes
    if (initialText.length > 0 && initialText.trim().length < 1) {
      setNote('');
    } else {
      setNote(initialText);
    }
  }, [activityId, initialText]);

  useEffect(() => {
    setButtonAction(
      (initialText.trim().length > 0 && note.trim().length === 0) ||
        (initialText.trim() === note.trim() && note.trim().length > 0)
        ? 'clear'
        : 'save',
    );
  }, [initialText, note]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{activityId != null ? 'Add Note' : 'Loading Note'}</DialogTitle>
        <DialogContent dividers>
          {activityId != null ? (
            <Stack>
              <TextField
                inputProps={{ 'data-uid': sprint$.activityNoteDialog.text }}
                fullWidth
                value={note}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
                multiline
                minRows={4}
                placeholder={notePlaceholder}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Typography
                  data-uid={sprint$.activityNoteDialog.caption}
                  variant="caption"
                  color="InactiveCaptionText"
                >
                  {note.length} {pluralize({ count: note.length, singular: 'char' })}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack sx={{ minHeight: '11rem' }}>
              <LinearProgress />
              <Alert data-uid={sprint$.activityNoteDialog.loading} severity="info">
                Loading your Activity note...
              </Alert>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          {activityId != null ? (
            <>
              <Button
                data-uid={sprint$.activityNoteDialog.cancelBtn}
                variant="outlined"
                color="primary"
                disabled={addNoteResult.loading}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                data-uid={sprint$.activityNoteDialog.saveBtn}
                variant="contained"
                color="primary"
                disabled={!canSaveNote}
                onClick={() =>
                  addNote({
                    variables: { activityId, text: buttonAction === 'clear' ? '' : note },
                  })
                }
              >
                {
                  actionButtonData[buttonAction].buttonLabel[
                    addNoteResult.loading ? 'loading' : 'regular'
                  ]
                }
              </Button>
            </>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        data-uid={sprint$.activityNoteDialog.success}
        open={snackbarMessage != null}
        onClose={onSnackClose}
        TransitionProps={{ onExited: onSnackClose }}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};
