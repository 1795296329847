import { HelpOutline } from '@mui/icons-material';
import type { TooltipProps } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

import { useClientConfig } from '../../hooks/client';
import type { AccountXAxisMetric, AccountYAxisMetric } from '../../hooks/useAccountChartConfig';
import { helpLabel } from '../../hooks/useAccountChartConfig';
import { cells$, tooltip$ } from '../../selectors';

interface NullScoreTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  field: AccountXAxisMetric | AccountYAxisMetric;
  accountName: string;
}
export const NullScoreTooltip = ({ field, accountName, ...rest }: NullScoreTooltipProps) => {
  const [config] = useClientConfig();
  const accountLabel = accountName != null ? <strong>{accountName}</strong> : <>this account</>;
  return (
    <Tooltip
      {...rest}
      key={`${field}-null-score-chip`}
      title={
        // attach uid to tooltip content to avoid collision with help icon
        <span data-uid={tooltip$.nullScoreChipTooltip}>
          <Typography>
            We were unable to find or generate {helpLabel(field, config)} value for {accountLabel}.
            Either a value is not applicable to this account, or there may be data missing.
          </Typography>
        </span>
      }
    >
      <HelpOutline data-uid={cells$.score.nullIcon} fontSize="small" />
    </Tooltip>
  );
};
