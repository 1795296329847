import type { ObjValues } from '../types/util';

const selectBtn = 'app-table-select-btn';
const tableHeader = 'app-table-header';
const tableRow = 'app-table-row';
const headerCell = 'app-table-header-cell';
const rowCell = 'app-table-body-cell';
const tableFooter = 'app-table-footer';
const tablePopper = 'app-table-popper';
interface Table$ {
  selectBtn: typeof selectBtn;
  tableHeader: typeof tableHeader;
  tableRow: typeof tableRow;
  headerCell: typeof headerCell;
  rowCell: typeof rowCell;
  tableFooter: typeof tableFooter;
  tablePopper: typeof tablePopper;
}

export const table$: Table$ = {
  selectBtn,
  tableHeader,
  tableRow,
  headerCell,
  rowCell,
  tableFooter,
  tablePopper,
};
export type Table$Values = ObjValues<Table$>;
