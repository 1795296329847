import { Stack } from '@mui/material';
import { format } from 'date-fns-tz';
import React from 'react';

import { CURRENCY_FORMATTER, DATE_FORMAT_LONG_LOCALIZED } from '../../../../constants';
import { useUserDateSettings } from '../../../../contexts/userSettingsContext';
import type { GrowthRecommendationFields } from '../../../../models/recommendation';
import type { UUID } from '../../../../models/uuid';
import { userWork$ } from '../../../../selectors';
import {
  pluralize,
  toDescriptiveDateTitle,
  toOptionalString,
  WHOLE_PERCENT_NUMBER_FORMATTER,
} from '../../../../utils';
import { HeaderTypography, ValueTypography } from './RecommendationFields';

export const GrowthRecommendationField = <K extends keyof GrowthRecommendationFields>({
  field,
  data,
  recId,
}: {
  recId: UUID;
  field: K;
  data: GrowthRecommendationFields;
}) => {
  const { locale } = useUserDateSettings();
  switch (field) {
    case 'accountPrimaryOwnerId':
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.accountPrimaryOwnerEmail)}
          >
            {data.accountPrimaryOwnerName ?? <>&ndash;</>}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Customer Lead
          </HeaderTypography>
        </Stack>
      );
    case 'accountSecondaryOwnerId':
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.accountSecondaryOwnerEmail)}
          >
            {data.accountSecondaryOwnerName ?? <>&ndash;</>}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Sales Lead
          </HeaderTypography>
        </Stack>
      );
    case 'score': {
      const scoreValue =
        data.score != null ? WHOLE_PERCENT_NUMBER_FORMATTER.format(data.score) : <>&ndash;</>;
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.score)}
          >
            {scoreValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Growth Score
          </HeaderTypography>
        </Stack>
      );
    }
    case 'currentPredictedOverage': {
      const currentPredictedOverageValue =
        data.currentPredictedOverage != null ? (
          WHOLE_PERCENT_NUMBER_FORMATTER.format(data.currentPredictedOverage)
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.currentPredictedOverage)}
          >
            {currentPredictedOverageValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Predicted Overage
          </HeaderTypography>
        </Stack>
      );
    }
    case 'currentPipeline': {
      const currentPipelineValue =
        data.currentPipeline != null ? (
          CURRENCY_FORMATTER.format(data.currentPipeline)
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '7.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={toOptionalString(data.currentPipeline)}
          >
            {currentPipelineValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Current Pipeline
          </HeaderTypography>
        </Stack>
      );
    }
    case 'accountRenewalDate': {
      const accountRenewalDateValue =
        data.accountRenewalDate != null ? (
          format(data.accountRenewalDate, DATE_FORMAT_LONG_LOCALIZED, { timeZone: 'UTC', locale })
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{ minWidth: '7.65rem' }}
            title={toDescriptiveDateTitle(data.accountRenewalDate, {
              prefix: 'Next Renewal ',
              locale,
            })}
          >
            {accountRenewalDateValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Next Renewal
          </HeaderTypography>
        </Stack>
      );
    }
    case 'accountLastUpsellDate': {
      const accountLastUpsellDateValue =
        data.accountLastUpsellDate != null ? (
          format(data.accountLastUpsellDate, DATE_FORMAT_LONG_LOCALIZED, {
            timeZone: 'UTC',
            locale,
          })
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{ minWidth: '7.65rem' }}
            title={toDescriptiveDateTitle(data.accountLastUpsellDate, {
              prefix: 'Last Upsell ',
              locale,
            })}
          >
            {accountLastUpsellDateValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Last Upsell Date
          </HeaderTypography>
        </Stack>
      );
    }
    case 'accountStartedOn': {
      const accountStartedOnValue =
        data.accountStartedOn != null ? (
          format(data.accountStartedOn, DATE_FORMAT_LONG_LOCALIZED, { timeZone: 'UTC', locale })
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{ minWidth: '7.65rem' }}
            title={toDescriptiveDateTitle(data.accountStartedOn, { prefix: 'Started ', locale })}
          >
            {accountStartedOnValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Customer Since
          </HeaderTypography>
        </Stack>
      );
    }
    case 'createdOn': {
      const createdOnValue =
        data.createdOn != null ? (
          format(data.createdOn, DATE_FORMAT_LONG_LOCALIZED, { timeZone: 'UTC', locale })
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{ minWidth: '7.65rem' }}
            title={toDescriptiveDateTitle(data.createdOn, { prefix: 'Recommended ', locale })}
          >
            {createdOnValue}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            Recommended On
          </HeaderTypography>
        </Stack>
      );
    }
    case 'products': {
      const productNames =
        data.products != null && data.products.length > 0 ? (
          data.products.map((p) => p.name).join(', ')
        ) : (
          <>&ndash;</>
        );
      return (
        <Stack direction="column">
          <ValueTypography
            data-uid={userWork$.recommendations.fieldValue(recId, field)}
            sx={{
              maxWidth: '20rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={data.products.map((p) => p.name).join(', ')}
          >
            {productNames}
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            {pluralize({ count: data.products.length, singular: 'Product' })}
          </HeaderTypography>
        </Stack>
      );
    }
    default:
      return (
        <Stack direction="column">
          <ValueTypography data-uid={userWork$.recommendations.fieldValue(recId, field)}>
            &ndash;
          </ValueTypography>
          <HeaderTypography data-uid={userWork$.recommendations.fieldHeader(recId, field)}>
            &ndash;
          </HeaderTypography>
        </Stack>
      );
  }
};
