import { Alert, Box, Card, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DATE_FORMAT_LONG_LOCALIZED } from '../../../../../constants';
import { useUserDateSettings } from '../../../../../contexts/userSettingsContext';
import type { MetricDatum } from '../../../../../graphql/generated';
import { ProductMetricProgressStatus } from '../../../../../graphql/generated';
import { useReefFlags } from '../../../../../hooks/flags';
import { useProductMetrics } from '../../../../../hooks/productMetric';
import type { MetricType } from '../../../../../models/productMetrics';
import { METRICS, ProductMetricMap } from '../../../../../models/productMetrics';
import { ResultType } from '../../../../../models/result';
import type { UUID } from '../../../../../models/uuid';
import { transformDate } from '../../../../../utils';
import { MetricsAcrossTimeChart } from '../../../../Charts/LineChart/MetricsAcrossTimeChart';
import type { ProductMetric } from '../../../../Charts/LineChart/types';
import { LegacyProducts } from '../LegacyProducts';
import { Products } from '../Products';
import type { ProductMetricViews } from '../types';
import { MetricSummary } from './MetricSummary/MetricSummary';
import { ProductMetricPageToggle } from './ProductMetricPageToggle';

const isCompletedProductMetric = ({ progressStatus }: MetricDatum) =>
  progressStatus === ProductMetricProgressStatus.Complete;

export const ProductMetrics = () => {
  const { accountId } = useParams();
  const { showLegacyProducts } = useReefFlags();
  const { locale } = useUserDateSettings();
  const [metricSelected, setMetricSelected] = useState<MetricType>(METRICS.NORTH_STAR);
  const [metricView, setMetricView] = useState<ProductMetricViews>('overview_metrics');
  const productMetricResult = useProductMetrics(accountId as UUID | undefined);
  const metricArray = useMemo(() => {
    if (productMetricResult.state === ResultType.Value) {
      return productMetricResult.value[metricSelected satisfies MetricType];
    }

    return [];
  }, [productMetricResult, metricSelected]);

  const handleChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newMetricView: ProductMetricViews) => {
      // require a view to always be active
      if (newMetricView != null) {
        setMetricView(newMetricView);
      }
    },
    [],
  );

  const chartData: ProductMetric[] = useMemo(() => {
    return metricArray.filter(isCompletedProductMetric).map((metric) => ({
      time: transformDate(metric.weekStartingOn),
      value: metric.metricValue,
      unit: metric.metricUnit,
      type: metricSelected,
      timeUnit: 'Start of Week',
    }));
  }, [metricArray, metricSelected]);

  const hasAllNullChartData = useMemo(
    () => chartData.map((cd) => cd.value).filter(Number.isFinite).length < 1,
    [chartData],
  );

  if (productMetricResult.state === ResultType.Loading) {
    return (
      <Card
        variant="outlined"
        sx={{
          height: 'calc(100vh - 8rem)',
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack spacing={1} direction="row">
          <CircularProgress size={24} />
          <Typography variant="overline">Loading products...</Typography>
        </Stack>
      </Card>
    );
  }

  if (metricView === 'product_level_metrics') {
    return (
      <>
        <Stack direction="row" alignItems="flex-start" justifyContent="flex-end" width="100%">
          <ProductMetricPageToggle metricView={metricView} onChange={handleChange} />
        </Stack>
        {showLegacyProducts ? <LegacyProducts /> : <Products />}
      </>
    );
  }

  return (
    <Stack direction="row" flexGrow={1} justifyContent="space-between" spacing={3}>
      <Stack direction="column" alignItems="flex-start" justifyContent="space-between">
        <Box>
          <Typography variant="h6">Weekly Totals</Typography>
          {productMetricResult.state === ResultType.Value &&
            Object.keys(ProductMetricMap).map((key: string) => (
              <MetricSummary
                key={`metric-summary-${key}`}
                metric={key as MetricType}
                data={productMetricResult.value[key as MetricType].filter(isCompletedProductMetric)}
                isSelected={metricSelected === key}
                onClick={() => setMetricSelected(key as MetricType)}
              />
            ))}
        </Box>
        {metricArray.length > 0 && (
          <Box>
            <Typography variant="body2">
              {format(
                transformDate(metricArray[metricArray.length - 1].weekStartingOn),
                DATE_FORMAT_LONG_LOCALIZED,
                { locale },
              )}
            </Typography>
            <Typography variant="caption">Last Data Sync</Typography>
          </Box>
        )}
      </Stack>
      <Stack direction="column" flexGrow={1} alignItems="flex-start" justifyContent="flex-start">
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width="100%">
          <Typography variant="h6">{ProductMetricMap[metricSelected].title} Trends</Typography>
          <ProductMetricPageToggle metricView={metricView} onChange={handleChange} />
        </Stack>
        <Paper elevation={0} variant="outlined" sx={{ mt: 1, flex: 1, width: '100%' }}>
          <Stack direction="row" alignItems="center" justifyContent="center" height="100%">
            {chartData.length > 0 && !hasAllNullChartData ? (
              <Box sx={{ height: '95%', width: '95%' }}>
                <MetricsAcrossTimeChart metrics={chartData} />
              </Box>
            ) : (
              <Alert severity="error">
                No product metrics trend data available for this account.
              </Alert>
            )}
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};
