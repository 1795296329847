import { useCallback, useMemo } from 'react';

import type { Account } from '../models/account';
import type { UUID } from '../models/uuid';
import { useHighlightedHeatmapTiles, useSelectedHeatmapTiles } from './heatmapTiles';
import type { AccountXAxisMetric, AccountYAxisMetric } from './useAccountChartConfig';
import { useListPersistedEntitySelection } from './useEntitySelection';
import { useHeatmapAccounts } from './useHeatmapAccounts';

interface UseCustomerAccountSelectionResult {
  /**
   * The grid of selected tiles in the heatmap.
   */
  selectedTilesGrid: boolean[][];
  /**
   * The list of accounts selected in the heatmap.
   */
  selectedHeatmapAccounts: Account[];
  /**
   * The id's of the selected heatmap accounts.
   */
  selectedHeatmapAccountIds: UUID[];
  /**
   * The grid of highlighted tiles in the heatmap.
   */
  highlightedTilesGrid: boolean[][];
  /**
   * Called when the user highlights an account.
   * @param account the account being highlighted
   */
  onHighlightAccount(account: Account): void;
  /**
   * Called when the user toggles the account highlighting.
   */
  onUnhighlightAccount(): void;
  /**
   * Callback to handle toggling a heatmap tile.
   * @param coords the [x, y] coordinates toggled in the heatmap
   */
  onToggleHeatmapTile(coords: [number, number]): void;
  /**
   * Callback to handle toggling _all_ heatmap tiles.
   * @param isEveryTileSelected if `true` every tile is selected when called
   */
  onToggleAllHeatmapTiles(isEveryTileSelected: boolean): void;
  /**
   * The list of accounts selected for draft sprint creation.
   */
  newSprintAccounts: Account[];
  /**
   * The list of account ids select for draft sprint creation.
   */
  newSprintAccountIds: UUID[];
  /**
   * Callback used to add or remove an account from the draft sprint creation state.
   * @param account to be added/removed from draft sprint creation state
   */
  toggleDraftSprintAccount(account: Account): void;
  /**
   * Callback used to toggle all accounts for draft sprint creation state.
   * @param entityIds the list of currently selected account ids
   */
  toggleAllDraftSprintAccounts(entityIds: UUID[]): void;
  /**
   * Callback to set the list of accounts in the draft sprint.
   * @param accountIds list of account ids to select for draft sprint creation
   */
  setNewSprintAccountIds(accountIds: UUID[]): void;
}

interface UseCustomerAccountSelectionOptions {
  accounts: Account[];
  heatmapSize?: number;
  xAxisMetric: AccountXAxisMetric;
  yAxisMetric: AccountYAxisMetric;
}

/**
 * Hook to get wrapped logic for the customer heatmap with selected accounts
 * for a sprint.
 * @param root0 options
 * @param root0.accounts the list of customers to show in the heatmap
 * @param root0.heatmapSize the size of the customer heatmap
 * @param root0.xAxisMetric the metric used to bucket on the x-axis
 * @param root0.yAxisMetric the metric used to bucket on the y-axis
 * @returns tuple with data necessary for the customer heatmap with account selection
 */
export function useCustomerAccountSelection({
  accounts,
  xAxisMetric,
  yAxisMetric,
  heatmapSize = 6,
}: UseCustomerAccountSelectionOptions): UseCustomerAccountSelectionResult {
  // a dictionary of accounts indexed by their x-y coordinates on the heatmap
  const heatmapAccountsByCoords = useHeatmapAccounts(accounts, xAxisMetric, yAxisMetric);

  // raw state utils for selecting tiles in the heatmap
  const [selectedTilesGrid, toggleTile, toggleAllTiles] = useSelectedHeatmapTiles(heatmapSize);

  const [highlightedTilesGrid, onHighlightAccount, onUnhighlightAccount] =
    useHighlightedHeatmapTiles(heatmapSize, xAxisMetric, yAxisMetric);

  const selectedHeatmapAccounts = useMemo(() => {
    const selectedAccounts: Array<Account> = [];
    selectedTilesGrid.forEach((row, y) =>
      row.forEach((selected, x) =>
        selected
          ? selectedAccounts.push(...(heatmapAccountsByCoords[`${x}-${y}`] || []))
          : undefined,
      ),
    );
    return selectedAccounts;
  }, [heatmapAccountsByCoords, selectedTilesGrid]);
  const selectedHeatmapAccountIds = useMemo(
    () => selectedHeatmapAccounts.map((a) => a.id),
    [selectedHeatmapAccounts],
  );

  const {
    selectedEntities: newSprintAccounts,
    selectedEntityIds: newSprintAccountIds,
    setSelectedEntityIds: setNewSprintAccountIds,
    toggleEntity: toggleDraftSprintAccount,
    toggleAllEntities: toggleAllDraftSprintAccounts,
  } = useListPersistedEntitySelection({ entities: accounts });

  // used to toggle a single tile (and the accounts on that tile) in the heatmap
  const onToggleHeatmapTile = useCallback(
    ([x, y]: [number, number]) => toggleTile([x, y]),
    [toggleTile],
  );

  // used to toggle _all_ tiles in the customer heatmap
  const onToggleAllHeatmapTiles = useCallback(
    (isEveryTileSelected: boolean) => toggleAllTiles(isEveryTileSelected),
    [toggleAllTiles],
  );

  return {
    selectedTilesGrid,
    selectedHeatmapAccounts,
    selectedHeatmapAccountIds,
    onToggleHeatmapTile,
    onToggleAllHeatmapTiles,
    newSprintAccounts,
    newSprintAccountIds,
    toggleDraftSprintAccount,
    toggleAllDraftSprintAccounts,
    setNewSprintAccountIds,
    highlightedTilesGrid,
    onHighlightAccount,
    onUnhighlightAccount,
  };
}
