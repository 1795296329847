import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React from 'react';

import { customersList$ } from '../../../../selectors';

type FieldWrapperProps = React.PropsWithChildren<Pick<GridRenderCellParams, 'field'>>;
export const FieldWrapper = ({ field, children }: FieldWrapperProps) => {
  switch (field) {
    case 'name':
      return <span data-uid={customersList$.customerName}>{children}</span>;
    case 'crmLink':
    case 'industry':
    case 'ownerName':
    case 'currentArr':
    case 'rawPipeline':
    case 'sixMoWeightedPipeline':
    case 'renewalDate':
    case 'totalOverageDollars':
    case 'customHealthscore':
    case 'productEngagement':
    case 'eventEngagement':
    case 'emailEngagement':
    case 'uniqueProductUsers':
    case 'advancedProductUsers':
    case 'newExperiences':
    case 'totalExperiences':
    case 'totalOverageProducts':
    case 'supportCaseCount':
    case 'totalEmailConversations':
      return <span data-uid={customersList$[field]}>{children}</span>;
    case 'potential':
    case 'engagement':
    default:
      return <>{children}</>;
  }
};
