import { Alert, Box, Card } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { useGetCustomerSprintsPage } from '../../../../hooks/client';
import { useCustomerSprintsGrid } from '../../../../hooks/useCustomerSprintsGrid';
import { ResultType } from '../../../../models/result';
import { asUUID } from '../../../../models/uuid';
import { customerDetails$ } from '../../../../selectors';
import { FailedResult } from '../../../Alerts';
import { CustomGrid } from '../../../Tables/CustomGrid';

export const CustomerSprints = () => {
  const { accountId } = useParams();
  const clientSprintsResult = useGetCustomerSprintsPage({
    accountId: accountId ? asUUID(accountId) : undefined,
  });

  const loading = useMemo(
    () => clientSprintsResult.state === ResultType.Loading,
    [clientSprintsResult.state],
  );
  const sprints = useMemo(
    () => (clientSprintsResult.state === ResultType.Value ? clientSprintsResult.value : []),
    [clientSprintsResult],
  );
  const sprintsGridProps = useCustomerSprintsGrid({ sprints });

  if (accountId == null) {
    return <Alert severity="info">Uh oh! We were unable to find sprints for that account.</Alert>;
  }

  if (
    clientSprintsResult.state === ResultType.Error ||
    clientSprintsResult.state === ResultType.NoValue
  ) {
    return <FailedResult resultType="account sprint details" error={clientSprintsResult.value} />;
  }

  return (
    <Card data-uid={customerDetails$.sprints.section} elevation={0}>
      <Box
        sx={{
          '& .MuiDataGrid-root .MuiDataGrid-booleanCell[data-value="true"]': {
            color: (theme: Theme) => theme.palette.success.main,
          },
        }}
      >
        <CustomGrid
          loading={loading}
          GridWrapperBoxProps={{
            outer: {
              sx: {
                height: 'calc(100vh - 7.3rem)',
                maxWidth: 'calc(100vw - 31.5rem)',
              },
            },
          }}
          {...sprintsGridProps}
        />
      </Box>
    </Card>
  );
};
