import { Close, Settings } from '@mui/icons-material';
import {
  Box,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import type { ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import { useHeatmapContext } from '../../contexts/heatmapContext';
import { useClientConfig } from '../../hooks/client';
import type {
  AccountXAxisMetric,
  AccountXAxisMetricOptional,
  AccountYAxisMetric,
} from '../../hooks/useAccountChartConfig';
import { metricLabel } from '../../hooks/useAccountChartConfig';
import settingsTurtle from '../../images/settingsTurtle.min.svg';
import { heatmap$ } from '../../selectors';

const heatmapDrawerResponsiveWidths = [300, 350, 400, 400, 400];

const X_AXIS_FIELDS: AccountXAxisMetric[] = [
  'engagement',
  'customHealthscore',
  'productEngagement',
  'productConsumptionPercent',
];
const X_AXIS_FIELDS_OPTIONAL: AccountXAxisMetricOptional[] = ['risk', 'growth'];
const Y_AXIS_FIELDS: AccountYAxisMetric[] = ['potential', 'customerRevenueBand'];

export const HeatmapSettingsDrawer = () => {
  const [open, setOpen] = useState(false);
  const [config] = useClientConfig();
  const { buckets } = config;
  const getMenuItem = useCallback(
    <T extends AccountXAxisMetric | AccountYAxisMetric>(field: T, index: number): ReactNode => (
      <MenuItem
        key={`${field}-${index}`}
        data-uid={
          field === 'potential'
            ? heatmap$.settingsYAxisSelectOptions
            : heatmap$.settingsXAxisSelectOptions
        }
        value={field}
      >
        {metricLabel(field, config)}
      </MenuItem>
    ),
    [config],
  );
  const xAxisOptions = useMemo(
    () =>
      [...X_AXIS_FIELDS, ...X_AXIS_FIELDS_OPTIONAL.filter((field) => buckets[field].total > 0)].map(
        getMenuItem,
      ),
    [buckets, getMenuItem],
  );
  const yAxisOptions = Y_AXIS_FIELDS.map(getMenuItem);
  const { xAxisMetric, setXAxisMetric, setYAxisMetric, yAxisMetric } = useHeatmapContext();
  return (
    <>
      <IconButton data-uid={heatmap$.settingsOpen} size="small" onClick={() => setOpen(true)}>
        <Settings fontSize="inherit" color="primary" />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          width: heatmapDrawerResponsiveWidths,
          '& .MuiDrawer-paper': {
            width: heatmapDrawerResponsiveWidths,
            backgroundImage: `url(${settingsTurtle})`,
            backgroundColor: theme.palette.common.white,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
          },
          // this drawer is one above the ReefNavDrawer for overlay rendering
          zIndex: (theme) => theme.zIndex.drawer + 2,
        })}
      >
        <Box sx={{ p: 4 }}>
          <Stack direction="column" spacing={4}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">Heatmap Filters</Typography>
              <IconButton
                size="small"
                data-uid={heatmap$.settingsClose}
                onClick={() => setOpen(false)}
              >
                <Close color="action" />
              </IconButton>
            </Stack>
            <FormControl fullWidth>
              <InputLabel id="x-axis-metric-input-label">X-Axis</InputLabel>
              <Select
                labelId="x-axis-metric-input-label"
                id="x-axis-select"
                data-uid={heatmap$.settingsXAxisSelect}
                value={xAxisMetric}
                label="X-Axis"
                onChange={(e) => setXAxisMetric(e.target.value as AccountXAxisMetric)}
              >
                {xAxisOptions}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="y-axis-metric-input-label">Y-Axis</InputLabel>
              <Select
                labelId="y-axis-metric-input-label"
                id="y-axis-select"
                data-uid={heatmap$.settingsYAxisSelect}
                value={yAxisMetric}
                label="Y-Axis"
                onChange={(e) => setYAxisMetric(e.target.value as AccountYAxisMetric)}
              >
                {yAxisOptions}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
