import { Link } from '@mui/icons-material';
import type { IconButtonProps, SnackbarProps } from '@mui/material';
import { IconButton, Snackbar, Tooltip } from '@mui/material';
import React, { useCallback, useState } from 'react';

interface CopyURLButtonProps {
  hidden: boolean;
  IconButtonProps?: IconButtonProps;
  SnackbarProps?: Omit<SnackbarProps, 'children'>;
}
export const CopyURLButton = ({
  hidden = true,
  IconButtonProps = {},
  SnackbarProps,
}: CopyURLButtonProps) => {
  const { sx: IconButtonSx, ...restIconButtonProps } = IconButtonProps;
  const [open, setOpen] = useState(false);

  const onClick = useCallback(() => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.href);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Tooltip title="Copy URL" followCursor>
        <IconButton
          onClick={onClick}
          color="primary"
          sx={{
            transition: (theme) => theme.transitions.create('opacity'),
            opacity: hidden ? 0 : 1,
            ...IconButtonSx,
          }}
          {...restIconButtonProps}
        >
          <Link />
        </IconButton>
      </Tooltip>
      <Snackbar
        message="URL copied!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={onClose}
        open={open}
        {...SnackbarProps}
      />
    </>
  );
};
