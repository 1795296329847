import { wrapCreateBrowserRouter } from '@sentry/react';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { ReefContextProvider } from '../contexts';
import {
  AuthErrorOutlet,
  AuthRequiredOutlet,
  ReefAuthNavigatorOutlet,
  ReefLeftNavOutlet,
  RootOutlet,
  SplashPageOutlet,
} from './Outlets';
import { ActiveClientRequiredOutlet } from './Outlets/ActiveClientRequiredOutlet';
import {
  AllCustomers,
  Challenge,
  CreateSprint,
  CustomerContracts,
  CustomerDetails,
  CustomerDetailsIndex,
  CustomerMarketing,
  CustomerPipeline,
  CustomerSprints,
  CustomerSupportCases,
  DataSyncQuality,
  FilterCustomers,
  Login,
  Manage,
  ManageIndex,
  MyActions,
  MySprints,
  MyWorkIndex,
  NotFound,
  Playbooks,
  Profile,
  Renewals,
  ResendSecurityCode,
  RiskRecommendations,
  SignUp,
  SprintDashboard,
  Users,
  UserWork,
} from './Routes';
import { GrowthRecommendations } from './Routes/Analytics/GrowthRecommendations';
import { ProductMetrics } from './Routes/CustomerDetails/Products/ProductMetrics/ProductMetrics';
import { DataConnectors } from './Routes/DataConnectors';
import { Knock } from './Routes/Skeleton/Knock';
import { Skeleton } from './Routes/Skeleton/Skeleton';
import { DeleteSprint } from './Routes/Sprint/DeleteSprint';
import { SprintDetails } from './Routes/Sprint/SprintDetails/SprintDetails';
import { DismissMyRecommendation } from './Routes/UserWork/Recommendations/DismissMyRecommendation';
import { MyRecommendationsOutlet } from './Routes/UserWork/Recommendations/MyRecommendationsOutlet';
import { ServiceWorker } from './ServiceWorker';

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#react-router-v6
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const App = () => (
  <ReefContextProvider>
    <ServiceWorker />
    <RouterProvider
      router={sentryCreateBrowserRouter(
        createRoutesFromElements(
          <Route element={<RootOutlet />}>
            <Route element={<ReefAuthNavigatorOutlet />}>
              <Route element={<SplashPageOutlet />}>
                <Route element={<AuthErrorOutlet />}>
                  <Route path="login" element={<Login />} />
                  <Route path="sign-up">
                    <Route index element={<SignUp />} />
                    <Route path="challenge" element={<Challenge />} />
                    <Route path="resend-code" element={<ResendSecurityCode />} />
                  </Route>
                  <Route path="skeleton">
                    <Route index element={<Skeleton />} />
                    <Route path="knock" element={<Knock />} />
                  </Route>
                </Route>
              </Route>
              <Route element={<AuthRequiredOutlet />}>
                <Route element={<ActiveClientRequiredOutlet />}>
                  <Route element={<ReefLeftNavOutlet />}>
                    <Route path="filter-customers">
                      <Route index element={<AllCustomers />} />
                      <Route path=":filterId" element={<FilterCustomers />} />
                    </Route>
                    <Route path="sprint">
                      <Route index element={<SprintDashboard />} />
                      <Route path=":sprintId" element={<SprintDetails />} />
                      <Route path=":sprintId/delete" element={<DeleteSprint />} />
                      <Route path="create" element={<CreateSprint />} />
                      <Route path="capture" element={<CreateSprint variant="capture" />} />
                    </Route>
                    <Route path="customer-details">
                      <Route path=":accountId" element={<CustomerDetails />}>
                        <Route index element={<CustomerDetailsIndex />} />
                        <Route path="products" element={<ProductMetrics />} />
                        <Route path="contracts" element={<CustomerContracts />} />
                        <Route path="pipeline" element={<CustomerPipeline />} />
                        <Route path="support-cases" element={<CustomerSupportCases />} />
                        <Route path="marketing" element={<CustomerMarketing />} />
                        <Route path="sprints" element={<CustomerSprints />} />
                        {/* Legacy redirects */}
                        <Route
                          path="customer-360"
                          element={<Navigate relative="path" to=".." replace />}
                        />
                        <Route
                          path="custom-reports"
                          element={<Navigate relative="path" to=".." replace />}
                        />
                      </Route>
                    </Route>
                    <Route path="manage" element={<Manage />}>
                      <Route index element={<ManageIndex />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="playbooks" element={<Playbooks />} />
                      <Route path="users" element={<Users />} />
                    </Route>
                    <Route path="work">
                      <Route index element={<MyWorkIndex />} />
                      <Route path="my" element={<UserWork />}>
                        <Route index element={<MyWorkIndex />} />
                        <Route path="sprints" element={<MySprints />} />
                        <Route path="actions" element={<MyActions />} />
                        <Route path="recommendations" element={<MyRecommendationsOutlet />}>
                          <Route path=":recId/dismiss" element={<DismissMyRecommendation />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="data">
                      <Route index element={<Navigate relative="route" to="quality" replace />} />
                      <Route path="quality" element={<DataSyncQuality />} />
                    </Route>
                    <Route path="analytics">
                      <Route index element={<Navigate relative="route" to="renewals" replace />} />
                      <Route path="renewals" element={<Renewals />} />
                      <Route path="risk" element={<RiskRecommendations />} />
                      <Route path="growth" element={<GrowthRecommendations />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Route>
                <Route path="connectors" element={<DataConnectors />}>
                  <Route path=":sourceType/create" element={<DataConnectors />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="/"
                element={<Navigate relative="route" to="filter-customers" replace />}
              />
              <Route
                path="/list-of-customers/*"
                element={<Navigate to="/filter-customers" replace />}
              />
            </Route>
          </Route>,
        ),
      )}
    />
  </ReefContextProvider>
);
