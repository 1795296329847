import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { PersistenceKeys } from '../contexts/persistenceContext';
import type { Account } from '../models/account';
import type { AccountXAxisMetric, AccountYAxisMetric } from './useAccountChartConfig';
import { getAccountBucket, getAccountMetric } from './useAccountChartConfig';
import { usePersistence } from './useLocalStorage';

type HeatmapGridSelectionState = boolean[][];
interface GridOverrideValues {
  [yCoord: number]: { [xCoord: number]: boolean | undefined } | undefined;
}
/**
 * Function to create a boolean grid.
 * @param size size of the grid
 * @param defaultTileValue the default value of each tile
 * @param overrideValues specific override value, takes precedence over the default
 * @returns a boolean grid
 */
function createGrid(
  size: number,
  defaultTileValue = true,
  overrideValues: GridOverrideValues = {},
): HeatmapGridSelectionState {
  // TODO: initialize this with browser storage indexed on the `list.id`
  return Array.from({ length: size }, (_, y) =>
    Array.from({ length: size }, (_, x) => overrideValues[y]?.[x] ?? defaultTileValue),
  );
}

/**
 * Hook to manage the highlighted tiles in the heatmap.
 * @param size size of the heatmap
 * @param xAxisMetric account metric for the x-axis
 * @param yAxisMetric account metric for the y-axis
 * @returns tuple of the highlighted tiles and CBs to highlight a tile
 */
export function useHighlightedHeatmapTiles(
  size: number,
  xAxisMetric: AccountXAxisMetric,
  yAxisMetric: AccountYAxisMetric,
): [HeatmapGridSelectionState, (account: Account) => void, () => void] {
  const [highlightedTiles, setHighlightedTiles] = useState(() => createGrid(size, false));

  const onHighlightAccount = useCallback(
    (account: Account) => {
      // default to the 0-0 bucket if the account isn't bucket-able
      const xBucket = getAccountBucket(getAccountMetric(account, xAxisMetric), xAxisMetric) ?? 0;
      const yBucket = getAccountBucket(getAccountMetric(account, yAxisMetric), yAxisMetric) ?? 0;

      setHighlightedTiles(
        createGrid(size, false, {
          [yBucket]: { [xBucket]: true },
        }),
      );
    },
    [size, xAxisMetric, yAxisMetric],
  );

  const onUnhighlightAccount = useCallback(
    () => setHighlightedTiles(createGrid(size, false)),
    [size],
  );

  return [highlightedTiles, onHighlightAccount, onUnhighlightAccount];
}

/**
 * Hook to get the selected tiles state of the heatmap.
 * @param size the size of the grid
 * @returns state for a heatmap grid
 */
export function useSelectedHeatmapTiles(
  size: number,
): [
  HeatmapGridSelectionState,
  (coords: [number, number]) => void,
  (isEveryTileSelected: boolean) => void,
] {
  const defaultPersistedTiles = useMemo(() => createGrid(size), [size]);
  // TODO: it would be cool to type this as a tuple while still allowing the flexibility of
  // using variant size. It's likely possible, but not worth the effort right now.
  const [selectedTiles, setSelectedTiles] = usePersistence(
    PersistenceKeys.HeatmapTileAccountsSelection,
    defaultPersistedTiles,
  );

  const onToggleHeatmapTile = useCallback(
    ([x, y]: [number, number]) => {
      if (selectedTiles.every((row) => row.every((tile) => tile))) {
        setSelectedTiles(createGrid(size, false, { [y]: { [x]: true } }));
      } else {
        const newGrid = cloneDeep(selectedTiles);
        // overwrite the [x,y] coord with the inverse (user selected that tile)
        newGrid[y][x] = !newGrid[y][x];
        setSelectedTiles(newGrid);
      }
    },
    [selectedTiles, setSelectedTiles, size],
  );

  const onToggleAllHeatmapTiles = useCallback(
    (isEveryTileSelected: boolean) => setSelectedTiles(createGrid(size, !isEveryTileSelected)),
    [setSelectedTiles, size],
  );

  return [selectedTiles, onToggleHeatmapTile, onToggleAllHeatmapTiles];
}
