import { kebabCase } from 'lodash';

import type { OverviewField } from '../components/OverviewField/types';
import type { ObjValues } from '../types/util';

const overview = {
  fields: 'customer-details--fields-section',
  notes: 'customer-details--notes-section',
  field: (of: OverviewField) => `customer-details--overview-field--${kebabCase(of)}`,
} as const;
const products = {
  tab: 'customer-details--tab--products',
  section: 'customer-details--products-section',
} as const;
const legacyProducts = {
  tab: 'customer-details--tab--legacy-products',
  section: 'customer-details--legacy-products-section',
} as const;
const contracts = {
  tab: 'customer-details--tab--contracts',
  section: 'customer-details--contracts-section',
} as const;
const renewals = {
  tab: 'customer-details--tab--renewals',
  section: 'customer-details--renewals-section',
} as const;
const pipeline = {
  tab: 'customer-details--tab--pipeline',
  section: 'customer-details--pipeline-section',
} as const;
const supportCases = {
  tab: 'customer-details--tab--support-cases',
  section: 'customer-details--support-cases-section',
} as const;
const marketing = {
  tab: 'customer-details--tab--marketing',
  section: 'customer-details--marketing-section',
} as const;
const sprints = {
  tab: 'customer-details--tab--sprints',
  section: 'customer-details--sprints-section',
} as const;

export const customerDetails$ = {
  contracts,
  legacyProducts,
  marketing,
  overview,
  pipeline,
  products,
  renewals,
  sprints,
  supportCases,
} as const;

export type CustomerDetails$Values =
  | ObjValues<typeof contracts>
  | ObjValues<typeof legacyProducts>
  | ObjValues<typeof marketing>
  | ObjValues<typeof overview>
  | ObjValues<typeof pipeline>
  | ObjValues<typeof products>
  | ObjValues<typeof renewals>
  | ObjValues<typeof sprints>
  | ObjValues<typeof supportCases>;
