import type { SelectChangeEvent, SelectProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useCallback } from 'react';

import type { OutcomesWeightingField } from '../../../../hooks/useOutcomesByScoreChartConfig';
import { OUTCOMES_WEIGHTING_FIELDS } from '../../../../hooks/useOutcomesByScoreChartConfig';
import { analytics$ } from '../../../../selectors/analytics';

type WeightingFieldProps = {
  value: OutcomesWeightingField;
  onChange: (newType: OutcomesWeightingField) => void;
  selectProps?: Partial<SelectProps<OutcomesWeightingField>>;
};
const WEIGHTING_FIELD_LABELS: Record<OutcomesWeightingField, string> = {
  arrExpiringTotal: 'Expiring ARR',
  count: 'Count',
  netChurn: 'Net Churn',
  netUpsell: 'Net Upsell',
};
export const WeightingFieldSelect = ({ value, onChange, selectProps }: WeightingFieldProps) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<OutcomesWeightingField>) =>
      event instanceof Event ? onChange(event.target.value) : undefined,
    [onChange],
  );
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="outcomes-weighting-field-select-label">Weighting</InputLabel>
      <Select<OutcomesWeightingField>
        data-uid={analytics$.weightingFieldSelect.select}
        labelId="outcomes-weighting-field-select-label"
        id="weighting-field-select-small"
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label="Weighting" />}
        {...selectProps}
      >
        {OUTCOMES_WEIGHTING_FIELDS.map((rf) => (
          <MenuItem data-uid={analytics$.weightingFieldSelect.item(rf)} key={rf} value={rf}>
            {WEIGHTING_FIELD_LABELS[rf]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
