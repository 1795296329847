import { Typography } from '@mui/material';
import type { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import React from 'react';

import { NUMBER_FORMATTER } from '../../../../utils';
import { FieldWrapper } from '../Account/FieldWrapper';

export interface NumberCellProps extends GridRenderCellParams<GridValidRowModel, number> {
  elTitle?: string;
}
export const NumberCell = ({
  field,
  value,
  rowNode,
  elTitle = value?.toString(),
}: NumberCellProps) => {
  const ignoreMissingValue = 'isAutoGenerated' in rowNode && rowNode.isAutoGenerated;
  const missingValue = ignoreMissingValue ? <></> : <>&ndash;</>;
  return (
    <FieldWrapper field={field}>
      <Typography variant="body2" title={elTitle}>
        {value != null ? NUMBER_FORMATTER.format(value) : missingValue}
      </Typography>
    </FieldWrapper>
  );
};
