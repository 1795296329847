import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { ProductMetrics } from '../graphql/generated';
import { GetCustomerProductMetricsDocument } from '../graphql/generated';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import type { UUID } from '../models/uuid';

export const useProductMetrics = (accountId?: UUID) => {
  const variables = accountId != null ? { id: accountId } : undefined;
  const apiProductMetricResult = useResultFromQuery(
    useQuery(GetCustomerProductMetricsDocument, { variables, skip: accountId == null }),
  );

  return useMemo((): Result<ProductMetrics, ApolloError> => {
    if (accountId == null) {
      return { state: ResultType.NoValue, value: undefined };
    }

    if (apiProductMetricResult.state === ResultType.Loading) {
      return { state: ResultType.Loading, value: undefined };
    }

    if (apiProductMetricResult.state === ResultType.Value) {
      if (apiProductMetricResult.value.node.__typename === 'Account') {
        return {
          ...apiProductMetricResult,
          value: apiProductMetricResult.value.node.metrics.products,
        };
      }
    }

    return { state: ResultType.NoValue, value: undefined };
  }, [accountId, apiProductMetricResult]);
};
