import { Box, Chip, Typography } from '@mui/material';
import React from 'react';

import type { Account } from '../../../models/account';
import type { UUID } from '../../../models/uuid';
import { sprint$ } from '../../../selectors';

type AccountsByOwnerId = { [id: UUID]: AccountOwner | undefined };

interface AccountOwner {
  ownerName: string;
  numberOfAccounts: number;
}

interface CustomersByOwnerProps {
  /**
   * Collection of accounts to be included in the sprint.
   */
  accounts: Account[];
}

export const CustomersByOwner = ({ accounts }: CustomersByOwnerProps) => {
  const accountsByOwner = accounts.reduce(
    (owners: AccountsByOwnerId, account): AccountsByOwnerId => {
      if (account.ownerId != null && account.ownerName != null) {
        return {
          ...owners,
          [account.ownerId]: {
            ownerName: account.ownerName,
            numberOfAccounts: (owners[account.ownerId]?.numberOfAccounts ?? 0) + 1,
          },
        };
      }
      return owners;
    },
    {},
  );

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {Object.values(accountsByOwner)
        .filter((owner): owner is AccountOwner => owner != null)
        .map((owner, index) => (
          <Box key={`${owner.ownerName}-${index}`} sx={{ mb: 1 }}>
            <Chip
              data-uid={sprint$.startSprintForm.customersByOwnerBadge(index)}
              sx={{ mr: 1 }}
              variant="outlined"
              size="medium"
              color="primary"
              label={
                <Typography>
                  <span data-uid={sprint$.startSprintForm.customersByOwnerBadgeOwner}>
                    {owner.ownerName}
                  </span>{' '}
                  <span data-uid={sprint$.startSprintForm.customersByOwnerBadgeTotal}>
                    ({owner.numberOfAccounts})
                  </span>
                </Typography>
              }
            />
          </Box>
        ))}
    </Box>
  );
};
