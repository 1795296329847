import type { PaletteColor } from '@mui/material';
import { useTheme } from '@mui/material';
import { groupBy, keys, max, min } from 'lodash';
import { useMemo } from 'react';

import type { ProductMetric, ResponsiveLineProps } from '../components/Charts/LineChart/types';
import { METRICS, ProductMetricMap, ProductMetricUnitMap } from '../models/productMetrics';

interface UseMetricsAcrossTimeChartConfigProps {
  metrics: ProductMetric[];
}

const getXFromProductMetric = (pm: ProductMetric) => pm.time;
const getYFromProductMetric = (pm: ProductMetric) => pm.value;

export const useMetricsAcrossTimeChartConfig = ({
  metrics,
}: UseMetricsAcrossTimeChartConfigProps): ResponsiveLineProps => {
  const theme = useTheme();
  const metricType = useMemo(() => {
    return metrics.at(0)?.type ?? METRICS.NORTH_STAR;
  }, [metrics]);
  const leftAxisLegend = useMemo(() => {
    const metricMapping = ProductMetricMap[metricType];
    if (metricMapping) {
      return `${metricMapping.unitSymbolPrefix != null ? ` (${metricMapping.unitSymbolPrefix})` : ''}${metricMapping.title}${metricMapping.unitSymbolSuffix != null ? ` (${metricMapping.unitSymbolSuffix})` : ''}`;
    }
    return metricType;
  }, [metricType]);
  const bottomAxisLegend = useMemo(() => `${metrics.at(0)?.timeUnit}`, [metrics]);
  const axisLeftFormat = useMemo(
    () => (metrics.at(0)?.unit ? ProductMetricUnitMap[metrics[0].unit] : ''),
    [metrics],
  );
  const metricsByType = useMemo(
    () => groupBy(metrics, 'type') as Record<ProductMetric['type'], ProductMetric[]>,
    [metrics],
  );
  const data: ResponsiveLineProps['data'] = useMemo(() => {
    const metricTypes = keys(metricsByType) as Array<ProductMetric['type']>;
    return metricTypes.map((metricType) => ({
      id: metricType,
      data: metricsByType[metricType].map((pm) => ({
        x: getXFromProductMetric(pm),
        y: getYFromProductMetric(pm),
      })),
    }));
  }, [metricsByType]);
  const colors: ResponsiveLineProps['colors'] = useMemo(() => {
    const metricTypes = keys(metricsByType) as Array<ProductMetric['type']>;
    return metricTypes.map(
      // TODO: handle unmapped metric types
      (metricType) => (theme.palette[ProductMetricMap[metricType].color] as PaletteColor).main,
    );
  }, [metricsByType, theme.palette]);
  const metricValueInfo = useMemo(() => {
    const metricValues = metrics.map((m) => m.value).filter(Number.isFinite);
    return {
      min: min(metricValues),
      max: max(metricValues),
    };
  }, [metrics]);
  const yScaleInfo = useMemo(() => {
    return {
      min: Math.min(0, metricValueInfo.min ?? 0),
      max: Math.max(1, metricValueInfo.max ?? 1),
    };
  }, [metricValueInfo.max, metricValueInfo.min]);

  const nonNullMetricValuesCount = useMemo(
    () => metrics.map((m) => m.value).filter(Number.isFinite).length,
    [metrics],
  );

  return {
    data,
    margin: { top: 50, right: 50, bottom: 50, left: 60 },
    xFormat: 'time:%Y-%m-%d',
    xScale: { format: '%Y-%m-%dT%H:%M:%S.%L%Z', type: 'time' },
    yScale: {
      type: 'linear',
      min: yScaleInfo.min,
      max: yScaleInfo.max,
      stacked: true,
      reverse: false,
    },
    yFormat: ' >-.2~%',
    axisTop: null,
    axisRight: null,
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: bottomAxisLegend,
      legendOffset: 35,
      legendPosition: 'middle',
      truncateTickAt: 0,
      format: '%m/%d/%y',
    },
    axisLeft: {
      tickSize: 0,
      tickPadding: 10,
      tickRotation: 0,
      legend: leftAxisLegend,
      legendOffset: -50,
      legendPosition: 'middle',
      truncateTickAt: 0,
      format: axisLeftFormat,
      tickValues: 5,
    },
    colors,
    pointSize: 10,
    pointColor: theme.palette.background.default,
    pointBorderWidth: 2,
    pointBorderColor: { from: 'serieColor' },
    pointLabel: 'data.yFormatted',
    pointLabelYOffset: -12,
    enableTouchCrosshair: true,
    // until https://github.com/plouc/nivo/issues/2630 / https://github.com/plouc/nivo/issues/2597 are resolved,
    // only enable mesh when at least one non-null value is present
    useMesh: nonNullMetricValuesCount > 0,
    legends: undefined,
    enableArea: false,
    theme: {
      background: theme.palette.background.default,
      text: {
        fontFamily: theme.typography.fontFamily,
      },
      tooltip: {
        basic: {
          fontFamily: theme.typography.fontFamily,
        },
      },
      crosshair: {
        line: {
          // TODO: handle unmapped metric types
          stroke: (theme.palette[ProductMetricMap[metricType].color] as PaletteColor).light,
          strokeDasharray: 'none',
        },
      },
    },
    gridXValues: [],
    gridYValues: 5,
    crosshairType: 'bottom',
  };
};
