import { Box, Container, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { format, isAfter } from 'date-fns';
import React, { useMemo } from 'react';

import { DATE_FORMAT_DATE_AND_TIME, HEALTH_FIELDS } from '../../../constants';
import { useUserDateSettings } from '../../../contexts/userSettingsContext';
import { useClientAccounts } from '../../../hooks/client';
import { useDataQualityGrid } from '../../../hooks/useDataQualityGrid';
import type { Account, AccountHealthField } from '../../../models/account';
import { ResultType } from '../../../models/result';
import { data$ } from '../../../selectors/data';
import { CustomGrid } from '../../Tables/CustomGrid';
import { UnhealthyDonut } from './UnhealthyDonut';

type TotalFieldsMap = Record<AccountHealthField, number>;
const DEFAULT_MAP: TotalFieldsMap = {
  currentArr: 0,
  name: 0,
  ownerName: 0,
  renewalDate: 0,
  customerRevenue: 0,
};
const DEFAULT_REFRESH_DATE = new Date(0);

const isUnhealthyField = (a: Account, hf: AccountHealthField): boolean => a[hf] == null;
const isUnhealthyAccount = (a: Account): boolean =>
  HEALTH_FIELDS.some((hf) => isUnhealthyField(a, hf));
const recordUnhealthyAccount = (res: TotalFieldsMap, a: Account): TotalFieldsMap =>
  HEALTH_FIELDS.reduce<TotalFieldsMap>(
    (totals, hf) => ({ ...totals, [hf]: isUnhealthyField(a, hf) ? totals[hf] + 1 : totals[hf] }),
    res,
  );
const getLatestAccountUpdateTime = (currentDate: Date, account: Account) =>
  isAfter(account.reefUpdatedOn, currentDate) ? account.reefUpdatedOn : currentDate;

export const DataSyncQuality = () => {
  const result = useClientAccounts();
  const loading = useMemo(() => result.state === ResultType.Loading, [result.state]);
  const { locale } = useUserDateSettings();
  const error = useMemo(
    () => (result.state === ResultType.Error ? result.value : null),
    [result.state, result.value],
  );
  const accounts = useMemo(
    () => (result.state === ResultType.Value ? result.value : []),
    [result.state, result.value],
  );
  const totalAccountsCount = useMemo(() => accounts.length || 0, [accounts.length]);
  const unhealthyAccounts = useMemo(() => accounts.filter(isUnhealthyAccount), [accounts]);
  const latestUpdate = useMemo(
    () => accounts.reduce(getLatestAccountUpdateTime, DEFAULT_REFRESH_DATE),
    [accounts],
  );
  const totalFieldsCountsMap = useMemo(
    () =>
      unhealthyAccounts.reduce<TotalFieldsMap>(
        (results, account) => recordUnhealthyAccount(results, account),
        DEFAULT_MAP,
      ),
    [unhealthyAccounts],
  );
  const dataQualityGridProps = useDataQualityGrid({
    accounts: unhealthyAccounts,
    fields: HEALTH_FIELDS,
    error: error != null,
  });
  const fieldTitlesMap = useMemo(
    () =>
      dataQualityGridProps.columns.reduce<Record<string, string>>(
        (acc, colDef) => ({
          ...acc,
          [colDef.field]: `${colDef.headerName}`,
        }),
        {},
      ),
    [dataQualityGridProps.columns],
  );
  const lastRefreshContent = useMemo(
    () =>
      latestUpdate.getTime() === DEFAULT_REFRESH_DATE.getTime()
        ? {
            title: 'Latest data sync information unavailable.',
            content: <>&ndash;</>,
          }
        : {
            title: latestUpdate.toString(),
            content: format(latestUpdate, DATE_FORMAT_DATE_AND_TIME, { locale }),
          },
    [latestUpdate, locale],
  );

  return (
    <Box
      data-uid={data$.page.root}
      sx={{
        backgroundColor: (theme) => theme.palette.magic.drawerBackground.main,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Container sx={{ mx: 0 }} maxWidth={false}>
        <Stack spacing={3.5}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <Typography data-uid={data$.page.header} variant="h4">
              Data Sync Quality
            </Typography>
          </Paper>
          <Stack direction="row" spacing={4}>
            {HEALTH_FIELDS.map((hf) => (
              <UnhealthyDonut
                key={hf}
                title={fieldTitlesMap[hf]}
                missing={totalFieldsCountsMap[hf]}
                total={totalAccountsCount}
                loading={loading}
                error={error != null}
              />
            ))}
            <Box sx={{ flexGrow: 2 }} />
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', textAlign: 'end' }}>
                <Typography variant="overline">Data Sync Schedule</Typography>
              </Box>
              <Typography variant="caption">
                {loading ? (
                  <LinearProgress />
                ) : (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'end', textAlign: 'end' }}>
                      Refresh Schedule: Daily
                    </Box>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'end', textAlign: 'end' }}
                      title={lastRefreshContent.title}
                    >
                      Last Refresh: {lastRefreshContent.content}
                    </Box>
                  </>
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Container>
      <Box
        data-uid={data$.page.grid}
        sx={{
          mt: 4,
          '& .data-sync-health--negative': {
            color: (theme) => theme.palette.warning.light,
            backgroundColor: (theme) => theme.palette.warning.s12p,
          },
          backgroundColor: 'white',
        }}
      >
        <CustomGrid
          {...dataQualityGridProps}
          rows={unhealthyAccounts}
          loading={loading}
          GridWrapperBoxProps={{
            outer: {
              sx: {
                height: 'calc(100vh - 25.25rem)',
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
