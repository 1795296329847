import type { Palette } from '@mui/material/styles';

import { ProductMetricUnit } from '../graphql/generated';

export interface ProductMetricConfig {
  title: string;
  color: keyof Palette;
  unitSymbolPrefix: string | undefined;
  unitSymbolSuffix: string | undefined;
}

export const METRICS = {
  NORTH_STAR: 'northStar',
} as const;

const METRIC_TIME_UNITS = {
  NORTH_STAR: 'startOfWeek',
} as const;

export type MetricType = (typeof METRICS)[keyof typeof METRICS];

export type ProductMetricMapType = {
  [key in MetricType]: ProductMetricConfig;
};

export const ProductMetricMap: ProductMetricMapType = {
  [METRICS.NORTH_STAR]: {
    title: 'North Star Metric',
    color: 'primary',
    unitSymbolPrefix: undefined,
    unitSymbolSuffix: '%',
  },
};

export type ProductMetricUnitMapType = {
  [key in ProductMetricUnit]: string;
};

export const ProductMetricUnitMap: ProductMetricUnitMapType = {
  [ProductMetricUnit.Percentage]: ' >-.2~%',
  [ProductMetricUnit.Unknown]: '',
};

const ProductMetricTimeUnitMap = {
  [METRIC_TIME_UNITS.NORTH_STAR]: 'Start of Week',
} as const;
export type ProductMetricTimeUnit =
  (typeof ProductMetricTimeUnitMap)[keyof typeof ProductMetricTimeUnitMap];
