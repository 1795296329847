import { Add } from '@mui/icons-material';
import { Box, Button, Container, Paper, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchContext } from '../../../../contexts/searchContext';
import { SearchProvider } from '../../../../contexts/SearchProvider';
import type { User } from '../../../../hooks/user';
import { useFindUsers } from '../../../../hooks/user';
import { useUsersGrid } from '../../../../hooks/useUsersGrid';
import { ResultType } from '../../../../models/result';
import { SettingsInviteUserDialog } from '../../../Dialogs/SettingsInviteUserDialog';
import { SearchUsers } from '../../../SearchUsers';
import { CustomGrid } from '../../../Tables/CustomGrid';

export const Users = () => (
  <SearchProvider>
    <UsersList />
  </SearchProvider>
);

const UsersList = () => {
  const { searchTerm, searchUsers } = useSearchContext();
  const [findUsers, usersResult] = useFindUsers();
  const users = useMemo((): User[] => {
    if (usersResult.state === ResultType.Value) {
      if (searchTerm) {
        return searchUsers(usersResult.value);
      }
      return usersResult.value;
    }
    return [];
  }, [searchTerm, searchUsers, usersResult.state, usersResult.value]);
  const usersGridProps = useUsersGrid();
  const loading = useMemo(() => usersResult.state === ResultType.Loading, [usersResult.state]);
  const [openDialog, setOpenDialog] = useState(false);

  const openInviteDialog = useCallback(() => setOpenDialog(true), [setOpenDialog]);
  const closeInviteDialog = useCallback(() => setOpenDialog(false), []);

  useEffect(() => {
    findUsers({ variables: { searchTerm: '' } });
  }, [findUsers]);

  return (
    <Container sx={{ my: 4, p: 0, mx: 3 }}>
      <Stack spacing={4}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchUsers data-uid="fixme" inputProps={{ 'data-uid': 'fixme' }} />
          <Box sx={{ my: 'auto' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={openInviteDialog}
            >
              Invite User
            </Button>
          </Box>
        </Box>
        <Paper elevation={0}>
          <CustomGrid
            loading={loading}
            rows={users}
            GridWrapperBoxProps={{ outer: { sx: { height: 'calc(100vh - 19rem)' } } }}
            {...usersGridProps}
          />
        </Paper>
      </Stack>
      <SettingsInviteUserDialog open={openDialog} onClose={closeInviteDialog} />
    </Container>
  );
};
