import React from 'react';

import { useMetricsAcrossTimeChartConfig } from '../../../hooks/useMetricsAcrossTimeChartConfig';
import { LineChart } from './LineChart';
import type { MetricsAcrossTimeChartProps } from './types';

export const MetricsAcrossTimeChart = ({ metrics }: MetricsAcrossTimeChartProps) => {
  const lineChartProps = useMetricsAcrossTimeChartConfig({ metrics });
  return <LineChart {...lineChartProps} />;
};
