import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { LIST_MENU_ITEM_CHAR_LIMIT } from '../../../constants';
import { useReefNavContext } from '../../../contexts/reefNavContext';
import { useMyFilters } from '../../../hooks/filters';
import { ResultType } from '../../../models/result';
import type { UUID } from '../../../models/uuid';
import { filterCustomers$ } from '../../../selectors';
import { ClampedText } from '../../ClampedText';

interface FilterPickerProps {
  /**
   * The current filter selected. If `undefined` the default selected filter is "All Customers".
   */
  selectedFilterId: UUID | undefined;
  /**
   * The initial name of the filter.
   */
  initialName: string;
  /**
   * If `true`, the edit button is disabled.
   */
  disabled?: boolean;
  /**
   * Called when a user selects the filter from the select control.
   * @param filterId the filter selected
   */
  onSelect(filterId: UUID | undefined): void;
  /**
   * Called when a user saves the new name of a filter.
   * @param name new name of the filter
   */
  onRename(name: string): Promise<void>;
  renameLoading: boolean;
  /**
   * Called when a user deletes a filter.
   * @param filterId the filter to delete
   */
  onDelete(filterId: UUID): Promise<void>;
  deleteLoading: boolean;
}
export const FilterPicker = ({
  selectedFilterId,
  initialName,
  disabled,
  onSelect,
  onRename,
  renameLoading,
  onDelete,
  deleteLoading,
}: FilterPickerProps) => {
  const myFiltersResult = useMyFilters();

  const [editing, setEditing] = useState(false);

  const { filterEditMenuState } = useReefNavContext();
  useEffect(
    () => setEditing((filterEditMenuState.open && filterEditMenuState.editName) ?? false),
    [filterEditMenuState],
  );

  const [name, setName] = useState(initialName);

  useEffect(() => setName(initialName), [initialName]);

  const sortedFilterOptions = useMemo((): { id: UUID; name: string }[] | null => {
    if (myFiltersResult.state === ResultType.Value) {
      return sortBy(myFiltersResult.value, ({ name }) => name.toLowerCase());
    }

    return null;
  }, [myFiltersResult]);

  return (
    <Stack direction="row" style={{ marginBottom: '1rem' }} spacing={2} useFlexGap>
      {editing ? (
        <>
          <TextField
            data-uid={filterCustomers$.filterPicker.edit.renameInput}
            fullWidth
            size="small"
            value={name}
            disabled={selectedFilterId == null}
            onChange={(e) => setName(e.target.value)}
            label="Filter name"
          />
          <LoadingButton
            data-uid={filterCustomers$.filterPicker.edit.deleteBtn}
            sx={{ my: 'auto' }}
            size="small"
            variant="outlined"
            color="error"
            loading={myFiltersResult.state === ResultType.Loading || deleteLoading}
            disabled={deleteLoading || selectedFilterId == null}
            onClick={async () => {
              if (selectedFilterId != null) {
                await onDelete(selectedFilterId);
                setEditing(false);
              }
            }}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            data-uid={filterCustomers$.filterPicker.edit.saveBtn}
            sx={{
              my: 'auto',
              // use the darker color, but then lighter color for hover
              bgcolor: (theme) => theme.palette.success.dark,
              '&:hover': { bgcolor: (theme) => theme.palette.success.main },
            }}
            variant="contained"
            size="small"
            color="success"
            disabled={renameLoading || name === ''}
            loading={myFiltersResult.state === ResultType.Loading || renameLoading}
            onClick={async () => {
              await onRename(name);
              setEditing(false);
            }}
          >
            Save
          </LoadingButton>
        </>
      ) : (
        <>
          <FormControl
            size="small"
            fullWidth
            disabled={myFiltersResult.state === ResultType.Loading}
          >
            <InputLabel id="filter-picker-label" htmlFor="filter-picker">
              Saved filters
            </InputLabel>
            <Select
              data-uid={filterCustomers$.filterPicker.select.input}
              id="filter-picker"
              labelId="filter-picker-label"
              label="Saved filters"
              value={
                myFiltersResult.state === ResultType.Loading
                  ? ''
                  : (selectedFilterId ?? 'all-customers')
              }
              endAdornment={
                myFiltersResult.state === ResultType.Loading ? (
                  <CircularProgress sx={{ mr: 3 }} />
                ) : undefined
              }
              onChange={(e) =>
                e instanceof Event &&
                onSelect(e.target.value !== 'all-customers' ? e.target.value : undefined)
              }
              MenuProps={{
                PopoverClasses: { paper: filterCustomers$.filterPicker.select.optionMenuClass },
              }}
            >
              <MenuItem value="all-customers">All Customers</MenuItem>
              {sortedFilterOptions != null &&
                sortedFilterOptions.map(({ id, name }) => (
                  <MenuItem
                    data-uid={filterCustomers$.filterPicker.select.option(name)}
                    sx={{ maxWidth: (theme) => theme.spacing(50) }}
                    key={id}
                    value={id}
                  >
                    <ClampedText text={name || 'New Filter'} limit={LIST_MENU_ITEM_CHAR_LIMIT} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {selectedFilterId != null && (
            <Button
              data-uid={filterCustomers$.filterPicker.select.editBtn}
              sx={{ my: 'auto' }}
              size="small"
              variant="contained"
              disabled={editing || disabled}
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          )}
        </>
      )}
    </Stack>
  );
};
