import type {
  DataGridPremiumProps,
  GridColTypeDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import {
  DateCell,
  MultiCurrencyCell,
  RenewalScoreCell,
  StringCell,
} from '../components/Tables/CustomGrid';
import type { ContractsGridRowModel } from '../components/Tables/CustomGrid/Contract/types';
import type { ContractDetails } from '../models/contract';
import { useCommonGridHelpers } from './useCommonGridHelpers';

interface ContractGridColDef extends GridColTypeDef {
  field: keyof ContractsGridRowModel;
}

const renderDateCell = (params: GridRenderCellParams<ContractsGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderRenewalScoreCell = (params: GridRenderCellParams<ContractsGridRowModel, string>) => (
  <RenewalScoreCell {...params} />
);
const renderMultiCurrencyCell = (params: GridRenderCellParams<ContractsGridRowModel, number>) => (
  <MultiCurrencyCell {...params} />
);
const renderStringCell = (params: GridRenderCellParams<ContractsGridRowModel, string>) => (
  <StringCell {...params} />
);

export interface UseContractsGridProps {
  contracts: ContractDetails[];
}
type ContractsGridProps = Pick<
  DataGridPremiumProps<ContractDetails>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'localeText'
  | 'rowHeight'
  | 'rows'
>;
export const useContractsGrid = ({ contracts }: UseContractsGridProps): ContractsGridProps => {
  const hideableColMap = useMemo(() => ({}), []);

  const commonProps = useCommonGridHelpers({ hideableColMap, localeEntity: 'Contract' });

  const columns = useMemo((): ContractGridColDef[] => {
    return [
      {
        field: 'name',
        headerName: 'Contract',
        hideable: false,
        minWidth: 200,
        type: 'string',
        renderCell: renderStringCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'isActive',
        headerName: 'Status',
        minWidth: 150,
        type: 'string',
        renderCell: renderStringCell,
        valueGetter({ value }: GridValueGetterParams<ContractDetails, boolean>) {
          return value ? 'Active' : 'Contract Inactive';
        },
      },
      {
        field: 'usageRating',
        headerName: 'Renewal Score',
        minWidth: 180,
        type: 'string',
        renderCell: renderRenewalScoreCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'contractStartDate',
        headerName: 'Start Date',
        minWidth: 140,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'contractEndDate',
        headerName: 'Expiration Date',
        minWidth: 170,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'currentArr',
        headerName: 'Current ARR',
        renderCell: renderMultiCurrencyCell,
        minWidth: 150,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'expiringArr',
        headerName: 'Expiring ARR',
        renderCell: renderMultiCurrencyCell,
        minWidth: 150,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'contractValueTotal',
        headerName: 'Total Value',
        renderCell: renderMultiCurrencyCell,
        minWidth: 140,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
      {
        field: 'contractEndFiscalQuarter',
        headerName: 'Quarter',
        hideable: false,
        minWidth: 130,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'contractEndFiscalYear',
        headerName: 'Year',
        hideable: false,
        minWidth: 100,
        type: 'string',
        renderCell: renderStringCell,
        groupable: true,
        aggregable: false,
      },
      {
        field: 'lastModifiedAt',
        headerName: 'Last Updated',
        minWidth: 160,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
    ];
  }, []);

  const initialState: ContractsGridProps['initialState'] = useMemo(
    () => ({
      pinnedColumns: { left: ['name'] },
      sorting: {
        sortModel: [],
      },
    }),
    [],
  );

  return {
    ...commonProps,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    rowHeight: 42,
    initialState,
    columns,
    rows: contracts,
  };
};
