import { PersonRemove } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useCallback } from 'react';

import type { Account } from '../../../../models/account';
import type { UUID } from '../../../../models/uuid';
import { tooltip$ } from '../../../../selectors';
import type { CustomersGridRowModel } from '../../ListOfCustomersTable/types';

interface RowActionsCellProps extends GridRenderCellParams<CustomersGridRowModel, UUID> {
  onRemoveCustomer?: (account: Account) => void;
}
export const RowActionsCell = ({ value, onRemoveCustomer, row: account }: RowActionsCellProps) => {
  const removeCustomer = useCallback(
    (e: React.MouseEvent) => {
      if (onRemoveCustomer) {
        // we want to make sure to prevent this click event from propagating to selecting
        // the customer in the list
        e.stopPropagation();
        onRemoveCustomer(account);
      }
    },
    [account, onRemoveCustomer],
  );
  return value != null && onRemoveCustomer ? (
    <>
      <Tooltip data-uid={tooltip$.component} title="Remove customer">
        <IconButton size="small" onClick={removeCustomer}>
          <PersonRemove fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  ) : (
    <></>
  );
};
