import type { ObjValues } from '../types/util';

export const profile$ = {
  userField: (field: string) => `user-profile-field-${field}` as const,
  saveBtn: (field: string) => `user-profile-save-btn-${field}` as const,
  cancelBtn: (field: string) => `user-profile-cancel-btn-${field}` as const,
  editBtn: (field: string) => `user-profile-edit-btn-${field}` as const,

  regionSelect: 'user-profile-region-select',
  regionSelectOptions: 'user-profile-region-select-options',
  regionSelectOption: (option: string) => `user-profile-region-select-option-${option}` as const,

  currencySelect: 'user-profile-currency-select',
  currencySelectOptions: 'user-profile-currency-select-options',
  currencySelectOption: (option: string) =>
    `user-profile-currency-select-option-${option}` as const,
} as const;

export type Profile$Values = ObjValues<typeof profile$>;
