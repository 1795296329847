import { Card, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { useCustomerOpportunities } from '../../../../hooks/opportunity';
import { useOpportunitiesGrid } from '../../../../hooks/useOpportunitiesGrid';
import { useOpportunityServicePeriodsGrid } from '../../../../hooks/useOpportunityServicePeriodsGrid';
import type { OpportunityServicePeriodDetails } from '../../../../models/opportunity';
import { ResultType } from '../../../../models/result';
import type { UUID } from '../../../../models/uuid';
import { CustomGrid } from '../../../Tables/CustomGrid';

export const CustomerPipeline = () => {
  const { accountId } = useParams();
  const opportunitiesResult = useCustomerOpportunities(accountId as UUID | undefined);
  const opportunityDetails = useMemo(
    () => (opportunitiesResult.state === ResultType.Value ? opportunitiesResult.value : null),
    [opportunitiesResult.state, opportunitiesResult.value],
  );

  const openOpportunities = useMemo(() => {
    if (opportunityDetails == null || opportunityDetails.opportunities == null) {
      return [];
    }
    return opportunityDetails.opportunities.filter((o) => !o.isClosed);
  }, [opportunityDetails]);
  const opportunityServicePeriods = useMemo(() => {
    if (opportunityDetails == null) {
      return [];
    }
    return openOpportunities.reduce<OpportunityServicePeriodDetails[]>(
      (
        conAcc,
        { id: opportunityId, servicePeriods, ...remainingCon },
      ): OpportunityServicePeriodDetails[] => [
        ...conAcc,
        ...servicePeriods.reduce<OpportunityServicePeriodDetails[]>(
          (spAcc, { id: servicePeriodId, ...remainingSp }): OpportunityServicePeriodDetails[] => [
            ...spAcc,
            {
              ...remainingSp,
              servicePeriodId,
              opportunityId,
              ...remainingCon,
              id: `${opportunityId}-${servicePeriodId}`,
            },
          ],
          [],
        ),
      ],
      [],
    );
  }, [openOpportunities, opportunityDetails]);

  const opportunitiesGridProps = useOpportunitiesGrid({ opportunities: openOpportunities });
  const opportunityServicePeriodsGridProps = useOpportunityServicePeriodsGrid({
    opportunityServicePeriods,
  });

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Card elevation={0}>
        <CustomGrid
          loading={opportunitiesResult.state === ResultType.Loading}
          GridWrapperBoxProps={{
            outer: {
              sx: {
                height: 'calc((100vh - 8.8rem) * 0.3)',
                maxWidth: 'calc(100vw - 31.5rem)',
              },
            },
          }}
          {...opportunitiesGridProps}
        />
      </Card>
      <Card elevation={0}>
        <CustomGrid
          loading={opportunitiesResult.state === ResultType.Loading}
          GridWrapperBoxProps={{
            outer: {
              sx: {
                height: 'calc((100vh - 8.8rem) * 0.7)',
                maxWidth: 'calc(100vw - 31.5rem)',
              },
            },
          }}
          {...opportunityServicePeriodsGridProps}
        />
      </Card>
    </Stack>
  );
};
