import type { SxProps, Theme } from '@mui/material';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles/';
import React, { useCallback, useMemo, useState } from 'react';

import { heatmap$, tooltip$ } from '../../../selectors';

/**
 * A static 66% opacity that can be added to the end of a hexadecimal color value.
 */
const HEX_66_PERCENT = 'aa';

/**
 * The variant of the tile. _null + selected + highlighted_ state.
 */
// the order of the kebab'd string is determinate, we use `null`, then
// `selected`, then `highlighted` so we don't have to spend a bunch of effort or time
// reconstructing the variant from a tile object
export type TileVariant =
  | 'default'
  | 'null'
  | 'selected'
  | 'highlighted'
  | 'selected-highlighted'
  | 'null-selected'
  | 'null-highlighted'
  | 'null-selected-highlighted';

/**
 * Variant describing if the tile is in a given corner on a heatmap grid.
 */
export type CornerVariant = 'none' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

interface HeatmapTileProps {
  col: number;
  row: number;
  variant?: TileVariant;
  /**
   * variant describing if the given tile is on a specific corner of a map
   */
  roundedCorner?: CornerVariant;
  /**
   * The set of values that can be shown on the tile.
   */
  displayValue: string | undefined;
  /**
   * The "heat" value of a tile. Determines how much opacity to apply to the
   * primary color. A higher heat value means that the tile is denser and
   * will apply a darker color.
   */
  heatValue: number;
  /**
   * Content added to the tile's tooltip that displays when mousing over the tile.
   */
  tooltipContent: NonNullable<React.ReactNode>;
  /**
   * Called when the tile is clicked.
   */
  onClick: () => void;
}

export const HeatmapTile = ({
  col,
  row,
  variant = 'default',
  roundedCorner = 'none',
  displayValue,
  heatValue,
  tooltipContent,
  onClick,
}: HeatmapTileProps) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const backgroundColor = useMemo(() => {
    if (isHovering) {
      return `${theme.palette.secondary.main}${HEX_66_PERCENT}`;
    }
    // determine the alpha channel of the hex color
    // we turn the opacity from a 256-ish value to a hex - which will translate from 00 to ff
    // the string formatting doesn't include the leading 0, so if it's less than 16, we need
    // to add the leading 0 for formatting purposes
    // using a leading value of 0 is fine here because it's totally fine to display `00`
    // as the opacity so the tile has no color
    const alpha = `${heatValue < 16 ? '0' : ''}${heatValue.toString(16)}`;

    switch (variant) {
      case 'null':
      case 'null-selected':
        return theme.palette.background.paper;
      case 'null-selected-highlighted':
      case 'null-highlighted':
      case 'highlighted':
      case 'selected-highlighted':
        return `${theme.palette.secondary.main}${HEX_66_PERCENT}`;
      default:
        // then use that as the opacity of the background color
        return `${theme.palette.primary.main}${alpha}`;
    }
  }, [
    heatValue,
    isHovering,
    theme.palette.background.paper,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    variant,
  ]);

  const tileButtonSx: SxProps<Theme> = useMemo((): SxProps<Theme> => {
    return [
      {
        // removing min-width lets us scale the buttons down to smaller screens
        // this was forcing a min-width of the whole heatmap
        minWidth: 'unset',
        width: '100%',
        height: '100%',
        p: 'unset',
        borderRadius: 0,
        zIndex: 1,
      },
      {
        '&.tile--top-left': {
          borderTopLeftRadius: '4px',
        },
        '&.tile--top-right': {
          borderTopRightRadius: '4px',
        },
        '&.tile--bottom-left': {
          borderBottomLeftRadius: '4px',
        },
        '&.tile--bottom-right': {
          borderBottomRightRadius: '4px',
        },
      },
      {
        '&.tile--null--dimensions': {
          zIndex: 1,
        },
        '&.tile--non-null--dimensions': {
          zIndex: 2,
        },
      },
      {
        '&.tile--default--variant': {
          color: (theme) => theme.palette.text.primary,
          outline: (theme) => `1px solid ${theme.palette.text.primary}`,
          border: 'none',
          '&--high-heat': {
            color: (theme) => theme.palette.primary.contrastText,
            '&:hover': {
              color: (theme) => theme.palette.text.primary,
            },
          },
        },
        '&.tile--null--variant': {
          color: (theme) => theme.palette.text.disabled,
          outline: (theme) => `1px solid ${theme.palette.text.disabled}`,
          border: 'none',
          '&:hover': {
            color: (theme) => theme.palette.text.primary,
          },
        },
        '&.tile--selected--variant': {
          color: (theme) => theme.palette.text.primary,
          zIndex: 3, // selected > *
          outline: (theme) => `1px solid ${theme.palette.common.white}`,
          border: (theme) => `2px solid ${theme.palette.text.primary}`,
          '&--high-heat': {
            color: (theme) => theme.palette.primary.contrastText,
            '&:hover': {
              color: (theme) => theme.palette.text.primary,
            },
          },
        },
        '&.tile--highlighted--variant': {
          color: (theme) => theme.palette.text.primary,
          outline: (theme) => `1px solid ${theme.palette.text.primary}`,
          border: 'none',
        },
        '&.tile--selected-highlighted--variant': {
          color: (theme) => theme.palette.text.primary,
          zIndex: 3, // selected > *
          outline: (theme) => `1px solid ${theme.palette.common.white}`,
          border: (theme) => `2px solid ${theme.palette.text.primary}`,
        },
        '&.tile--null-selected--variant': {
          color: (theme) => theme.palette.text.disabled,
          zIndex: 3, // selected > *
          outline: (theme) => `1px solid ${theme.palette.common.white}`,
          border: (theme) => `2px solid ${theme.palette.text.primary}`,
          '&:hover': {
            color: (theme) => theme.palette.text.primary,
          },
        },
        '&.tile--null-highlighted--variant': {
          color: (theme) => theme.palette.text.disabled,
          outline: (theme) => `1px solid ${theme.palette.text.primary}`,
          border: 'none',
          '&:hover': {
            color: (theme) => theme.palette.text.primary,
          },
        },
        '&.tile--null-selected-highlighted--variant': {
          color: (theme) => theme.palette.text.disabled,
          zIndex: 3, // selected > *
          outline: (theme) => `1px solid ${theme.palette.common.white}`,
          border: (theme) => `2px solid ${theme.palette.text.primary}`,
          '&:hover': {
            color: (theme) => theme.palette.text.primary,
          },
        },
      },
    ];
  }, []);

  const tileClassName = useMemo(
    () =>
      [
        `tile--${roundedCorner}`,
        `tile--${variant}--variant`,
        heatValue > 256 * 0.8 ? `tile--${variant}--variant--high-heat` : '',
        `tile--${col > 0 && row > 0 ? 'non-null' : 'null'}--dimensions`,
      ].join(' '),
    [col, heatValue, roundedCorner, row, variant],
  );

  const onMouseEnter = useCallback(() => setIsHovering(true), []);
  const onMouseLeave = useCallback(() => setIsHovering(false), []);

  return (
    <Box sx={{ aspectRatio: '1 / 1' }}>
      <Tooltip
        followCursor
        data-uid={tooltip$.component}
        title={
          <Box sx={{ m: 1 }} data-uid={tooltip$.heatmapTile}>
            {tooltipContent}
          </Box>
        }
      >
        <Button
          className={tileClassName}
          data-uid={heatmap$.tileBtn(col, row)}
          sx={tileButtonSx}
          style={{
            backgroundColor,
          }}
          disableElevation
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          variant="contained"
        >
          <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '0.875rem' }}>
            {displayValue ?? <>&ndash;</>}
          </Typography>
        </Button>
      </Tooltip>
    </Box>
  );
};
