import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { differenceInCalendarDays } from 'date-fns';
import { get } from 'lodash';
import { useMemo } from 'react';

import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type {
  Account as APIAccount,
  AccountFieldsFragment,
  AccountIndustryFieldsFragment,
  Case as APICase,
  CategoricalMetric,
  LegacyScore as APIScore,
  ProductConsumptionFieldsFragment,
} from '../graphql/generated';
import {
  GetAccountIndustriesDocument,
  GetAccountNamesDocument,
  GetAccountRegionsDocument,
  GetCustomerDetailsPageDocument,
  GetCustomerProductsPageDocument,
  GetCustomerSupportCasesPageDocument,
} from '../graphql/generated';
import { useDemoAugmenters } from '../graphql/hooks';
import type {
  Account,
  AccountCaseDetails,
  AccountProductDetails,
  CustomNumericalMetric,
  Industry,
  ProductConsumptionDetails,
  Score,
  SupportCaseDetails,
} from '../models/account';
import { asIndustry } from '../models/account';
import { getModelMoneyFields } from '../models/currency';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import type { UUID } from '../models/uuid';
import { toLoggableErrorMessages, transformDate } from '../utils';

export const transformProduct = ({
  id,
  contractYear,
  contractedUnits,
  overageDate,
  overageDollarsTotal,
  overageUnitsTotal,
  productName,
  projectedUnitsTotal,
  serviceEndDate,
  serviceStartDate,
  unitsUsedTotal,
  usageUpdatedOn,
}: ProductConsumptionFieldsFragment): ProductConsumptionDetails => ({
  id,
  contractedUnits,
  contractYear,
  overageDate: transformDate(overageDate),
  overageDollarsTotal,
  overageUnitsTotal,
  productName,
  projectedUnitsTotal,
  serviceEndDate: transformDate(serviceEndDate),
  serviceStartDate: transformDate(serviceStartDate),
  unitsUsedTotal,
  usageUpdatedOn: transformDate(usageUpdatedOn),
  // An account will have the product if there is a service period with
  // any amount of contracted units
  hasProduct: contractedUnits != null,
});

/**
 * Industry type used for client Accounts with a null/missing/unknown Industry value.
 */
export const NULL_INDUSTRY = asIndustry('UNKNOWN INDUSTRY');
export const transformIndustry = (accountIndustry: string | null): Industry =>
  accountIndustry == null || accountIndustry.trim().length === 0
    ? NULL_INDUSTRY
    : asIndustry(accountIndustry);

const transformAccountForIndustry = (account: AccountIndustryFieldsFragment): Industry =>
  transformIndustry(account.rawData.industry);

export const NULL_REGION = 'UNKNOWN REGION';

const transformScore = <S extends Pick<APIScore, 'value'>>(
  scoreField: S | null | undefined,
): Score | null => (scoreField != null ? { value: scoreField.value } : null);

const transformCategoricalScore = (
  scoreField: CategoricalMetric | null | undefined,
): CustomNumericalMetric | null => (scoreField != null ? scoreField : null);

export const transformCase = (c: APICase): SupportCaseDetails => ({
  caseLink: c.crmLink,
  caseNumber: c.number,
  caseType: c.type,
  createdAt: transformDate(c.createdAt),
  id: c.id,
  lastModifiedAt: transformDate(c.lastModifiedAt),
  status: c.status,
  isOpen: c.isOpen ?? false,
  closedOn: transformDate(c.closedOn),
  durationDays: c.durationDays,
  priority: c.priority,
  supportTier: c.supportTier,
});
const transformCases = (cases?: APICase[] | null): SupportCaseDetails[] | null =>
  cases != null ? cases.map(transformCase) : null;

/**
 * Hook to get a set of industries for a Reef client.
 * @returns a set of industries
 */
export const useAccountIndustries = (): Result<Set<Industry>, ApolloError> => {
  const industriesResult = useResultFromQuery(useQuery(GetAccountIndustriesDocument));

  return useMemo(() => {
    if (industriesResult.state === ResultType.Value) {
      return {
        ...industriesResult,
        value: new Set(industriesResult.value.client.accounts.map(transformAccountForIndustry)),
      };
    }

    return industriesResult;
  }, [industriesResult]);
};

/**
 * Hook to get a set of account names for a Reef client.
 * @returns a set of account names
 */
export const useAccountNames = (): Result<Set<string>, ApolloError> => {
  const accountNamesResult = useResultFromQuery(useQuery(GetAccountNamesDocument));
  return useMemo(() => {
    if (accountNamesResult.state !== ResultType.Value) {
      return accountNamesResult;
    }
    return {
      ...accountNamesResult,
      value: new Set(accountNamesResult.value.client.accounts.map((a) => a.name)),
    };
  }, [accountNamesResult]);
};

type PossibleAccountFields = {
  id: UUID;
  productConsumption?: ProductConsumptionFieldsFragment[];
} & Pick<AccountFieldsFragment, 'name' | 'lastUpdated' | 'status'> &
  Partial<AccountFieldsFragment>;
/**
 * Function to map an api account to an {@link Account}
 * @param account an account retrieved from the api
 * @param currencyCode optional currencyCode string to display appropriate currency values
 * @returns an app {@link Account} model
 */
export function getAccountFromAPIAccount<A extends PossibleAccountFields>(
  account: A,
  currencyCode: string | null = null,
): Account {
  const isUsingCurrencyCode = currencyCode != null;
  const accountMoneyFields = getModelMoneyFields(
    {
      currentArrConversions: account.reefData?.currentArrConversions ?? null,
      customerRevenueConversions: account.rawData?.customerRevenueConversions ?? null,
      rawPipelineConversions: account.rawData?.rawPipelineConversions ?? null,
      sixMoWeightedPipelineConversions: account.reefData?.sixMoWeightedPipelineConversions ?? null,
      totalOverageDollarsConversions: account.reefData?.totalOverageDollarsConversions ?? null,
    },
    [
      'currentArrConversions',
      'customerRevenueConversions',
      'rawPipelineConversions',
      'sixMoWeightedPipelineConversions',
      'totalOverageDollarsConversions',
    ],
  );
  return {
    id: account.id,
    name: account.name,
    status: account.status,
    crmLink: account.reefData?.crmLink ?? null,
    potential: transformScore(account.reefData?.scores?.potential),
    engagement: transformScore(account.reefData?.scores?.engagement),
    risk: transformCategoricalScore(account.reefData?.scores.risk),
    growth: transformCategoricalScore(account.reefData?.scores.growth),
    currentArr: isUsingCurrencyCode
      ? get(accountMoneyFields.currentArrConversions, currencyCode, null)
      : (account.reefData?.currentArr ?? null),
    customerRevenue: isUsingCurrencyCode
      ? get(accountMoneyFields.customerRevenueConversions, currencyCode, null)
      : (account.rawData?.customerRevenue ?? null),
    rawPipeline: isUsingCurrencyCode
      ? get(accountMoneyFields.rawPipelineConversions, currencyCode, null)
      : (account.rawData?.rawPipeline ?? null),
    sixMoWeightedPipeline: isUsingCurrencyCode
      ? get(accountMoneyFields.sixMoWeightedPipelineConversions, currencyCode, null)
      : (account.reefData?.sixMoWeightedPipeline ?? null),
    // nullish because of optional chaining
    ownerId: account.roles?.primaryOwner?.id ?? null,
    ownerName: account.roles?.primaryOwner?.name ?? null,
    ownerEmail: account.roles?.primaryOwner?.email ?? null,
    renewalOwnerName: account.roles?.renewalOwner?.name ?? null,
    renewalOwnerEmail: account.roles?.renewalOwner?.email ?? null,
    secondaryOwnerName: account.roles?.secondaryOwner?.name ?? null,
    secondaryOwnerEmail: account.roles?.secondaryOwner?.email ?? null,
    productEngagement: transformScore(account.reefData?.scores?.productEngagement),
    productConsumptionPercent: account.reefData?.productConsumptionPercent ?? null,
    customHealthscore: {
      value: account.rawData?.customHealthValue ?? null,
      bucket: account.rawData?.customHealthBucket ?? NaN,
      category: account.rawData?.customHealthCategory ?? null,
    },
    customerRevenueBand: {
      value: account.rawData?.customerRevenue?.toString() ?? null,
      bucket: account.rawData?.customerRevenueBucket ?? NaN,
      category: account.rawData?.customerRevenueCategory ?? null,
    },
    industry: account.rawData?.industry as Industry | null,
    region: account.rawData?.region ?? null,
    customerStartedOn: transformDate(account.rawData?.customerStartedOn ?? null),
    renewalDate: transformDate(account.rawData?.renewalDate ?? null),
    daysUntilRenewal:
      account.rawData?.renewalDate != null
        ? differenceInCalendarDays(transformDate(account.rawData?.renewalDate), new Date())
        : null,
    reefUpdatedOn: transformDate(account.lastUpdated),
    supportCaseClosedDuration: account.reefData?.supportCaseClosedDuration ?? null,
    supportCaseCount: account.reefData?.supportCaseCount ?? null,
    supportCaseOpenDuration: account.reefData?.supportCaseOpenDuration ?? null,
    supportCaseTotal: account.reefData?.supportCaseTotal ?? null,
    uniqueProductUsers: transformScore(account.reefData?.scores?.uniqueProductUsers),
    advancedProductUsers: transformScore(account.reefData?.scores?.advancedProductUsers),
    totalOverageDollars: isUsingCurrencyCode
      ? get(accountMoneyFields.totalOverageDollarsConversions, currencyCode, null)
      : (account.reefData?.totalOverageDollars ?? null),
    totalOverageProducts: account.reefData?.totalOverageProducts ?? null,
    hasBeenImplemented: account.reefData?.milestones?.hasBeenImplemented ?? null,

    implementedOn: transformDate(account.reefData?.milestones?.implementedOn ?? null),
    daysSinceImplemented:
      account.reefData?.milestones?.implementedOn != null
        ? differenceInCalendarDays(
            new Date(),
            transformDate(account.reefData?.milestones.implementedOn),
          )
        : null,

    hasBeenOnboarded: account.reefData?.milestones?.hasOnboarded ?? null,
    onboardedOn: transformDate(account.reefData?.milestones?.onboardedOn ?? null),
    daysSinceOnboarded:
      account.reefData?.milestones?.onboardedOn != null
        ? differenceInCalendarDays(
            new Date(),
            transformDate(account.reefData?.milestones.onboardedOn),
          )
        : null,
    renewalFiscalQuarter: account.reefData?.renewalFiscalQuarter ?? null,
    supportEngagement: transformScore(account.reefData?.scores?.supportEngagement),
    productConsumption:
      account.productConsumption != null ? account.productConsumption.map(transformProduct) : [],
    cases: null,
  };
}

export type ProductDetailsFields = Pick<APIAccount, 'id' | 'name' | 'lastUpdated'> & {
  rawData: Pick<APIAccount['rawData'], 'renewalDate'>;
} & Pick<APIAccount, 'productConsumption'>;
export type OpportunityDetailsFields = Pick<
  APIAccount,
  'id' | 'name' | 'lastUpdated' | 'opportunities'
>;
export type CaseDetailsFields = Pick<APIAccount, 'id' | 'name' | 'lastUpdated' | 'cases'> & {
  reefData: Pick<
    APIAccount['reefData'],
    | 'supportCaseCount'
    | 'supportCaseClosedDuration'
    | 'supportCaseOpenDuration'
    | 'supportCaseTotal'
  > & {
    scores: {
      supportEngagement: AccountFieldsFragment['reefData']['scores']['supportEngagement'];
    };
  };
};
function getAccountDetailsFromAPI(fields: ProductDetailsFields): AccountProductDetails;
function getAccountDetailsFromAPI(fields: CaseDetailsFields): AccountCaseDetails;
/**
 * Builds details from fragmented queries.
 * @param fields possible fragmented query fields
 * @returns corresponding Account<F>Details
 */
function getAccountDetailsFromAPI(
  fields: ProductDetailsFields | OpportunityDetailsFields | CaseDetailsFields,
) {
  if ('productConsumption' in fields) {
    return {
      id: fields.id,
      name: fields.name,
      reefUpdatedOn: transformDate(fields.lastUpdated),
      renewalDate: transformDate(fields.rawData?.renewalDate ?? null),
      productConsumption: fields.productConsumption.map(transformProduct),
    } satisfies AccountProductDetails;
  }
  if ('cases' in fields && 'reefData' in fields) {
    return {
      id: fields.id,
      name: fields.name,
      reefUpdatedOn: transformDate(fields.lastUpdated),
      cases: transformCases(fields.cases),
      supportCaseClosedDuration: fields.reefData.supportCaseClosedDuration,
      supportCaseCount: fields.reefData?.supportCaseCount,
      supportCaseOpenDuration: fields.reefData.supportCaseOpenDuration,
      supportCaseTotal: fields.reefData.supportCaseTotal,
      supportEngagement: transformScore(fields.reefData?.scores?.supportEngagement),
    } satisfies AccountCaseDetails;
  }
  return;
}

export const useCustomerProducts = (
  accountId?: UUID,
): Result<AccountProductDetails, ApolloError> => {
  const variables = accountId != null ? { id: accountId } : undefined;
  const apiProductsResult = useResultFromQuery(
    useQuery(GetCustomerProductsPageDocument, { variables, skip: accountId == null }),
  );

  const { augmentAPIProductFieldsWithDemoData, shouldAugmentData } = useDemoAugmenters();

  return useMemo((): Result<AccountProductDetails, ApolloError> => {
    if (accountId == null) {
      return { state: ResultType.NoValue, value: undefined };
    }

    if (apiProductsResult.state === ResultType.Value) {
      if (apiProductsResult.value.node.__typename === 'Account') {
        const productsResult = shouldAugmentData
          ? augmentAPIProductFieldsWithDemoData(apiProductsResult.value.node)
          : apiProductsResult.value.node;
        return {
          ...apiProductsResult,
          value: getAccountDetailsFromAPI(productsResult),
        };
      }

      return { state: ResultType.NoValue, value: undefined };
    }

    if (apiProductsResult.state === ResultType.Error) {
      console.warn(
        ...toLoggableErrorMessages('useCustomerProducts failed to fetch', apiProductsResult.value),
      );
    }

    return apiProductsResult;
  }, [accountId, apiProductsResult, augmentAPIProductFieldsWithDemoData, shouldAugmentData]);
};

/**
 * Hook to get an Account (with products) by it's id for Customer Details.
 * @param accountId account to get
 * @returns result of an account query
 */
export const useCustomerDetails = (accountId?: UUID): Result<Account, ApolloError> => {
  const { currencyCode } = useUserSettingsContext();
  const variables = accountId != null ? { id: accountId } : undefined;
  const detailsResult = useResultFromQuery(
    useQuery(GetCustomerDetailsPageDocument, { variables, skip: accountId == null }),
  );

  const { augmentAPIAccountsWithDemoData, shouldAugmentData } = useDemoAugmenters();

  return useMemo((): Result<Account, ApolloError> => {
    if (accountId == null) {
      return { state: ResultType.NoValue, value: undefined };
    }

    if (detailsResult.state === ResultType.Value) {
      if (detailsResult.value.node.__typename === 'Account') {
        const detailsResultAccount = shouldAugmentData
          ? augmentAPIAccountsWithDemoData([detailsResult.value.node])[0]
          : detailsResult.value.node;
        return {
          ...detailsResult,
          value: getAccountFromAPIAccount(detailsResultAccount, currencyCode),
        };
      }

      return { state: ResultType.NoValue, value: undefined };
    }

    if (detailsResult.state === ResultType.Error) {
      console.warn(
        ...toLoggableErrorMessages('useCustomerDetails failed to fetch', detailsResult.value),
      );
    }

    return detailsResult;
  }, [accountId, augmentAPIAccountsWithDemoData, currencyCode, shouldAugmentData, detailsResult]);
};

export const useCustomerCases = (accountId?: UUID): Result<AccountCaseDetails, ApolloError> => {
  const variables = accountId != null ? { id: accountId } : undefined;
  const casesResult = useResultFromQuery(
    useQuery(GetCustomerSupportCasesPageDocument, { variables, skip: accountId == null }),
  );

  const { augmentAPICaseDetailsFieldsWithDemoData, shouldAugmentData } = useDemoAugmenters();

  return useMemo((): Result<AccountCaseDetails, ApolloError> => {
    if (accountId == null) {
      return { state: ResultType.NoValue, value: undefined };
    }

    if (casesResult.state === ResultType.Value) {
      if (casesResult.value.node.__typename === 'Account') {
        const casesFields = shouldAugmentData
          ? augmentAPICaseDetailsFieldsWithDemoData(casesResult.value.node)
          : casesResult.value.node;
        return {
          ...casesResult,
          value: getAccountDetailsFromAPI(casesFields),
        };
      }

      return { state: ResultType.NoValue, value: undefined };
    }

    if (casesResult.state === ResultType.Error) {
      console.warn(
        ...toLoggableErrorMessages('useCustomerCases failed to fetch', casesResult.value),
      );
    }

    return casesResult;
  }, [accountId, augmentAPICaseDetailsFieldsWithDemoData, casesResult, shouldAugmentData]);
};

/**
 * Hook to get a set of regions for a Reef client.
 * @returns a set of regions
 */
export const useAccountRegions = (): Result<Set<string>, ApolloError> => {
  const regionsResult = useResultFromQuery(useQuery(GetAccountRegionsDocument));

  return useMemo(() => {
    if (regionsResult.state === ResultType.Value) {
      return {
        ...regionsResult,
        value: new Set(
          regionsResult.value.client.accounts
            .map((account) => account.rawData.region ?? NULL_REGION)
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
        ),
      };
    }

    return regionsResult;
  }, [regionsResult]);
};
