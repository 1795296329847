import { useMutation } from '@apollo/client';
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import type { DateRange, DateRangePickerDayProps } from '@mui/x-date-pickers-pro';
import { DateRangePickerDay, DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { addDays, getUnixTime, isToday, startOfToday } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CreateSprintBulkDocument,
  GetMySprintsDocument,
  GetSprintDashboardDocument,
} from '../../../graphql/generated';
import { useObjectives } from '../../../hooks/client';
import type { Account } from '../../../models/account';
import { ResultType } from '../../../models/result';
import type { Objective } from '../../../models/sprint';
import { CreateSprintStep } from '../../../models/sprint';
import { sprint$ } from '../../../selectors';
import { bottom } from '../../../utils';
import { CustomersByOwner } from './CustomersByOwner';
import { PlaybookPicker } from './PlaybookPicker';
import { SprintAccountSelectionOverview } from './SprintAccountSelectionOverview';

interface CreateDraftSprintDialogProps {
  /**
   * If `true`, the dialog is open.
   */
  open: boolean;
  /**
   * Called when the dialog is closed.
   */
  onClose: () => void;
  /**
   * The list of accounts to be added to the sprint.
   */
  accounts: Account[];
}

export const CreateDraftSprintDialog = ({
  open,
  onClose,
  accounts,
}: CreateDraftSprintDialogProps) => {
  const nav = useNavigate();
  const today = startOfToday();
  const [objective, setObjective] = useState<Objective>();
  const [playbooks, setPlaybooks] = useState<Objective[]>();
  const [sprintName, setSprintTitle] = useState<string>('');
  const [sprintDateRange, setSprintDateRange] = useState<DateRange<Date>>([today, null]);
  const objectivesResult = useObjectives({ withActions: true });
  const [createSprintStep, setCreateSprintStep] = useState(CreateSprintStep.Objective);

  const afterCloseSprintDialog = useCallback(() => {
    setCreateSprintStep(CreateSprintStep.Objective);
  }, []);
  const selectObjective = useCallback(() => setCreateSprintStep(CreateSprintStep.Start), []);
  const afterSprintStart = useCallback(
    () =>
      nav('/work/my/sprints', {
        state: { startSprintSnackbarOpen: true, numOfSprints: accounts.length },
      }),
    [nav, accounts],
  );
  const goBack = useCallback(
    () => setCreateSprintStep((createSprintStep - 1) % CreateSprintStep.Start),
    [createSprintStep],
  );

  useEffect(() => {
    if (objective?.title) {
      setSprintTitle(objective?.title);
    }
  }, [objective?.title]);

  useEffect(() => {
    if (objectivesResult.state === ResultType.Value) {
      setPlaybooks(objectivesResult.value);
    }
  }, [objectivesResult]);

  const [createSprintBulk, createSprintBulkResult] = useMutation(CreateSprintBulkDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }, { query: GetSprintDashboardDocument }],
  });

  useEffect(() => {
    if (createSprintBulkResult.called && createSprintBulkResult.data != null) {
      afterSprintStart();
    }
  }, [createSprintBulkResult.called, createSprintBulkResult.data, onClose, afterSprintStart]);

  // this gets called after the dialog is closed
  const afterClose = useCallback(() => {
    if (createSprintBulkResult.called) {
      createSprintBulkResult.reset();
    }

    setObjective(undefined);
    setSprintDateRange([today, null]);
    afterCloseSprintDialog();
  }, [createSprintBulkResult, today, afterCloseSprintDialog]);

  const DialogHeader = ({ title }: { title: string }) => {
    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ padding: 3 }}>
          <SprintAccountSelectionOverview accounts={accounts} />
        </Box>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: afterClose }}
      maxWidth="sm"
      fullWidth
    >
      {createSprintStep === CreateSprintStep.Objective ? (
        <>
          <DialogHeader title="Choose a Playbook" />
          <DialogContent
            sx={{
              marginX: 0,
              padding: 0,
              backgroundColor: (theme) => theme.palette.magic.connectorListBackground.main,
              minHeight: '300px',
            }}
          >
            <PlaybookPicker
              key="objective-picker"
              selectedPlaybookId={objective?.id}
              playbooks={playbooks}
              onChange={(o) => {
                setObjective(o);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              data-uid={sprint$.sprintFormDialog.nextBtn}
              variant="contained"
              color="primary"
              disabled={objective == null}
              onClick={selectObjective}
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogHeader title="Start Sprint" />
          <DialogContent
            sx={{
              marginX: 0,
              paddingY: 0,
              paddingX: 2,
              backgroundColor: (theme) => theme.palette.magic.connectorListBackground.main,
              minHeight: '300px',
            }}
          >
            <Box sx={{ my: 2 }}>
              <TextField
                data-uid={sprint$.startSprintForm.titleInput}
                fullWidth
                label="Sprint name"
                variant="outlined"
                onChange={(e) => setSprintTitle(e.target.value)}
                value={sprintName}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              {/* NOTE: prefer Desktop controls to avoid e2e shenanigans */}
              {/* https://github.com/mui/mui-x/issues/4644 */}
              <DesktopDateRangePicker
                value={sprintDateRange}
                onChange={setSprintDateRange}
                shouldDisableDate={(day, pos) => pos === 'start' && !isToday(day)}
                disablePast
                maxDate={addDays(today, 14)}
                slots={{
                  day: (props: DateRangePickerDayProps<Date>) => (
                    <DateRangePickerDay
                      {...props}
                      data-uid={sprint$.startSprintForm.dateOption(props.day)}
                    />
                  ),
                }}
                slotProps={{
                  fieldSeparator: { children: 'to' },
                  textField: ({ position }) => {
                    switch (position) {
                      case 'end':
                        return {
                          inputProps: {
                            'data-uid': sprint$.startSprintForm.endDate,
                          },
                        };
                      case 'start':
                        return {
                          InputProps: {
                            disabled: true,
                          },
                          inputProps: {
                            'data-uid': sprint$.startSprintForm.startDate,
                          },
                        };
                      case undefined:
                        return {};
                      default:
                        bottom(position);
                    }
                  },
                }}
              />
            </Box>
            <Box>
              <CustomersByOwner accounts={accounts} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              data-uid={sprint$.sprintFormDialog.backBtn}
              variant="outlined"
              color="primary"
              startIcon={<NavigateBeforeIcon />}
              onClick={goBack}
            >
              Back
            </Button>
            <Button
              data-uid={sprint$.startSprintFormDialog.startSprintBtn}
              variant="contained"
              color="primary"
              endIcon={createSprintBulkResult.loading ? <CircularProgress size={24} /> : undefined}
              disabled={
                createSprintBulkResult.loading ||
                sprintDateRange[0] == null ||
                sprintDateRange[1] == null ||
                accounts == null ||
                objective == null ||
                objective.actions == null
              }
              onClick={() => {
                const [startDate, endDate] = sprintDateRange;
                if (
                  startDate != null &&
                  endDate != null &&
                  objective != null &&
                  objective.actions != null &&
                  accounts != null
                ) {
                  createSprintBulk({
                    variables: {
                      input: {
                        sprintName: sprintName,
                        playbookId: objective.id,
                        actionIds: objective.actions.map((selectedAction) => selectedAction.id),
                        accountIds: accounts.map((account) => account.id),
                        startDate: getUnixTime(startDate),
                        endDate: getUnixTime(endDate),
                      },
                    },
                  });
                }
              }}
            >
              Start Sprint
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
