import { useMutation } from '@apollo/client';
import { Star as StarIcon, StarBorder as StarBorderIcon } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useCallback } from 'react';

import {
  FollowSprintDocument,
  GetMySprintsDocument,
  GetSprintDashboardDocument,
  UnfollowSprintDocument,
} from '../../../../graphql/generated';
import { useCurrentUser } from '../../../../hooks/user';
import { ResultType } from '../../../../models/result';
import { FieldWrapper } from '../Account/FieldWrapper';
import type { SprintDashboardGridRowModel } from './types';

type FavoriteCellProps = GridRenderCellParams<SprintDashboardGridRowModel, boolean>;
export const FavoriteCell = ({ field, value, row }: FavoriteCellProps) => {
  const currentUserResult = useCurrentUser();
  const [followSprint] = useMutation(FollowSprintDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }, { query: GetSprintDashboardDocument }],
  });
  const [unfollowSprint] = useMutation(UnfollowSprintDocument, {
    refetchQueries: [{ query: GetMySprintsDocument }, { query: GetSprintDashboardDocument }],
  });

  const onToggleFavorite = useCallback(
    (isFollowing: boolean | undefined) => {
      if (isFollowing != null) {
        if (isFollowing) {
          unfollowSprint({ variables: { sprintId: row.id } });
        } else {
          followSprint({ variables: { sprintId: row.id } });
        }
      }
    },
    [followSprint, row.id, unfollowSprint],
  );

  const doesCurrentUserOwnThis =
    currentUserResult.state === ResultType.Value && currentUserResult.value.id === row.createdBy.id;

  const disabled = currentUserResult.state !== ResultType.Value || doesCurrentUserOwnThis;

  return (
    <FieldWrapper field={field}>
      {value != null && (
        <Checkbox
          disabled={disabled}
          checked={value || doesCurrentUserOwnThis}
          onChange={() => onToggleFavorite(value)}
          checkedIcon={
            <StarIcon
              color="primary"
              sx={{
                color: (theme) =>
                  disabled ? theme.palette.primary.s50p : theme.palette.primary.main,
              }}
            />
          }
          icon={<StarBorderIcon />}
        />
      )}
    </FieldWrapper>
  );
};
