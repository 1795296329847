import type { ApolloError } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';

import { PersistenceKeys } from '../contexts/persistenceContext';
import type { Result } from '../models/result';
import { ResultType } from '../models/result';
import type { UUID } from '../models/uuid';
import { isNonNull } from '../utils';
import { usePersistence } from './useLocalStorage';

interface EntityObject {
  id: UUID;
}
type EntityID = EntityObject['id'];
interface EntitySelectionProps<E> {
  entities?: E[];
  entitiesResult?: Result<E[], ApolloError>;
  persistedEntityIds?: EntityID[];
  setPersistedEntityIds?: React.Dispatch<React.SetStateAction<EntityID[]>>;
}
export const useEntitySelection = <E extends EntityObject>({
  entities,
  entitiesResult,
  persistedEntityIds,
  setPersistedEntityIds,
}: EntitySelectionProps<E>) => {
  const [localSelectedEntityIds, setLocalSelectedEntityIds] = useState<EntityID[]>([]);

  const setSelectedEntityIds = useMemo(
    () => (setPersistedEntityIds != null ? setPersistedEntityIds : setLocalSelectedEntityIds),
    [setPersistedEntityIds],
  );

  const selectableEntities = useMemo(() => {
    if (entities) {
      return entities;
    }
    if (entitiesResult && entitiesResult.state === ResultType.Value) {
      return entitiesResult.value;
    }
    return [];
  }, [entities, entitiesResult]);

  const entitiesById = useMemo(
    () =>
      selectableEntities.reduce<Record<EntityID, E>>((map, acc) => ({ ...map, [acc.id]: acc }), {}),
    [selectableEntities],
  );

  const selectedEntityIds = useMemo(() => {
    const ids = persistedEntityIds != null ? persistedEntityIds : localSelectedEntityIds;
    // if the entities changed - same filter but new operation applied which reduces the subset
    // of entities, then we need to make sure that the entity is even available for selection
    return ids.filter((id) => id in entitiesById);
  }, [entitiesById, localSelectedEntityIds, persistedEntityIds]);

  const selectedEntities = useMemo(
    () => selectedEntityIds.map((id) => entitiesById[id]).filter(isNonNull),
    [entitiesById, selectedEntityIds],
  );

  const toggleEntity = useCallback(
    (entity: E) => {
      const existingIndex = selectedEntityIds.indexOf(entity.id);
      const newEntityIds = selectedEntityIds.includes(entity.id)
        ? [
            ...selectedEntityIds.slice(0, existingIndex),
            ...selectedEntityIds.slice(existingIndex + 1),
          ]
        : [...selectedEntityIds, entity.id];
      setSelectedEntityIds(newEntityIds);
    },
    [selectedEntityIds, setSelectedEntityIds],
  );

  const toggleAllEntities = useCallback(
    (entityIds: EntityID[]) => {
      const newEntityIds = selectedEntityIds.length === entityIds.length ? [] : entityIds;
      setSelectedEntityIds(newEntityIds);
    },
    [selectedEntityIds.length, setSelectedEntityIds],
  );

  return {
    selectedEntities,
    selectedEntityIds,
    setSelectedEntityIds,
    toggleEntity,
    toggleAllEntities,
  };
};

export const useListPersistedEntitySelection = <E extends EntityObject>({
  entities,
  entitiesResult,
}: Omit<EntitySelectionProps<E>, 'persistedEntityIds' | 'setPersistedEntityIds'>) => {
  const defaultEntityIds: EntityID[] = [];
  const [persistedEntityIds, setPersistedEntityIds] = usePersistence(
    PersistenceKeys.CustomersGridRowsSelection,
    defaultEntityIds,
  );
  return useEntitySelection({
    entities,
    entitiesResult,
    persistedEntityIds,
    setPersistedEntityIds,
  });
};
