import type { DataConnectorCreateInput } from '../../../../graphql/generated';
import { DataConnectorSource } from '../../../../graphql/generated';

interface setPayload {
  type: 'set-payload';
  payload: DataConnectorCreateInput;
}

interface clearPayload {
  type: 'clear';
}

export const initialState: DataConnectorCreateInput = {
  airbyteSecretId: '',
  sourceType: DataConnectorSource.Salesforce,
};

export const dataConnectorReducer = (
  state: DataConnectorCreateInput,
  action: setPayload | clearPayload,
): DataConnectorCreateInput => {
  switch (action.type) {
    case 'set-payload':
      if (action.payload) {
        return {
          ...state,
          ...action.payload,
        };
      }
      return state;
    case 'clear':
      return {
        ...state,
        airbyteSecretId: '',
        sourceType: DataConnectorSource.Salesforce,
      };
    default:
      return state;
  }
};
