import { useMemo } from 'react';

import { useUserSettingsContext } from '../../../../contexts/userSettingsContext';
import { ActivityStatus } from '../../../../graphql/generated';
import type { Activity } from '../../../../models/sprint';
import type { UUID } from '../../../../models/uuid';

export const useSprintSummary = ({ activities }: { activities: Activity[] }) => {
  const { formatCurrencyShort } = useUserSettingsContext();

  return useMemo(() => {
    const account = activities[0]?.account;

    const activityCounts = activities.reduce(
      (acc, activity) => {
        const { assignees } = acc;
        let { totalActivities, totalCompleted, totalInProgress, totalNotStarted, totalRejected } =
          acc;
        switch (activity.status) {
          case ActivityStatus.Complete:
            totalCompleted += 1;
            break;
          case ActivityStatus.InProcess:
            totalInProgress += 1;
            break;
          case ActivityStatus.NotStarted:
            totalNotStarted += 1;
            break;
          case ActivityStatus.Rejected:
            totalRejected += 1;
            break;
        }
        if (!assignees.has(activity.assignee.id)) {
          assignees.set(activity.assignee.id, activity.assignee);
        }
        totalActivities += 1;

        return {
          totalActivities,
          totalNotStarted,
          totalInProgress,
          totalCompleted,
          totalRejected,
          assignees,
        };
      },
      {
        totalActivities: 0,
        totalNotStarted: 0,
        totalInProgress: 0,
        totalCompleted: 0,
        totalRejected: 0,
        assignees: new Map<UUID, Activity['assignee']>(),
      },
    );

    return {
      arrValue: formatCurrencyShort(account?.currentArr),
      rawArrValue: account?.currentArr,
      pipelineValue: formatCurrencyShort(account?.rawPipeline),
      rawPipelineValue: account?.rawPipeline,
      customHealthScore: account?.customHealthscore?.category,
      growthScore: account?.growth,
      riskScore: account?.risk,
      renewalDate: account?.renewalDate,
      customerLead: account?.ownerName,
      renewalLead: account?.renewalOwnerName,
      salesLead: account?.secondaryOwnerName,
      totalActivities: activityCounts.totalActivities,
      totalNotStarted: activityCounts.totalNotStarted,
      totalInProgress: activityCounts.totalInProgress,
      totalCompleted: activityCounts.totalCompleted,
      totalRejected: activityCounts.totalRejected,
      totalAssignees: activityCounts.assignees.size,
      assigneeNames: Array.from(activityCounts.assignees.values())
        .map((a) => a.name)
        .join(', '),
    };
  }, [activities, formatCurrencyShort]);
};
