import { Link, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React from 'react';

import { FieldWrapper } from '../Account/FieldWrapper';
import type { SupportCasesGridRowModel } from './types';

export type SupportCaseNameCellProps = GridRenderCellParams<SupportCasesGridRowModel, string>;
export const SupportCaseNameCell = ({ field, value, row }: SupportCaseNameCellProps) => (
  <FieldWrapper field={field}>
    {value != null ? (
      <Link href={row.caseLink} rel="noopener noreferrer" target="_blank">
        {value}
      </Link>
    ) : (
      <Typography variant="body2">&ndash;</Typography>
    )}
  </FieldWrapper>
);
