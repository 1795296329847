import { groupBy, mapValues, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useHeatmapContext } from '../contexts/heatmapContext';
import { PersistenceKeys } from '../contexts/persistenceContext';
import type { Account } from '../models/account';
import { ResultType } from '../models/result';
import type { Action, Activity } from '../models/sprint';
import { CreateSprintStep } from '../models/sprint';
import type { UUID } from '../models/uuid';
import { HeatmapVariation } from '../types/heatmap';
import { useSprint } from './sprint';
import { useAccountChartConfig } from './useAccountChartConfig';
import { useCustomerAccountSelection } from './useCustomerAccountSelection';
import { usePersistence } from './useLocalStorage';

// TODO: move to utils/models?
const sortAccountsByName = (accounts: Account[]) => orderBy(accounts, 'name');
const sortActionsByRank = (actions: Action[]) => orderBy(actions, 'rank');
const mapActivitiesToAccounts = (activities: Activity[]) =>
  sortAccountsByName(activities.map((act) => act.account));

interface UseListTableProps {
  /**
   * The id of the entity that contains this set of accounts.
   */
  id: string;
  accounts: Account[];
  /**
   * The dimensions of the heatmap.
   * @default 6
   */
  heatmapSize?: number;
}
export const useListTable = ({ id, accounts, heatmapSize = 6 }: UseListTableProps) => {
  const { xAxisMetric, yAxisMetric } = useHeatmapContext();

  const [draftSprintId, setDraftSprintId] = useState<UUID>();
  const [sprintDialogOpen, setSprintDialogOpen] = useState(false);
  const [createSprintStep, setCreateSprintStep] = useState(CreateSprintStep.Objective);

  const {
    selectedTilesGrid,
    selectedHeatmapAccounts,
    onToggleHeatmapTile,
    onToggleAllHeatmapTiles,
    newSprintAccounts,
    toggleDraftSprintAccount,
    newSprintAccountIds,
    setNewSprintAccountIds,
    highlightedTilesGrid,
    onHighlightAccount,
    onUnhighlightAccount,
  } = useCustomerAccountSelection({
    accounts: accounts,
    xAxisMetric,
    yAxisMetric,
    heatmapSize,
  });

  const draftSprintResult = useSprint(draftSprintId);

  const actionsById = useMemo(() => {
    if (draftSprintResult.state === ResultType.Value) {
      return draftSprintResult.value.activities.reduce<Record<UUID, Action>>(
        (map, activity) => ({ ...map, [activity.action.id]: activity.action }),
        {},
      );
    }
    return {};
  }, [draftSprintResult]);

  const accountsGroupedByActionId = useMemo(() => {
    if (draftSprintResult.state === ResultType.Value) {
      return mapValues(
        groupBy(draftSprintResult.value.activities, 'action.id'),
        mapActivitiesToAccounts,
      );
    }
    return {};
  }, [draftSprintResult]);

  const activityGroups = useMemo(() => {
    const sortedAccounts = sortActionsByRank(Object.values(actionsById));
    return sortedAccounts.map((element) => ({
      element,
      grouped: accountsGroupedByActionId[element.id],
    }));
  }, [accountsGroupedByActionId, actionsById]);

  const startDraftSprint = useCallback(() => setSprintDialogOpen(true), []);
  const createSprintDisabled = useMemo(
    () =>
      // you can't create a sprint if you have 0 accounts selected or you don't already have
      // a draft sprint created
      newSprintAccounts.length < 1 && draftSprintResult.state !== ResultType.Value,
    [draftSprintResult.state, newSprintAccounts.length],
  );

  const clearSprint = useCallback(() => {
    setDraftSprintId(undefined);
    // set the create sprint dialog to start over
    setCreateSprintStep(CreateSprintStep.Objective);
  }, []);

  const startSprint = useCallback(() => {
    setSprintDialogOpen(true);
  }, []);

  const closeSprintDialog = useCallback(() => setSprintDialogOpen(false), []);

  // reset any draft sprints when the active list changes
  useEffect(() => clearSprint(), [clearSprint, id]);

  const [heatmapVariation, setHeatmapVariation] = usePersistence(
    PersistenceKeys.HeatmapVariation,
    HeatmapVariation.NumAccounts,
  );

  const heatmapConfig = useAccountChartConfig({
    accounts: accounts,
    selectedTiles: selectedTilesGrid,
    highlightedTiles: highlightedTilesGrid,
    variant: heatmapVariation,
    xAxisMetric,
    yAxisMetric,
  });

  return {
    newSprintAccounts,
    draftSprintId,
    selectedHeatmapAccounts,
    sprintDialogOpen,
    createSprintStep,
    draftSprintResult,
    activityGroups,

    // selected tiles in the heatmap
    selectedTilesGrid,
    onToggleHeatmapTile,
    onToggleAllHeatmapTiles,

    // highlighted tiles in the heatmap
    highlightedTilesGrid,
    onHighlightAccount,
    onUnhighlightAccount,

    heatmapConfig,
    heatmapVariation,
    setHeatmapVariation,

    startDraftSprint,
    createSprintDisabled,
    clearSprint,
    startSprint,
    toggleDraftSprintAccount,
    closeSprintDialog,

    newSprintAccountIds,
    setNewSprintAccountIds,
  };
};
