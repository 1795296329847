import type {
  DataGridPremiumProps,
  GridColDef,
  GridInitialState,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import { StringCell, UserStatusCell } from '../components/Tables/CustomGrid';
import type { UsersGridRowModel } from '../components/Tables/CustomGrid/User/types';
import { UserStatus } from '../graphql/generated';
import { useCommonGridHelpers } from './useCommonGridHelpers';
import type { User } from './user';

type UsersGridColDef = GridColDef<UsersGridRowModel>;

const renderStringCell = (params: GridRenderCellParams<UsersGridRowModel, string>) => (
  <StringCell {...params} />
);
const renderStatusCell = (params: GridRenderCellParams<UsersGridRowModel, UserStatus>) => (
  <UserStatusCell {...params} />
);

type UsersGridProps = Pick<
  DataGridPremiumProps<User>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'initialState'
  | 'hideFooter'
  | 'localeText'
  | 'rowHeight'
>;
export const useUsersGrid = (): UsersGridProps => {
  const hideableColMap = useMemo(() => ({}), []);

  const { customNumberComparator: _unused, ...commonProps } = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'User',
  });

  const columns: UsersGridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        filterable: false,
        disableReorder: true,
        disableColumnMenu: true,
        flex: 1,
        type: 'string',
        renderCell: renderStringCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'email',
        headerName: 'Email',
        disableReorder: true,
        disableColumnMenu: true,
        flex: 3,
        type: 'string',
        renderCell: renderStringCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'status',
        headerName: 'Reef Status',
        disableReorder: true,
        disableColumnMenu: true,
        flex: 1,
        type: 'string',
        renderCell: renderStatusCell,
        groupable: true,
        aggregable: false,
      },
    ];
  }, []);

  const initialState: GridInitialState = useMemo(
    () => ({
      sorting: {
        sortModel: [{ field: 'name', sort: 'asc' }],
      },
      filter: {
        filterModel: {
          items: [
            {
              field: 'status',
              operator: 'isAnyOf',
              value: [UserStatus.Active, UserStatus.Invited],
            },
          ],
        },
      },
    }),
    [],
  );

  return {
    ...commonProps,
    initialState,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    columns,
    hideFooter: true,
    rowHeight: 42,
  };
};
