import { useMutation } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import {
  EndSprintDocument,
  GetMySprintsDocument,
  GetSprintDashboardDocument,
} from '../../../graphql/generated';
import type { Sprint } from '../../../models/sprint';

interface EndSprintDialogProps {
  open: boolean;
  onClose: () => void;
  sprint: Sprint;
}

export const EndSprintDialog = ({ open, onClose, sprint }: EndSprintDialogProps) => {
  const [endSprint, endSprintResult] = useMutation(EndSprintDocument, {
    refetchQueries: [
      // 😵 this is a _pretty_ expensive refetch
      { query: GetMySprintsDocument }, // updates the nav
      { query: GetSprintDashboardDocument }, // updates the sprint dashboard
    ],
  });

  const afterClose = useCallback(() => {
    if (endSprintResult.called) {
      endSprintResult.reset();
    }
  }, [endSprintResult]);

  useEffect(() => {
    if (endSprintResult.called && endSprintResult.data?.payload != null) {
      onClose();
    }
  }, [endSprintResult, onClose]);

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited: afterClose }}>
      <DialogTitle>End Sprint</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to end the sprint <strong>{sprint.name}</strong>?
        </DialogContentText>
        <DialogContentText>
          This will end the sprint for all participants. If ended manually, the sprint cannot be
          restarted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={endSprintResult.loading}
          endIcon={endSprintResult.loading ? <CircularProgress size={24} /> : undefined}
          onClick={() => endSprint({ variables: { sprintId: sprint.id } })}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
