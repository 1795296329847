import { HelpOutline } from '@mui/icons-material';
import type { TooltipProps } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';

import { useClientConfig } from '../../hooks/client';
import type { AccountXAxisMetric, AccountYAxisMetric } from '../../hooks/useAccountChartConfig';
import { helpLabel } from '../../hooks/useAccountChartConfig';
import { tooltip$ } from '../../selectors';

interface NullHeatmapAxisTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  field: AccountXAxisMetric | AccountYAxisMetric;
}
export const NullHeatmapAxisTooltip = ({ field, ...rest }: NullHeatmapAxisTooltipProps) => {
  const [config] = useClientConfig();
  return (
    <Tooltip
      {...rest}
      title={
        // attach uid to tooltip content to avoid collision with help icon
        <span data-uid={tooltip$.nullHeatmapAxisTooltip}>
          <Typography>
            We were unable to find or generate {helpLabel(field, config)} value for these accounts.
            Either a value is not applicable to these accounts, or there may be data missing.
          </Typography>
        </span>
      }
    >
      <HelpOutline data-uid={tooltip$.nullHeatmapAxisTooltipIcon} fontSize="small" />
    </Tooltip>
  );
};
