import type {
  DataGridPremiumProps,
  GridColTypeDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import { DateCell, MultiCurrencyCell } from '../components/Tables/CustomGrid';
import { OpportunityNameCell } from '../components/Tables/CustomGrid/Opportunity';
import type { OpportunitiesGridRowModel } from '../components/Tables/CustomGrid/Opportunity/types';
import type { OpportunityDetails } from '../models/opportunity';
import { useCommonGridHelpers } from './useCommonGridHelpers';

interface ContractGridColDef extends GridColTypeDef {
  field: keyof OpportunitiesGridRowModel;
}

const renderMultiCurrencyCell = (
  params: GridRenderCellParams<OpportunitiesGridRowModel, number>,
) => <MultiCurrencyCell {...params} />;
const renderDateCell = (params: GridRenderCellParams<OpportunitiesGridRowModel, Date>) => (
  <DateCell {...params} />
);
const renderNameCell = (params: GridRenderCellParams<OpportunitiesGridRowModel, string>) => (
  <OpportunityNameCell {...params} />
);

export interface UseOpportunitiesGridProps {
  opportunities: OpportunityDetails[];
}
type OpportunitiesGridProps = Pick<
  DataGridPremiumProps<OpportunityDetails>,
  | 'apiRef'
  | 'checkboxSelection'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'columns'
  | 'columnVisibilityModel'
  | 'onColumnVisibilityModelChange'
  | 'localeText'
  | 'rowHeight'
  | 'rows'
>;
export const useOpportunitiesGrid = ({
  opportunities,
}: UseOpportunitiesGridProps): OpportunitiesGridProps => {
  const hideableColMap = useMemo(() => ({}), []);

  const commonProps = useCommonGridHelpers({
    hideableColMap,
    localeEntity: 'Opportunity',
    localeEntities: 'Opportunities',
  });

  const columns = useMemo((): ContractGridColDef[] => {
    return [
      {
        field: 'opportunityName',
        headerName: 'Expansion Opportunity',
        hideable: false,
        flex: 2,
        type: 'string',
        renderCell: renderNameCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'scheduledCloseDate',
        headerName: 'Close Date',
        flex: 1,
        type: 'dateTime',
        renderCell: renderDateCell,
        groupable: false,
        aggregable: false,
      },
      {
        field: 'opportunityStage',
        headerName: 'Opportunity Stage',
        hideable: false,
        flex: 1.5,
        type: 'string',
        groupable: true,
        aggregable: false,
      },
      {
        field: 'totalOpportunityValue',
        headerName: 'Total Opportunity Value',
        renderCell: renderMultiCurrencyCell,
        flex: 1.5,
        type: 'number',
        groupable: false,
        aggregable: true,
      },
    ];
  }, []);

  const initialState: OpportunitiesGridProps['initialState'] = useMemo(
    () => ({
      // skip default pinning due to flex column sizing
      pinnedColumns: { left: [] },
      sorting: {
        sortModel: [],
      },
    }),
    [],
  );

  return {
    ...commonProps,
    checkboxSelection: false,
    disableRowSelectionOnClick: true,
    rowHeight: 42,
    initialState,
    columns,
    rows: opportunities,
  };
};
