import type {
  DefaultLink,
  DefaultNode,
  SankeyLinkDatum,
  SankeyNodeDatum,
  SankeySvgProps,
} from '@nivo/sankey';
import { BasicTooltip, Chip } from '@nivo/tooltip';
import type { Dictionary } from 'lodash';
import { groupBy, mapValues, orderBy, pick, reduce, sum, sumBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { MISSING_COLOR, RENEWAL_ANALYTICS_COLORS } from '../components/Charts/Analytics/utils';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import { bottom, NUMBER_FORMATTER, WHOLE_PERCENT_NUMBER_FORMATTER } from '../utils';
import type { Renewal } from './renewal';
import { RenewalStatus } from './renewal';

export type RenewalScoreField = keyof Pick<
  Renewal,
  | 'customHealthCategoryCurrent'
  | 'customHealthCategory3mo'
  | 'customHealthCategory6mo'
  | 'customHealthCategory9mo'
  | 'reefRenewalCurrent'
  | 'reefRenewal3mo'
  | 'reefRenewal6mo'
  | 'reefRenewal9mo'
>;
export const RENEWAL_SCORE_FIELDS: Readonly<Array<RenewalScoreField>> = [
  'customHealthCategoryCurrent',
  'customHealthCategory3mo',
  'customHealthCategory6mo',
  'customHealthCategory9mo',
  'reefRenewalCurrent',
  'reefRenewal3mo',
  'reefRenewal6mo',
  'reefRenewal9mo',
] as const;
export type OutcomesWeightingField =
  | keyof Pick<Renewal, 'arrExpiringTotal' | 'netChurn' | 'netUpsell'>
  | 'count';
export const OUTCOMES_WEIGHTING_FIELDS: Readonly<Array<OutcomesWeightingField>> = [
  'arrExpiringTotal',
  'count',
  'netChurn',
  'netUpsell',
] as const;
interface UseOutcomesByScoreChartConfigProps {
  renewals: Renewal[];
  scoreField: RenewalScoreField;
  weightingField: OutcomesWeightingField;
}
type WeightingTotals = Record<OutcomesWeightingField, number | undefined>;
export interface OutcomeByScoreNode extends DefaultNode {}
export interface OutcomeByScoreLink extends DefaultLink, WeightingTotals {}
type ResponsiveSankeyProps = Omit<
  SankeySvgProps<OutcomeByScoreNode, OutcomeByScoreLink>,
  'width' | 'height'
>;

// TODO: update coloring scheme
const NODE_COLORS: Dictionary<string> = {
  Red: RENEWAL_ANALYTICS_COLORS.Healthscore.Red,
  Yellow: RENEWAL_ANALYTICS_COLORS.Healthscore.Yellow,
  Green: RENEWAL_ANALYTICS_COLORS.Healthscore.Green,
  Won: RENEWAL_ANALYTICS_COLORS.Outcome.Won,
  Lost: RENEWAL_ANALYTICS_COLORS.Outcome.Lost,
  Unknown: RENEWAL_ANALYTICS_COLORS.Outcome.Unknown,
  null: RENEWAL_ANALYTICS_COLORS.Healthscore.null,
  'No Renewal Found': RENEWAL_ANALYTICS_COLORS.Outcome.NoRenewalFound,
  Active: RENEWAL_ANALYTICS_COLORS.Outcome.Active,
};

const OMITTED_STATUSES: Set<string> = new Set([
  RenewalStatus.Active,
  RenewalStatus.NoRenewalFound,
  RenewalStatus.Unknown,
]);
const OMITTED_SCORES: Set<string> = new Set([]);

interface SankeyNodeTooltipProps {
  node: SankeyNodeDatum<OutcomeByScoreNode, OutcomeByScoreLink>;
}
const linkTooltipStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  sourceChip: {
    marginRight: 7,
  },
  targetChip: {
    marginLeft: 7,
    marginRight: 7,
  },
};
interface SankeyLinkTooltipProps {
  link: SankeyLinkDatum<OutcomeByScoreNode, OutcomeByScoreLink>;
}

/**
 * Consistent renewals-grouped-by-field, ordered first.
 * @param renewals Renewal[] to group
 * @param field string to group `renewals` by
 * @returns Dictionary<Renewal[]> of ordered-then-grouped Renewals
 */
const groupRenewalsByField = (renewals: Renewal[], field: keyof Renewal): Dictionary<Renewal[]> =>
  groupBy(orderBy(renewals, field), field);
const statusField: keyof Renewal = 'status';
/**
 * Consistent links-grouped-by-field, ordered first.
 * @param links OutcomeByScoreLink[] to group
 * @param field 'source' | 'target' to group `links` by
 * @returns Dictionary<OutcomeByScoreLink[]> of ordered-then-grouped links
 */
const buildWeightingTotalsFromLinks = (links: OutcomeByScoreLink[], field: 'source' | 'target') =>
  mapValues(
    groupBy(orderBy(links, field), field),
    (sourceLinks): WeightingTotals =>
      sourceLinks.reduce<WeightingTotals>(
        (acc, sourceLink): WeightingTotals => ({
          arrExpiringTotal: sum([acc.arrExpiringTotal, sourceLink.arrExpiringTotal]),
          count: sum([acc.count, sourceLink.count]),
          netChurn: sum([acc.netChurn, sourceLink.netChurn]),
          netUpsell: sum([acc.netUpsell, sourceLink.netUpsell]),
        }),
        {
          arrExpiringTotal: undefined,
          count: undefined,
          netChurn: undefined,
          netUpsell: undefined,
        },
      ),
  );

export const useOutcomesByScoreChartConfig = ({
  renewals,
  scoreField,
  weightingField,
}: UseOutcomesByScoreChartConfigProps): ResponsiveSankeyProps => {
  const { formatCurrencyShort } = useUserSettingsContext();
  const groupedRenewals = useMemo(
    () => groupRenewalsByField(renewals, scoreField),
    [renewals, scoreField],
  );
  const getWeighting = useCallback(
    (renewals: Renewal[], weightingField: OutcomesWeightingField): number => {
      switch (weightingField) {
        case 'arrExpiringTotal':
        case 'netChurn':
        case 'netUpsell':
          return sumBy(renewals, weightingField);
        case 'count':
          return renewals.length;
        default:
          bottom(weightingField);
      }
    },
    [],
  );
  const getWeightingTotals = useCallback(
    (renewals: Renewal[]): WeightingTotals => ({
      arrExpiringTotal: getWeighting(renewals, 'arrExpiringTotal'),
      count: getWeighting(renewals, 'count'),
      netChurn: getWeighting(renewals, 'netChurn'),
      netUpsell: getWeighting(renewals, 'netUpsell'),
    }),
    [getWeighting],
  );
  const processedData = useMemo(() => {
    const links: OutcomeByScoreLink[] = [];
    const nodesSet: Set<string> = new Set();
    const rawData = mapValues(groupedRenewals, (renewals, scoreFieldValue) => {
      const statusBreakdown = groupRenewalsByField(renewals, statusField);
      links.push(
        ...reduce<Dictionary<Renewal[]>, OutcomeByScoreLink[]>(
          statusBreakdown,
          (acc, renewals, status) => {
            if (OMITTED_SCORES.has(scoreFieldValue) || OMITTED_STATUSES.has(status)) {
              return [...acc];
            }
            nodesSet.add(scoreFieldValue);
            nodesSet.add(status);
            const weightingTotals = getWeightingTotals(renewals);
            return [
              ...acc,
              {
                source: scoreFieldValue,
                target: status,
                ...weightingTotals,
                value: weightingTotals[weightingField] ?? NaN,
              },
            ];
          },
          [],
        ),
      );
      return statusBreakdown;
    });
    const sourceWeightingTotals = buildWeightingTotalsFromLinks(links, 'source');
    const targetWeightingTotals = buildWeightingTotalsFromLinks(links, 'target');
    const groupedNodeData = Object.assign({}, sourceWeightingTotals, targetWeightingTotals);
    return {
      rawData,
      groupedNodeData,
      // NOTE: a single node is required to prevent chart 💥 on empty data
      nodes: nodesSet.size > 0 ? Array.from(nodesSet).map((n) => ({ id: n })) : [{ id: 'N/A' }],
      links,
    };
  }, [getWeightingTotals, groupedRenewals, weightingField]);

  const data: ResponsiveSankeyProps['data'] = useMemo(
    () => pick(processedData, ['nodes', 'links']),
    [processedData],
  );

  /**
   * d3-format-compatible value for chart display.
   */
  const valueFormat = useMemo(() => {
    switch (weightingField) {
      case 'arrExpiringTotal':
      case 'netChurn':
      case 'netUpsell':
        return '>-$,.0f';
      case 'count':
        return '>-,';
      default:
        return bottom(weightingField);
    }
  }, [weightingField]);

  /**
   * Customize node tooltip with given weighting selection.
   */
  const nodeTooltip = useCallback(
    ({ node }: SankeyNodeTooltipProps) => {
      const nodeWeightingTotals = processedData.groupedNodeData[node.label];
      const count =
        weightingField !== 'count' && nodeWeightingTotals?.count != null
          ? NUMBER_FORMATTER.format(nodeWeightingTotals.count)
          : null;
      const formattedValue =
        weightingField !== 'count' ? formatCurrencyShort(node.value) : node.formattedValue;
      return (
        <BasicTooltip
          id={node.label}
          enableChip={true}
          color={node.color}
          value={`${formattedValue}${count != null ? ` (${count})` : ''}`}
        />
      );
    },
    [formatCurrencyShort, processedData.groupedNodeData, weightingField],
  );
  /**
   * Customize link tooltips with given weighting selections & composition calculations.
   */
  const linkTooltip = useCallback(
    ({ link }: SankeyLinkTooltipProps) => {
      const sourceComposition = WHOLE_PERCENT_NUMBER_FORMATTER.format(
        link.value / (link.source.value || 1),
      );
      const targetComposition = WHOLE_PERCENT_NUMBER_FORMATTER.format(
        link.value / (link.target.value || 1),
      );
      const count =
        weightingField !== 'count' && link?.count != null
          ? NUMBER_FORMATTER.format(link.count)
          : null;
      const formattedValue =
        weightingField !== 'count' ? formatCurrencyShort(link.value) : link.formattedValue;
      return (
        <BasicTooltip
          id={
            <span style={linkTooltipStyles.container}>
              <Chip color={link.source.color} style={linkTooltipStyles.sourceChip} />
              {`${link.source.label} (${sourceComposition}) > ${link.target.label} (${targetComposition})`}
              <Chip color={link.target.color} style={linkTooltipStyles.targetChip} />
              <strong>{`${formattedValue}${count != null ? ` (${count})` : ''}`}</strong>
            </span>
          }
        />
      );
    },
    [formatCurrencyShort, weightingField],
  );

  return {
    data,
    sort: 'input',
    margin: { top: 40, right: 40, bottom: 40, left: 40 },
    align: 'justify',
    colors: (node) => NODE_COLORS[node.id] ?? MISSING_COLOR,
    nodeOpacity: 1,
    nodeHoverOthersOpacity: 0.35,
    nodeThickness: 36,
    nodeSpacing: 24,
    nodeBorderWidth: 0,
    nodeBorderColor: {
      from: 'color',
      modifiers: [['darker', 0.8]],
    },
    valueFormat,
    nodeBorderRadius: 3,
    linkOpacity: 0.5,
    linkHoverOthersOpacity: 0.1,
    linkContract: 3,
    enableLinkGradient: true,
    linkTooltip,
    nodeTooltip,
    labelPosition: 'outside',
    labelOrientation: 'vertical',
    labelPadding: 16,
    labelTextColor: {
      from: 'color',
      modifiers: [['darker', 1]],
    },
    theme: {
      text: {
        fontFamily: 'Inter',
      },
      tooltip: {
        basic: {
          fontFamily: 'Inter',
          fontWeight: 300,
        },
      },
    },
  };
};
