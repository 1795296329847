import { kebabCase } from 'lodash';

import type { ObjValues } from '../types/util';

const createActionBtn = (index: number) => `playbooks--create-action--btn-${index}`;
const deleteActionLinkBtn = (index: number) => `delete-action-link-${index}`;
const playbookTitle = (title: string) => `playbook-title--${kebabCase(title.toLowerCase())}`;
const addPlaybookBtn = 'add-playbook-btn';
const noResults = 'no-playbook-search-results';
const playbooksContent = 'playbooks-content';
const playbookSearch = 'manage-playbooks-search';
const playbookSearchInput = 'manage-playbooks-search-input';

const createPlaybookTitle = 'create-playbook--title';
const createPlaybookTitleInput = 'create-playbook--title--input';
const createPlaybookCancelBtn = 'create-playbook--cancel-btn';
const createPlaybookSaveBtn = 'create-playbook--save-btn';

const createActionTitle = 'create-action--title';
const createActionTitleInput = 'create-action--title--input';
const createActionCancelBtn = 'create-action--cancel-btn';
const createActionSaveBtn = 'create-action--save-btn';

const playbooks = {
  createActionBtn,
  deleteActionLinkBtn,
  playbookTitle,
  addPlaybookBtn,
  noResults,
  playbookSearch,
  playbooksContent,
  playbookSearchInput,
  createPlaybookTitle,
  createPlaybookTitleInput,
  createPlaybookCancelBtn,
  createPlaybookSaveBtn,
  createActionTitle,
  createActionTitleInput,
  createActionCancelBtn,
  createActionSaveBtn,
} as const;

export const manage$ = {
  playbooks,
};

export type Manage$Values = ObjValues<typeof playbooks>;
