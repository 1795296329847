import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import type { ReactElement } from 'react';
import React from 'react';

import { profile$ } from '../../../../selectors/profile';

type UserFields = 'name' | 'auth' | 'currency' | 'region' | 'demo' | 'impersonation';

const FIELD_LABELS = {
  name: 'Name',
  auth: 'Sign In Method',
  currency: 'Display Currency',
  region: 'Customer Region',
  demo: 'Demo Mode',
  impersonation: 'Impersonation',
} as const satisfies Record<UserFields, string>;

interface UserFieldProps {
  field: UserFields;
  editableFieldComponent?: ReactElement;
  value: string;
  isInEditMode?: boolean;
  loading?: boolean;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
}
export const UserField = ({
  field,
  value,
  editableFieldComponent,
  isInEditMode = false,
  loading = false,
  onEdit,
  onSave,
  onCancel,
}: UserFieldProps) => {
  return (
    <Paper elevation={0} sx={{ p: '0.75rem' }}>
      <Stack
        data-uid={profile$.userField(field)}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        sx={{ width: '100%' }}
      >
        <Box width={'15%'}>
          <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
            {FIELD_LABELS[field]}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', textAlign: 'left' }}>
          {isInEditMode ? editableFieldComponent : <Typography variant="body2">{value}</Typography>}
        </Box>
        <Box>
          {isInEditMode ? (
            <Stack direction="row" gap={1}>
              <LoadingButton
                data-uid={profile$.saveBtn(field)}
                color="primary"
                variant="contained"
                loading={loading}
                onClick={onSave}
              >
                Save
              </LoadingButton>
              {onCancel != null ? (
                <Button
                  data-uid={profile$.cancelBtn(field)}
                  size="small"
                  color="primary"
                  variant="text"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              ) : null}
            </Stack>
          ) : (
            <LoadingButton
              data-uid={profile$.editBtn(field)}
              loading={loading}
              variant="text"
              startIcon={<Edit />}
              onClick={onEdit}
              disabled={!editableFieldComponent}
            >
              Edit
            </LoadingButton>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};
