import type { SxProps } from '@mui/material';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useTransition } from 'react';

import { heatmap$ } from '../../../selectors';
import { HeatmapTile } from './HeatmapTile';
import { getCornerVariantFromMap, getTileVariantFromTile } from './heatmapUtils';

export interface ChartDataItem {
  /**
   * Key for the data item.
   */
  id: string;
  /**
   * A singular value to display on the chart.
   */
  displayValue: string | undefined;
  /**
   * A meta value about the chart data-point. For a heatmap, this would be the
   * tile's "heat" value that's a range from 0-255 to determine the color on the tile
   */
  metaValue: number;
  /**
   * Tooltip data to be shown when mouseover of the data item.
   */
  tooltipContent: React.ReactNode;
  /**
   * Whether or not this data item is selected.
   */
  selected: boolean;
  /**
   * Whether or not this data item is highlighted.
   */
  highlighted: boolean;
  /**
   * If true, this data item represents a null value.
   */
  hasNullValue: boolean;
}

export interface HeatmapAxisConfig {
  field: string;
  label: string;
  /**
   * A collection of ticks to display in the gaps on the heatmap.
   * There is _one_ extra tick so it can sit on the gap at the **end** of the heatmap.
   */
  ticks: Readonly<Array<string | JSX.Element>>;
  containerSx: SxProps;
}

interface HeatmapProps {
  /**
   * X-Axis attributes for axis display and configuration.
   */
  xAxis: HeatmapAxisConfig;
  /**
   * Y-Axis attributes for axis display and configuration.
   */
  yAxis: HeatmapAxisConfig;
  /**
   * A tuple of chart data items.
   */
  data: ChartDataItem[][];
  /**
   * Called when a tile is selected.
   * @param coords is a tuple of the `[x, y]` coordinates
   */
  onSelectTile: (coords: [number, number]) => void;
  /**
   * Called when the select all checkbox is toggled.
   * @param isEveryTileSelected will be true if every tile in the heatmap is selected when the toggle is clicked
   */
  onToggleAllTiles: (isEveryTileSelected: boolean) => void;
}

export const Heatmap = ({ xAxis, yAxis, data, onSelectTile, onToggleAllTiles }: HeatmapProps) => {
  // if there are a ton of accounts to render clicking "select all" can be really costly
  // so we can do a `useTransition` here to defer the state changes
  const [isSelectAllLoading, startSelectAll] = useTransition();

  /**
   * Function to build a click handler. Accepts a coords parameter with [x, y] order.
   */
  const onClickFactory = useCallback(
    ([x, y]: [number, number]) =>
      () =>
        onSelectTile([x, y]),
    [onSelectTile],
  );

  const isAnyTileSelected = useMemo(
    () => data.some((row) => row.some(({ selected }) => selected)),
    [data],
  );

  const isEveryTileSelected = useMemo(
    () => data.every((row) => row.every(({ selected }) => selected)),
    [data],
  );

  const getYAxisTickStyles = useCallback(
    (tick: React.ReactNode) =>
      typeof tick === 'string'
        ? {
            whiteSpace: 'nowrap',
            writingMode: 'vertical-lr', // unsupported in IE
            translate: '-3px', // handcrafted placement, revisit for new y-axis values
          }
        : {},
    [],
  );

  return (
    <Box>
      <Box
        data-uid={heatmap$.heatmapComponent}
        sx={{
          display: 'grid',
          gridTemplateAreas:
            "'. x-axis-annotation .' 'y-axis heatmap y-axis-annotation' '. x-axis .'",
          gridTemplateColumns: 'fit-content(1rem) 1fr 1rem', // tighten RHS spacing
          gridTemplateRows: 'fit-content(1rem) 1fr fit-content(1rem)',
          gap: 1,
        }}
      >
        <Box
          sx={{
            gridArea: 'x-axis-annotation',
            display: 'grid',
            gridTemplateColumns: `repeat(${xAxis.ticks.length}, 1fr)`,
            gap: '1px',
            color: (theme) => theme.palette.text.disabled,
            marginBottom: '-6px', // pull ticks closer to grid tiles
          }}
        >
          {xAxis.ticks.map((tick, i) => (
            <Box key={`x-axis-label-${i}`} sx={{ mt: 'auto', mx: 'auto', display: 'flex' }}>
              {(typeof tick === 'string' && (
                // unset line-height to pull ticks closer to grid tiles
                <Typography variant="caption" sx={{ mt: 'auto', lineHeight: 'unset' }}>
                  {tick}
                </Typography>
              )) ||
                tick}
            </Box>
          ))}
        </Box>
        <Box
          // 🙈 this left axis is so hacky and bad, but I don't know a better way to do it
          // honestly material isn't cut out to build a data vis utility so I think
          // we should explore something else at some point
          sx={{
            gridArea: 'y-axis',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // adds a small arrow token using some borders to the top of the axis
            '&:before': {
              content: '""',
              display: 'inline-block',
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              borderBottom: (theme) => `4px solid ${theme.palette.text.disabled}`,
              width: 0,
              height: 0,
              position: 'absolute',
              mt: '-2px',
            },
          }}
        >
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              width: 8,
              height: '100%',
              mx: 'auto',
              '&:before': { borderColor: (theme) => theme.palette.text.disabled },
              '&:after': { borderColor: (theme) => theme.palette.text.disabled },
              '& .MuiDivider-wrapperVertical': {
                mt: 8,
                pl: '5px',
              },
            }}
          >
            <Box
              sx={{
                transform: 'rotate(-90deg) translate(100%)',
                ...yAxis.containerSx,
              }}
            >
              <Typography variant="caption">{yAxis.label.toUpperCase()}</Typography>
            </Box>
          </Divider>
        </Box>
        <Box sx={{ gridArea: 'heatmap' }}>
          <Box
            sx={{
              display: 'grid',
              // get the number of columns in the grid
              gridTemplateColumns: `repeat(${Math.min(...data.map((r) => r.length))}, 1fr)`,
              // get the number of rows in the grid
              gridTemplateRows: `repeat(${data.length}, 1fr)`,
              gap: '1px',
            }}
          >
            {data
              .map((row, yIndex) => (
                <React.Fragment key={`customer-heatmap-row-${yIndex}`}>
                  {row.map((tile, xIndex) => (
                    <React.Fragment key={`customer-heatmap-tile-${xIndex}-${yIndex}`}>
                      <HeatmapTile
                        col={xIndex}
                        row={yIndex}
                        roundedCorner={getCornerVariantFromMap({
                          col: xIndex,
                          maxCol: xAxis.ticks.length - 1,
                          row: yIndex,
                          maxRow: yAxis.ticks.length - 1,
                        })}
                        variant={getTileVariantFromTile(tile)}
                        displayValue={tile.displayValue}
                        heatValue={tile.metaValue}
                        tooltipContent={<>{tile.tooltipContent}</>}
                        onClick={onClickFactory([xIndex, yIndex])}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))
              // the [0, 0] coord of the heatmap is in the bottom left so we want to reverse
              // the rows because the grid is drawn from top-to-bottom
              .reverse()}
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'y-axis-annotation',
            display: 'grid',
            gridTemplateRows: `repeat(${yAxis.ticks.length}, 1fr)`,
            gap: '1px',
            marginLeft: '-6px', // pull ticks closer to grid tiles
          }}
        >
          {yAxis.ticks
            .map((tick, i) => (
              <Box
                key={`y-axis-label-${i}`}
                sx={{
                  mr: 'auto',
                  my: 'auto',
                  color: (theme) => theme.palette.text.disabled,
                  ...getYAxisTickStyles(tick),
                }}
              >
                <Typography variant="caption">{tick}</Typography>
              </Box>
            ))
            .reverse()}
        </Box>
        <Box
          sx={{
            gridArea: 'x-axis',
            display: 'flex',
            // centers the border and the arrow together
            alignItems: 'center',
            // 🤡 this renders a small arrow pointing to the right for the axis
            '&:after': {
              content: '""',
              display: 'inline-block',
              borderLeft: (theme) => `4px solid ${theme.palette.text.disabled}`,
              borderTop: '4px solid transparent',
              borderBottom: '4px solid transparent',
              width: 0,
              height: 0,
            },
            height: 8, // sync'd with y-axis width
          }}
        >
          <Divider
            sx={{
              width: '100%',
              mx: 'auto',
              // we want the axis color to be darker
              '&:before': { borderColor: (theme) => theme.palette.text.disabled },
              '&:after': { borderColor: (theme) => theme.palette.text.disabled },
            }}
          >
            <Typography variant="caption">{xAxis.label.toUpperCase()}</Typography>
          </Divider>
        </Box>
      </Box>
      <FormGroup>
        <FormControlLabel
          sx={{ mx: 'unset', ml: 'auto' }}
          control={
            <Checkbox
              data-uid={heatmap$.selectAllTiles}
              // if we are in the middle of a long state transition, lets show a spinner
              // this could happen if there are a lot of accounts and the UI has a lot of
              // rerendering happening
              icon={isSelectAllLoading ? <CircularProgress size={24} /> : undefined}
              checkedIcon={isSelectAllLoading ? <CircularProgress size={24} /> : undefined}
              disabled={isSelectAllLoading}
              checked={isAnyTileSelected}
              indeterminate={isAnyTileSelected && !isEveryTileSelected}
              onChange={() => startSelectAll(() => onToggleAllTiles(isEveryTileSelected))}
            />
          }
          label={
            <Typography variant="caption" fontSize={14.4}>
              Select all
            </Typography>
          }
          labelPlacement="start"
        />
      </FormGroup>
    </Box>
  );
};
