import { Card, CardContent, CardHeader, Divider, Stack } from '@mui/material';
import type { Grid2Props } from '@mui/material/Unstable_Grid2';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { useCustomerCases } from '../../../../hooks/account';
import { useSupportCasesGrid } from '../../../../hooks/useSupportCasesGrid';
import { ResultType } from '../../../../models/result';
import type { UUID } from '../../../../models/uuid';
import { OverviewFieldItem } from '../../../OverviewField';
import { CustomGrid } from '../../../Tables/CustomGrid';

const FieldGrid = ({ children, ...props }: PropsWithChildren<Grid2Props>) => (
  <Grid display="flex" justifyContent="left" alignItems="center" xs={3} sm={4} md={6} {...props}>
    {children}
  </Grid>
);

export const CustomerSupportCases = () => {
  const { accountId } = useParams();
  const supportCasesResult = useCustomerCases(accountId as UUID | undefined);
  const loading = useMemo(
    () => supportCasesResult.state === ResultType.Loading,
    [supportCasesResult.state],
  );
  const supportCaseDetails = useMemo(
    () => (supportCasesResult.state === ResultType.Value ? supportCasesResult.value : null),
    [supportCasesResult.state, supportCasesResult.value],
  );

  const allSupportCases = useMemo(() => {
    if (supportCaseDetails == null || supportCaseDetails.cases == null) {
      return [];
    }
    return supportCaseDetails.cases;
  }, [supportCaseDetails]);

  const supportCasesGridProps = useSupportCasesGrid({ supportCases: allSupportCases });

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Card variant="outlined">
        <CardHeader
          title="Support Summary"
          titleTypographyProps={{ variant: 'body1', fontWeight: 500, fontSize: 14 }}
          sx={{ py: 1 }}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            rowSpacing={{ xs: 1.5, md: 2 }}
            spacing={{ xs: 2, md: 2.5 }}
            columns={{ xs: 6, sm: 8, md: 12 }}
          >
            <FieldGrid>
              <OverviewFieldItem field="supportCaseCount" details={supportCaseDetails} />
            </FieldGrid>
            <FieldGrid>
              <OverviewFieldItem field="supportCaseOpenDuration" details={supportCaseDetails} />
            </FieldGrid>
            <FieldGrid>
              <OverviewFieldItem field="supportCaseClosedDuration" details={supportCaseDetails} />
            </FieldGrid>
            <FieldGrid>
              <OverviewFieldItem field="supportEngagement" details={supportCaseDetails} />
            </FieldGrid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        elevation={0}
        sx={{
          '.support-cases-grid--cell--closed': {
            color: (theme) => theme.palette.text.disabled,
          },
        }}
      >
        <CustomGrid
          loading={loading}
          GridWrapperBoxProps={{
            outer: {
              sx: {
                height: 'calc(100vh - 20.3rem)',
                maxWidth: 'calc(100vw - 31.5rem)',
              },
            },
          }}
          {...supportCasesGridProps}
        />
      </Card>
    </Stack>
  );
};
