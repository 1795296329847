import type { ChipProps } from '@mui/material';
import { Chip, Stack, Typography } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useMemo } from 'react';

import { SprintStatus } from '../../../../graphql/generated';
import { PERCENT_NUMBER_FORMATTER } from '../../../../utils';
import { FieldWrapper } from '../Account/FieldWrapper';
import type { SprintDashboardGridRowModel } from './types';

type SprintStatusCellProps = GridRenderCellParams<SprintDashboardGridRowModel, SprintStatus>;
export const SprintStatusCell = ({ field, value, row }: SprintStatusCellProps) => {
  const label =
    row.completionPercentage != null
      ? PERCENT_NUMBER_FORMATTER.format(row.completionPercentage)
      : '–%';
  const [chipProps, statusLabel]: [Pick<ChipProps, 'icon' | 'label' | 'color'>, string] =
    useMemo(() => {
      switch (value) {
        case SprintStatus.Active:
          return [{ label, color: 'default' }, 'In Progress'];
        case SprintStatus.Complete:
          return [{ label, color: 'primary' }, 'Ended'];
        case SprintStatus.Draft:
          return [{ label, color: 'default' }, 'Draft'];
        default:
          return [{ label, color: 'error' }, 'UNKNOWN-SPRINT-STATUS'];
      }
    }, [label, value]);
  return (
    <FieldWrapper field={field}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        flexGrow={1}
      >
        <Typography variant="body2">{statusLabel}</Typography>
        {value != null ? (
          <Chip {...chipProps} size="small" variant="filled" />
        ) : (
          <Typography variant="body2">&ndash;</Typography>
        )}
      </Stack>
    </FieldWrapper>
  );
};
