import type { SxProps } from '@mui/material';
import { Box, Chip, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import type { ChartDataItem, HeatmapAxisConfig } from '../components/Charts/Heatmap';
import { ScoreQualityChip } from '../components/ScoreChip';
import { NullHeatmapAxisTooltip } from '../components/Tooltips';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type { Account } from '../models/account';
import type { HeatmapClientConfig } from '../models/client';
import { heatmap$ } from '../selectors';
import { HeatmapVariation } from '../types/heatmap';
import { bottom } from '../utils';
import { useClientConfig } from './client';

/**
 * X-Axis fields of an Account, used to plot on Customer Heatmap.
 */
export type AccountXAxisMetricOptional = keyof Pick<Account, 'risk' | 'growth'>;
export type AccountXAxisMetric = keyof Pick<
  Account,
  | 'engagement'
  | 'customHealthscore'
  | 'productEngagement'
  | 'supportEngagement'
  | 'productConsumptionPercent'
  | AccountXAxisMetricOptional
>;
/**
 * Y-Axis fields of an Account, used to plot on Customer Heatmap.
 */
export type AccountYAxisMetric = keyof Pick<Account, 'potential' | 'customerRevenueBand'>;

/**
 * Helper for constructing axis ticks from given metric field
 * @param field X-Axis or Y-Axis heatmap Account metric
 * @param config heatmap client config for labeling
 * @returns list of ReactNode values to render as ticks
 */
export const ticks = (
  field: AccountXAxisMetric | AccountYAxisMetric,
  config: HeatmapClientConfig,
): Readonly<Array<string | JSX.Element>> => {
  const nullTick = <NullHeatmapAxisTooltip key={`${field}-axis-nulls`} field={field} />;
  switch (field) {
    case 'customerRevenueBand':
    case 'customHealthscore':
    case 'risk':
    case 'growth':
      return config.buckets[field].labels.map((label) => {
        if (label.toLowerCase() === 'null') {
          return nullTick;
        }
        return label;
      });
    case 'engagement':
    case 'productEngagement':
    case 'supportEngagement':
      return [nullTick, '0-19', '20-39', '40-59', '60-79', '80-100'] as const;
    case 'productConsumptionPercent':
      return [nullTick, '0-24%', '25-49%', '50-74%', '75-99%', '100+%'] as const;
    case 'potential':
      return [nullTick, '19-0', '39-20', '59-40', '79-60', '100-80'] as const;
    default:
      bottom(field);
  }
};

const containerSxProps = (field: AccountXAxisMetric | AccountYAxisMetric): SxProps => {
  switch (field) {
    case 'potential':
      return {
        transform: 'rotate(-90deg) translate(100%) translateY(-1px) translateX(0px)',
      };
    case 'customerRevenueBand':
      return {
        transform: 'rotate(-90deg) translate(100%) translateY(-1px) translateX(-13px)',
      };
    default:
      return {};
  }
};

/**
 * Constructs a sentence fragment describing the given field as a metric
 * @param field account metric field
 * @param config heatmap client config for labeling
 * @returns sentence fragment node
 */
export const helpLabel = (
  field: AccountXAxisMetric | AccountYAxisMetric,
  config: HeatmapClientConfig,
): React.ReactNode => {
  switch (field) {
    case 'engagement':
      return (
        <>
          an <strong>{metricLabel(field, config)}</strong>
        </>
      );
    default:
      return (
        <>
          a <strong>{metricLabel(field, config)}</strong>
        </>
      );
  }
};

/**
 * Helper for constructing given field's display label.
 * @param field X-Axis or Y-Axis heatmap Account metric
 * @param config heatmap client config for labeling
 * @returns string display name
 */
export const metricLabel = (
  field: AccountXAxisMetric | AccountYAxisMetric,
  config: HeatmapClientConfig,
): string => {
  switch (field) {
    case 'customHealthscore':
      return config.buckets.customHealthscore.metricLabel ?? 'Healthscore';
    case 'risk':
      return config.buckets.risk.metricLabel ?? 'Risk Score';
    case 'growth':
      return config.buckets.growth.metricLabel ?? 'Growth Score';
    case 'engagement':
      return 'Engagement';
    case 'potential':
      return 'Potential';
    case 'productConsumptionPercent':
      return 'Consumption %';
    case 'productEngagement':
      return 'Product Score';
    case 'supportEngagement':
      return 'Support Score';
    case 'customerRevenueBand':
      return config.buckets.customerRevenueBand.metricLabel ?? 'Revenue Band';
    default:
      bottom(field);
  }
};

/**
 * Type representation for a set of possible heatmap values. Keyed on {@link HeatmapVariation}.
 */
export type HeatmapValue = {
  [HeatmapVariation.NumAccounts]: number;
  [HeatmapVariation.CurrentArr]: number | undefined;
  [HeatmapVariation.Pipeline]: number | undefined;
};

const getTileDisplayValue = (
  values: HeatmapValue | undefined,
  variant: HeatmapVariation,
  formatCurrencyShort: (n: number) => string,
): string | undefined => {
  if (variant === HeatmapVariation.NumAccounts) {
    // TODO: use localized number formatter
    return `${values?.[variant] ?? 0}`;
  }
  const value = values?.[variant];
  if (value != null) {
    return formatCurrencyShort(value);
  }
  return undefined;
};

/**
 * Helper for extracting given Account metric from an Account shape.
 * @param account Account shape containing details for a specific Account
 * @param field score / percent / number metric of an Account
 * @returns extracted number or null value
 */
export const getAccountMetric = (
  account: Account,
  field: AccountXAxisMetric | AccountYAxisMetric,
): number | null => {
  switch (field) {
    case 'engagement':
    case 'potential':
    case 'productEngagement':
    case 'supportEngagement':
      return account[field]?.value ?? null;
    case 'customerRevenueBand':
    case 'customHealthscore':
    case 'risk':
    case 'growth':
      return account[field]?.bucket ?? null;
    case 'productConsumptionPercent':
      return account[field];
    default:
      bottom(field);
  }
};

/**
 * Function to get a metric's bucket.
 * @param metric account bucket metric
 * @param field name of the metric - and implicit type
 * @returns a bucket value for this metric or null if the metric is non-bucketable
 */
export const getAccountBucket = (
  metric: number | null,
  field: AccountXAxisMetric | AccountYAxisMetric,
): number | null => {
  let fieldErrorValue: number | undefined = undefined;
  switch (field) {
    case 'customerRevenueBand':
    case 'customHealthscore':
    case 'risk':
    case 'growth':
      return metric;
    case 'engagement':
    case 'potential':
    case 'productEngagement':
    case 'supportEngagement': {
      if (metric == null) return 0;
      // score buckets are split by 20 "points"
      if (metric > 100) return null; // non-supported score values
      if (metric >= 80) return 5;
      if (metric >= 60) return 4;
      if (metric >= 40) return 3;
      if (metric >= 20) return 2;
      if (metric >= 0) return 1;
      fieldErrorValue = metric;
      break;
    }
    case 'productConsumptionPercent': {
      if (metric == null) return 0;
      // percent buckets are split by 25% values
      if (metric >= 100) return 5; // percent metrics are unbounded
      if (metric >= 75) return 4;
      if (metric >= 50) return 3;
      if (metric >= 25) return 2;
      if (metric >= 0) return 1;
      fieldErrorValue = metric;
      break;
    }
    default:
      bottom(field);
  }

  console.error(`got an invalid field ${field} with value ${fieldErrorValue} for account heatmap`);

  // we got the right metric name, but the value we received was off for some reason
  // in this case, we actually _can't_ put the account on the heatmap and it cannot be bucketed
  return null;
};

/**
 * Helper to only increment when values are defined
 * @param tileValue existing tile value
 * @param increment value to increment by
 * @returns incremented value, if any
 */
export const incrementTileData = (
  tileValue: number | null | undefined,
  increment: number | null | undefined,
): number | undefined => {
  if (tileValue != null) {
    if (increment != null) {
      return tileValue + increment;
    }
    return tileValue;
  }
  return increment != null ? increment : undefined;
};

interface AccountHeatmapAxisConfig<FieldType extends string> extends HeatmapAxisConfig {
  /**
   * Override the field type to be the available account
   * field types for the x-axis.
   */
  field: FieldType;
}

export interface AccountHeatmapConfig {
  xAxis: AccountHeatmapAxisConfig<AccountXAxisMetric>;
  yAxis: AccountHeatmapAxisConfig<AccountYAxisMetric>;
  data: ChartDataItem[][];
  selectionSummary: Record<HeatmapVariation, number>;
}
interface UseAccountChartConfigOptions {
  /**
   * Accounts to bucket into the chart.
   */
  accounts: Account[];
  /**
   * A grid of selected tiles on the chart.
   */
  selectedTiles: boolean[][];
  /**
   * A grid of highlighted tiles on the chart.
   */
  highlightedTiles: boolean[][];
  /**
   * The kind of account data to show in the chart.
   */
  variant: HeatmapVariation;
  /**
   * The metric to use when bucketing on the x-axis.
   */
  xAxisMetric: AccountXAxisMetric;
  /**
   * The metric to use when bucketing on the y-axis.
   */
  yAxisMetric: AccountYAxisMetric;
}

/**
 * A _required_ version of the heatmap tile value where the
 * current arr and pipeline fields can't be `undefined`.
 */
type RequiredHeatmapValue = { [K in keyof HeatmapValue]: NonNullable<HeatmapValue[K]> };

/**
 * Hook to get a heatmap config for client account data.
 * @param root0 props
 * @param root0.accounts to bucket into the chart data
 * @param root0.selectedTiles a grid of selected tiles in the heatmap
 * @param root0.highlightedTiles a grid of highlighted tiles in the heatmap
 * @param root0.variant the variant to use to display account data
 * @param root0.xAxisMetric the property to use to bucket accounts on the x-axis
 * @param root0.yAxisMetric the property to use to bucket accounts on the y-axis
 * @returns a heatmap config specifically for client accounts
 */
export function useAccountChartConfig({
  accounts,
  selectedTiles,
  highlightedTiles,
  variant,
  xAxisMetric,
  yAxisMetric,
}: UseAccountChartConfigOptions): AccountHeatmapConfig {
  const [config] = useClientConfig();
  const { formatCurrencyShort } = useUserSettingsContext();

  const yAxis = useMemo(
    (): AccountHeatmapAxisConfig<AccountYAxisMetric> => ({
      field: yAxisMetric,
      label: metricLabel(yAxisMetric, config),
      ticks: ticks(yAxisMetric, config),
      containerSx: containerSxProps(yAxisMetric),
    }),
    [config, yAxisMetric],
  );

  const xAxis = useMemo(
    (): AccountHeatmapAxisConfig<AccountXAxisMetric> => ({
      field: xAxisMetric,
      label: metricLabel(xAxisMetric, config),
      ticks: ticks(xAxisMetric, config),
      containerSx: containerSxProps(xAxisMetric),
    }),
    [config, xAxisMetric],
  );

  /**
   * Data we display on the tiles. Memoized separately to prevent extra churn when the user
   * selects or highlights a tile.
   */
  const tileDisplay = useMemo(
    () =>
      accounts.reduce<Partial<Record<`${number | string}-${number | string}`, HeatmapValue>>>(
        (displayData, account) => {
          // default to putting the account on 0-0 if they are un-bucket-able
          const xBucket =
            getAccountBucket(getAccountMetric(account, xAxis.field), xAxis.field) ?? 0;
          const yBucket =
            getAccountBucket(getAccountMetric(account, yAxis.field), yAxis.field) ?? 0;

          const tileKey = `${xBucket}-${yBucket}` as const;
          const data = displayData?.[tileKey];

          // we haven't found any accounts for this bucket yet, so lets initialize it with a new
          // heatmap value
          if (data == null) {
            return {
              ...displayData,
              [tileKey]: {
                [HeatmapVariation.NumAccounts]: 1,
                [HeatmapVariation.CurrentArr]: account.currentArr ?? undefined,
                [HeatmapVariation.Pipeline]: account.rawPipeline ?? undefined,
              },
            };
          }

          // the tile already exists and we need to increment our data
          return {
            ...displayData,
            [tileKey]: {
              [HeatmapVariation.NumAccounts]: data[HeatmapVariation.NumAccounts] + 1,
              [HeatmapVariation.CurrentArr]: incrementTileData(
                data[HeatmapVariation.CurrentArr],
                account.currentArr,
              ),
              [HeatmapVariation.Pipeline]: incrementTileData(
                data[HeatmapVariation.Pipeline],
                account.rawPipeline,
              ),
            },
          };
        },
        {},
      ),
    [accounts, xAxis.field, yAxis.field],
  );

  /**
   * For all of the selected tiles, how many accounts are selected and how much arr/pipeline
   * are in the selection?
   */
  const totalSelectedValue = useMemo(
    () =>
      selectedTiles.reduce(
        (total, row, yIndex): RequiredHeatmapValue =>
          row.reduce((rowTotal, isSelected, xIndex): RequiredHeatmapValue => {
            if (isSelected) {
              const key = `${xIndex}-${yIndex}` as const;
              return {
                [HeatmapVariation.NumAccounts]:
                  rowTotal[HeatmapVariation.NumAccounts] +
                  (tileDisplay[key]?.[HeatmapVariation.NumAccounts] ?? 0),
                [HeatmapVariation.CurrentArr]:
                  rowTotal[HeatmapVariation.CurrentArr] +
                  (tileDisplay[key]?.[HeatmapVariation.CurrentArr] ?? 0),
                [HeatmapVariation.Pipeline]:
                  rowTotal[HeatmapVariation.Pipeline] +
                  (tileDisplay[key]?.[HeatmapVariation.Pipeline] ?? 0),
              };
            }
            return rowTotal;
          }, total),
        {
          [HeatmapVariation.NumAccounts]: 0,
          [HeatmapVariation.CurrentArr]: 0,
          [HeatmapVariation.Pipeline]: 0,
        },
      ),
    [selectedTiles, tileDisplay],
  );

  const data = useMemo((): ChartDataItem[][] => {
    const maxTileValue = Object.entries(tileDisplay).reduce(
      (maxValue, [key, value]) =>
        Math.max(
          maxValue,
          // if the key starts with a 0- or ends with a -0, we are looking at a null bucket
          // this is kind of hacky, but a quick fix to stop considering nulls for
          // tile weight on the heatmap
          !key.startsWith('0-') && !key.endsWith('-0') ? (value?.[variant] ?? 0) : 0,
        ),
      0,
    );

    return Array.from(yAxis.ticks, (yAxisTick, yIndex): ChartDataItem[] =>
      Array.from(xAxis.ticks, (xAxisTick, xIndex): ChartDataItem => {
        const tileKey = `${xIndex}-${yIndex}` as const;

        const metaValue = Math.floor(
          (((tileDisplay[tileKey]?.[variant] ?? 0) - 0) / (1 + maxTileValue)) * 256,
        );

        const tile = tileDisplay[tileKey];
        const displayValue = getTileDisplayValue(tile, variant, formatCurrencyShort);

        const hasNullValue = xIndex === 0 || yIndex === 0;

        return {
          id: `${tileKey}-data-items`,
          displayValue,
          metaValue,
          tooltipContent: (
            <Box sx={{ mb: 1, textAlign: 'center' }}>
              <Typography data-uid={heatmap$.tooltipTitle}>
                {tile?.[HeatmapVariation.NumAccounts] ?? 0} customers
              </Typography>
              <Box sx={{ display: 'flex', my: 1, alignItems: 'center' }}>
                {yAxis.field === 'customerRevenueBand' && typeof yAxisTick === 'string' ? (
                  <Chip
                    data-uid={heatmap$.tooltipXAxisMetric}
                    variant="outlined"
                    size="small"
                    label={yAxisTick}
                  />
                ) : (
                  <ScoreQualityChip
                    data-uid={heatmap$.tooltipYAxisMetric}
                    level={yIndex}
                    scoreVariant="aggregate-score"
                  />
                )}
                <Box sx={{ ml: 1 }}>
                  <Typography variant="caption">{yAxis.label}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {
                  // show chips for data driven scores
                  (xAxis.field === 'customHealthscore' ||
                    xAxis.field === 'risk' ||
                    xAxis.field === 'growth') &&
                  // only render string xAxis ticks since it can be an element (i.e. icon)
                  typeof xAxisTick === 'string' ? (
                    <Chip
                      data-uid={heatmap$.tooltipXAxisMetric}
                      variant="outlined"
                      size="small"
                      label={xAxisTick}
                    />
                  ) : (
                    <ScoreQualityChip
                      data-uid={heatmap$.tooltipXAxisMetric}
                      level={xIndex}
                      scoreVariant="aggregate-score"
                    />
                  )
                }
                <Box sx={{ ml: 1 }}>
                  <Typography variant="caption">{xAxis.label}</Typography>
                </Box>
              </Box>
            </Box>
          ),
          hasNullValue,
          selected:
            yIndex in selectedTiles && xIndex in selectedTiles[yIndex]
              ? selectedTiles[yIndex][xIndex]
              : false,
          highlighted:
            yIndex in highlightedTiles && xIndex in highlightedTiles[yIndex]
              ? highlightedTiles[yIndex][xIndex]
              : false,
        };
      }),
    );
  }, [formatCurrencyShort, highlightedTiles, selectedTiles, tileDisplay, variant, xAxis, yAxis]);

  return {
    xAxis,
    yAxis,
    data,
    selectionSummary: totalSelectedValue,
  };
}
