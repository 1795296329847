import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import React from 'react';
import type { ChartProps } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

ChartJS.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend);
export const BarChart = (chartProps: ChartProps<'bar'>) => <Bar {...chartProps} />;
