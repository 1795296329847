import type { TickOptions, TooltipOptions } from 'chart.js';
import { merge, times } from 'lodash';
import { useCallback, useMemo } from 'react';
import type { ChartProps } from 'react-chartjs-2';

import { RENEWAL_ANALYTICS_COLORS } from '../components/Charts/Analytics/utils';
import { barYTicksHandler } from '../components/Charts/BarChart/barChartUtils';
import { useUserSettingsContext } from '../contexts/userSettingsContext';
import type { Renewal } from './renewal';
import { ExpiringBreakoutSegments } from './renewal';
import { baseWaterfallChartOptions } from './useRenewalOverviewChartConfig';

interface UseGrossRetentionBreakdownChartConfigProps {
  renewals: Renewal[];
}
export const useGrossRetentionBreakdownChartConfig = ({
  renewals,
}: UseGrossRetentionBreakdownChartConfigProps): ChartProps => {
  const { currencyFormatter, formatCurrencyShort } = useUserSettingsContext();
  const label = useCallback<TooltipOptions['callbacks']['label']>(
    (barItem) => {
      // default to empty label for custom interaction mode
      let label = '';

      if (barItem.parsed.y !== null) {
        const { barEnd, barStart } = barItem.parsed._custom;
        label += currencyFormatter.format(barEnd - barStart);
      }
      return label;
    },
    [currencyFormatter],
  );
  const callback = useCallback<TickOptions['callback']>(
    (value) => barYTicksHandler(value, formatCurrencyShort),
    [formatCurrencyShort],
  );
  const totals = useMemo(
    () =>
      renewals.reduce(
        (acc, c) => ({
          ...acc,
          [ExpiringBreakoutSegments.OnTime]:
            c.analytics.expiringBreakout[ExpiringBreakoutSegments.OnTime] +
            acc[ExpiringBreakoutSegments.OnTime],
          [ExpiringBreakoutSegments.Late]:
            c.analytics.expiringBreakout[ExpiringBreakoutSegments.Late] +
            acc[ExpiringBreakoutSegments.Late],
          [ExpiringBreakoutSegments.Pending]:
            c.analytics.expiringBreakout[ExpiringBreakoutSegments.Pending] +
            acc[ExpiringBreakoutSegments.Pending],
          [ExpiringBreakoutSegments.ProductDownsell]:
            c.analytics.expiringBreakout[ExpiringBreakoutSegments.ProductDownsell] +
            acc[ExpiringBreakoutSegments.ProductDownsell],
          [ExpiringBreakoutSegments.ProductChurn]:
            c.analytics.expiringBreakout[ExpiringBreakoutSegments.ProductChurn] +
            acc[ExpiringBreakoutSegments.ProductChurn],
          [ExpiringBreakoutSegments.ContractChurn]:
            c.analytics.expiringBreakout[ExpiringBreakoutSegments.ContractChurn] +
            acc[ExpiringBreakoutSegments.ContractChurn],
        }),
        {
          [ExpiringBreakoutSegments.OnTime]: 0,
          [ExpiringBreakoutSegments.Late]: 0,
          [ExpiringBreakoutSegments.Pending]: 0,
          [ExpiringBreakoutSegments.ProductDownsell]: 0,
          [ExpiringBreakoutSegments.ProductChurn]: 0,
          [ExpiringBreakoutSegments.ContractChurn]: 0,
        },
      ),
    [renewals],
  );
  const data: ChartProps['data'] = useMemo(
    () => ({
      labels: [
        'Total Expiring',
        'Renewed (On Time)',
        'Renewed (Late)',
        'Pending',
        'Product Downsell',
        'Product Churn',
        'Contract Churn',
      ],
      datasets: [
        {
          label: 'Total Expiring',
          data: [
            [
              0,
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending] +
                totals[ExpiringBreakoutSegments.ProductDownsell] +
                totals[ExpiringBreakoutSegments.ProductChurn] +
                totals[ExpiringBreakoutSegments.ContractChurn],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.TotalExpiring,
        },
        {
          label: 'Renewed (On Time)',
          data: [null, [0, totals[ExpiringBreakoutSegments.OnTime]]],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.RenewedOnTime,
        },
        {
          label: 'Renewed (Late)',
          data: [
            null,
            null,
            [
              totals[ExpiringBreakoutSegments.OnTime],
              totals[ExpiringBreakoutSegments.OnTime] + totals[ExpiringBreakoutSegments.Late],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.RenewedLate,
        },
        {
          label: 'Pending',
          data: [
            null,
            null,
            null,
            [
              totals[ExpiringBreakoutSegments.OnTime] + totals[ExpiringBreakoutSegments.Late],
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.Pending,
        },
        {
          label: 'Product Downsell',
          data: [
            null,
            null,
            null,
            null,
            [
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending],
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending] +
                totals[ExpiringBreakoutSegments.ProductDownsell],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.ProductDownsell,
        },
        {
          label: 'Product Churn',
          data: [
            null,
            null,
            null,
            null,
            null,
            [
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending] +
                totals[ExpiringBreakoutSegments.ProductDownsell],
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending] +
                totals[ExpiringBreakoutSegments.ProductDownsell] +
                totals[ExpiringBreakoutSegments.ProductChurn],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.ProductChurn,
        },
        {
          label: 'Contract Churn',
          data: [
            null,
            null,
            null,
            null,
            null,
            null,
            [
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending] +
                totals[ExpiringBreakoutSegments.ProductDownsell] +
                totals[ExpiringBreakoutSegments.ProductChurn],
              totals[ExpiringBreakoutSegments.OnTime] +
                totals[ExpiringBreakoutSegments.Late] +
                totals[ExpiringBreakoutSegments.Pending] +
                totals[ExpiringBreakoutSegments.ProductDownsell] +
                totals[ExpiringBreakoutSegments.ProductChurn] +
                totals[ExpiringBreakoutSegments.ContractChurn],
            ],
          ],
          backgroundColor: RENEWAL_ANALYTICS_COLORS.ContractChurn,
        },
        {
          label: 'Plan',
          data: times(
            7,
            () =>
              totals[ExpiringBreakoutSegments.OnTime] +
              totals[ExpiringBreakoutSegments.Late] +
              totals[ExpiringBreakoutSegments.Pending] +
              totals[ExpiringBreakoutSegments.ProductDownsell] +
              totals[ExpiringBreakoutSegments.ProductChurn] +
              totals[ExpiringBreakoutSegments.ContractChurn],
          ),
          borderColor: 'rgba(0, 0, 0, 0.87);',
          pointRadius: 0,
          showLine: true,
          segment: {
            borderDash: [3, 2],
            borderWidth: 0.65,
          },
          borderDashOffset: 5,
          type: 'line',
        },
      ],
    }),
    [totals],
  );

  return {
    ...merge({}, baseWaterfallChartOptions, {
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label,
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback,
            },
          },
        },
      },
    }),
    data,
  };
};
