import { Card, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { useCustomerContracts } from '../../../../hooks/contract';
import { useContractServicePeriodsGrid } from '../../../../hooks/useContractServicePeriodsGrid';
import { useContractsGrid } from '../../../../hooks/useContractsGrid';
import type { ContractServicePeriodDetails } from '../../../../models/contract';
import { ResultType } from '../../../../models/result';
import type { UUID } from '../../../../models/uuid';
import { CustomGrid } from '../../../Tables/CustomGrid';

export const CustomerContracts = () => {
  const { accountId } = useParams();
  const contractsResult = useCustomerContracts(accountId as UUID | undefined);
  const contractDetails = useMemo(
    () => (contractsResult.state === ResultType.Value ? contractsResult.value : null),
    [contractsResult.state, contractsResult.value],
  );

  const contracts = useMemo(() => {
    if (contractDetails == null || contractDetails.contracts == null) {
      return [];
    }
    return contractDetails.contracts;
  }, [contractDetails]);
  const contractServicePeriods = useMemo(() => {
    if (contractDetails == null) {
      return [];
    }
    return contracts.reduce<ContractServicePeriodDetails[]>(
      (
        conAcc,
        { id: contractId, servicePeriods, ...remainingCon },
      ): ContractServicePeriodDetails[] => [
        ...conAcc,
        ...servicePeriods.reduce<ContractServicePeriodDetails[]>(
          (spAcc, { id: servicePeriodId, ...remainingSp }): ContractServicePeriodDetails[] => [
            ...spAcc,
            {
              ...remainingSp,
              servicePeriodId,
              contractId,
              ...remainingCon,
              id: `${contractId}-${servicePeriodId}`,
            },
          ],
          [],
        ),
      ],
      [],
    );
  }, [contracts, contractDetails]);

  const contractsGridProps = useContractsGrid({ contracts });
  const contractServicePeriodsGridProps = useContractServicePeriodsGrid({ contractServicePeriods });

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Card elevation={0}>
        <CustomGrid
          loading={contractsResult.state === ResultType.Loading}
          GridWrapperBoxProps={{
            outer: {
              sx: {
                height: 'calc((100vh - 8.8rem) * 0.3)',
                maxWidth: 'calc(100vw - 31.5rem)',
              },
            },
          }}
          {...contractsGridProps}
        />
      </Card>
      <Card elevation={0}>
        <CustomGrid
          loading={contractsResult.state === ResultType.Loading}
          GridWrapperBoxProps={{
            outer: {
              sx: {
                height: 'calc((100vh - 8.8rem) * 0.7)',
                maxWidth: 'calc(100vw - 31.5rem)',
              },
            },
          }}
          {...contractServicePeriodsGridProps}
        />
      </Card>
    </Stack>
  );
};
