import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { DeleteActionDocument, GetClientObjectivesDocument } from '../../../graphql/generated';
import type { Action } from '../../../models/sprint';
import { DeleteConfirmationDialog } from '../DeleteConfirmationDialog';

interface Closeable {
  onClose(): void;
  /**
   * Callback to set dialog state after it has been closed to prevent weird data flashing
   * as it continues to close.
   */
  onExited(): void;
}

interface OpenDeleteActionDialog {
  open: true;
  action: Action;
}

/**
 * These properties can be non-null because a closed dialog may not be unmounted yet.
 * So there is no odd data flashing as the dialog closes, we allow the data to be non-null.
 */
interface ClosedDeleteActionDialog {
  open: false;
  action: Action | undefined;
}

export type DeleteActionDialogOptions = OpenDeleteActionDialog | ClosedDeleteActionDialog;

type DeleteActionDialogProps = DeleteActionDialogOptions & Closeable;

export const DeleteActionDialog = ({
  open,
  onClose,
  onExited,
  action,
}: DeleteActionDialogProps) => {
  const [deleteAction, result] = useMutation(DeleteActionDocument);

  useEffect(() => {
    if (result.data?.payload != null) {
      onClose();
    }
  }, [onClose, result.data, result.error]);

  const afterClose = useCallback(() => {
    onExited();
    if (result.called) {
      result.reset();
    }
  }, [onExited, result]);

  return (
    <DeleteConfirmationDialog
      type="action"
      open={open}
      onClose={onClose}
      onExited={afterClose}
      onDelete={() =>
        open &&
        deleteAction({
          variables: { actionId: action.id },
          refetchQueries: [{ query: GetClientObjectivesDocument }],
          // FIXME: evict action.id from the cache (remove refetch)
        })
      }
      title="Delete Action"
      confirmationText={
        <>
          <Typography gutterBottom>
            Are you sure you want to delete{' '}
            {action != null ? (
              <>
                the <strong>{action.title}</strong> action
              </>
            ) : (
              'this action'
            )}
            ?
          </Typography>
          <Typography gutterBottom>
            This will delete the <strong>Action</strong> and <em>all</em> of it&apos;s associated{' '}
            <strong>Links</strong>.
          </Typography>
        </>
      }
    />
  );
};
