import { sum } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { useCurrentUserDetails } from '../hooks/user';
import type { UUID } from '../models/uuid';
import { PersistenceContext, PersistenceKeys } from './persistenceContext';

const STORAGE_VERSION = 'v0';

const STORAGE_KEY_PREFIX = 'reefai';
const buildStorageKeyPrefix = (obj: Record<string, string>) => btoa(JSON.stringify(obj));

export const PersistenceProvider = ({ children }: React.PropsWithChildren) => {
  const {
    clientId,
    user: { id: userId },
  } = useCurrentUserDetails();
  const [filterId, setFilterId] = useState<string>();
  const [_, setSprintId] = useState<UUID>();
  const [changeCounts, setChangeCounts] = useState<Record<PersistenceKeys, number>>({
    [PersistenceKeys.HeatmapVariation]: 0,
    [PersistenceKeys.HeatmapXAxisSelection]: 0,
    [PersistenceKeys.HeatmapYAxisSelection]: 0,
    [PersistenceKeys.HeatmapTileAccountsSelection]: 0,
    [PersistenceKeys.CustomersGridRowsSelection]: 0,
    [PersistenceKeys.CustomersGridColumnFiltering]: 0,
    [PersistenceKeys.CustomersGridColumnSorting]: 0,
    [PersistenceKeys.CustomersNameSearch]: 0,
    [PersistenceKeys.StorageUpdatedAt]: 0,
  });
  const [removalRegistry, setRemovalRegistry] = useState<Set<() => void>>(new Set([]));

  const buildStorageKeyPrefixContent = useMemo(
    () => ({
      version: STORAGE_VERSION,
      clientId,
      userId,
    }),
    [clientId, userId],
  );
  const buildStorageKey = useCallback(
    (stateKeyFragment: string) =>
      `${STORAGE_KEY_PREFIX}_${stateKeyFragment}_${buildStorageKeyPrefix(
        buildStorageKeyPrefixContent,
      )}`,
    [buildStorageKeyPrefixContent],
  );
  const buildCustomersStorageKey = useCallback(
    (stateKeyFragment: string) =>
      `${STORAGE_KEY_PREFIX}_${stateKeyFragment}_${buildStorageKeyPrefix({
        ...buildStorageKeyPrefixContent,
        ...(filterId != null ? { filterId } : {}),
      })}`,
    [buildStorageKeyPrefixContent, filterId],
  );
  const persistenceKeys = useMemo(
    (): Record<PersistenceKeys, string> => ({
      [PersistenceKeys.HeatmapVariation]: buildCustomersStorageKey('heatmap_variation'),
      [PersistenceKeys.HeatmapXAxisSelection]: buildCustomersStorageKey('heatmap_x-axis_selection'),
      [PersistenceKeys.HeatmapYAxisSelection]: buildCustomersStorageKey('heatmap_y-axis_selection'),
      [PersistenceKeys.HeatmapTileAccountsSelection]: buildCustomersStorageKey(
        'heatmap_tile_accounts_selection',
      ),
      [PersistenceKeys.CustomersGridRowsSelection]: buildCustomersStorageKey(
        'customers_grid_rows_selection',
      ),
      [PersistenceKeys.CustomersGridColumnFiltering]: buildCustomersStorageKey(
        'customers_grid_column_filtering',
      ),
      [PersistenceKeys.CustomersGridColumnSorting]: buildCustomersStorageKey(
        'customers_grid_column_sorting',
      ),
      [PersistenceKeys.CustomersNameSearch]: buildCustomersStorageKey('customers_name_search'),
      [PersistenceKeys.StorageUpdatedAt]: buildStorageKey('storage_updated_at'),
    }),
    [buildCustomersStorageKey, buildStorageKey],
  );
  const getStorageKey = useCallback(
    (pk: PersistenceKeys): string => persistenceKeys[pk],
    [persistenceKeys],
  );

  const setChanges = useCallback(
    (pk: PersistenceKeys, count: number) => {
      if (changeCounts[pk] !== count) {
        setChangeCounts({ ...changeCounts, [pk]: count });
      }
    },
    [changeCounts],
  );
  const registerReset = useCallback(
    (cb: () => void) => {
      if (!removalRegistry.has(cb)) {
        const updated = new Set(removalRegistry);
        updated.add(cb);
        setRemovalRegistry(updated);
      }
    },
    [removalRegistry],
  );
  const changesCount = useMemo(() => sum(Object.values(changeCounts)), [changeCounts]);

  // NOTE: currently clears _all_ registered storage values
  const clearAllChanges = useCallback(
    () => Array.from(removalRegistry).map((removeStateFn) => removeStateFn()),
    [removalRegistry],
  );

  return (
    <PersistenceContext.Provider
      value={{
        setFilterId,
        setSprintId,
        getStorageKey,
        setChanges,
        registerReset,
        clearAllChanges: changesCount > 0 ? clearAllChanges : undefined,
      }}
    >
      {children}
    </PersistenceContext.Provider>
  );
};
